import { Button } from '@sede-x/shell-ds-react-framework';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.background.surface};
  margin-top: 12px;
`;

export const CodeContainer = styled.div`
  position: relative;
`;

export const ButtonStyled = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;
`;

export const TextContainer = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
`;
