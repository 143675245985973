import Layout from '../../../../components/Layout/Layout';
import AdminFormCreateSection from '../sections/CreationForms/AdminFormCreateSection/AdminFormCreateSection';

const AdminFormCreatePageEvent = () => {
  return (
    <Layout
      variant="CENTERED_COLUMN"
      content={<AdminFormCreateSection type="Event" />}
      padding="0px"
    />
  );
};

export default AdminFormCreatePageEvent;
