import { Button } from '@sede-x/shell-ds-react-framework';
import styled from 'styled-components';

export const ConsumeCardContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 2rem 3rem;
  gap: 2rem;
  background-color: ${(props) => props.theme.background.surface};
`;

export const ConsumeCardLeft = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1rem;
`;

export const ConsumeCardRight = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 1rem;
`;

export const ConsumeButton = styled(Button)`
  width: 70%;
`;
