export const updateTimeAndVersion = (
  updateTime: string | number,
  version?: string,
) => {
  const time = new Date(updateTime).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });

  return version ? `• v${version} • Updated: ${time}` : `• Updated: ${time}`;
};

export const millisInMinute = 60000;

export const getLastUpdateText = (minutes: number) => {
  if (minutes < 0) {
    return '';
  }

  if (minutes === 0) {
    return 'Last update now';
  }

  return `Last update ${minutes} minutes ago`;
};

export const getMinutesDifference = (lastUpdate?: number) =>
  lastUpdate
    ? Math.round((new Date().getTime() - lastUpdate) / millisInMinute)
    : -1;
