import Layout from '../../components/Layout/Layout';
import MainSection from './components/MainSection/MainSection';

const MainPage = () => {
  return (
    <Layout
      variant="FULLPAGE_CONTENT"
      content={<MainSection />}
      padding="0px"
      hasSearchBar={false}
    />
  );
};

export default MainPage;
