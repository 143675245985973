import { Icons, Loading } from '@sede-x/shell-ds-react-framework';
import { useState } from 'react';
import { DataProductType } from '../../../../../../../../../state/models/dataProductsModel/types';
import { DetailButton } from '../../TheButton.styles';
import CheckBoxModal from '../../CheckBoxModal/CheckBoxModal';
import ResultModal, { VariantsResultType } from '../../ResultModal/ResultModal';
import { useDispatch } from 'react-redux';
import RequestAccessModal from '../components/RequestAccessModal';
import AccessRequiredModal from '../components/AccessRequiredModal';
import { AxiosError } from 'axios';

interface Props {
  dataProduct: DataProductType;
  onDownload?: () => void;
}

const errorCode401 = 401;
const errorCode400 = 400;

const MultipleDownloadButton = ({
  dataProduct,
  onDownload = () => {},
}: Props) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [resultOpen, setResultOpen] = useState(false);
  const [requestingAccess, setRequestingAccess] = useState(false);
  const [accessRequired, setAccessRequired] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const onCloseDialog = () => setOpen(false);
  const currentQueries = dataProduct.queries ?? [];
  const [responseState, setResponseState] = useState<string[]>([]);
  const [downloadStatus, setDownloadStatus] =
    useState<VariantsResultType>('success');

  const handleResponse = (
    response: {
      title: string;
      endpoint: string;
      status: number;
      error?: string;
    }[],
    totalQueriesRequested: number,
  ) => {
    const errorFiles = response.filter((r) => r.status >= errorCode400);
    const hasPermissionErrors =
      response.filter((r) => r.status === errorCode401).length > 0;

    if (errorFiles.length === 0) {
      setDownloadStatus('success');
      setResultOpen(true);
      return onDownload();
    }
    if (hasPermissionErrors) {
      return setAccessRequired(true);
    }
    if (errorFiles.length >= totalQueriesRequested) {
      setDownloadStatus('error');
    } else {
      setDownloadStatus('info');
      setResponseState(errorFiles.map((eF) => eF.title));
    }
    return setResultOpen(true);
  };

  const handleDownload = async (
    queries: number[],
    totalQueriesRequested: number,
    callback: () => void,
  ) => {
    setIsLoading(true);
    try {
      const response: {
        title: string;
        endpoint: string;
        status: number;
        error?: string;
      }[] = await dispatch.dataProducts.downloadQueries({
        queries,
        dpName: dataProduct.name,
      });
      handleResponse(response, totalQueriesRequested);
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status && error.response.status === errorCode401) {
          setAccessRequired(true);
        } else {
          setDownloadStatus('error');
          setResultOpen(true);
        }
      } else {
        setDownloadStatus('error');
        setResultOpen(true);
      }
    } finally {
      callback();
      setIsLoading(false);
    }
  };

  const handleAccessRequest = () => {
    setAccessRequired(false);
    setRequestingAccess(true);
  };

  return (
    <>
      <DetailButton
        icon={!isLoading || open ? <Icons.InboxDownloadFilled /> : <Loading />}
        disabled={isLoading && !open}
        iconPosition="right"
        onClick={() => setOpen(true)}
      >
        Download data
      </DetailButton>
      {accessRequired && (
        <AccessRequiredModal
          onRequestAccess={handleAccessRequest}
          onCloseDialog={() => setAccessRequired(false)}
        />
      )}
      {requestingAccess && (
        <RequestAccessModal
          dataProduct={dataProduct}
          onCancel={() => setRequestingAccess(false)}
        />
      )}
      {open && (
        <CheckBoxModal
          title={'Make a selection'}
          onClose={onCloseDialog}
          onDownload={handleDownload}
          items={currentQueries.map((dPQ) => ({
            title: dPQ.title,
            id: `${dPQ.title}+${dPQ.path}`,
            description: dPQ.description,
            active: false,
          }))}
        />
      )}
      {resultOpen && (
        <ResultModal
          variant={downloadStatus}
          onClose={() => {
            setResultOpen(false);
          }}
          items={responseState}
        />
      )}
    </>
  );
};

export default MultipleDownloadButton;
