import axios from 'axios';
import axiosInstance from './DPAxios';
import axiosInstanceGlass from './GlassAxios';
import jwtDecode from 'jwt-decode';
import { ITokenPair } from '../hooks/useAuthentication';

const defaultURL = 'https://sso.dep.shell/auth/realms/dep';

export function login({
  url = defaultURL,
  client_id,
  redirect_uri,
  scope = 'openid email offline_access',
  response_type = 'code',
  code_challenge,
  code_challenge_method = 'S256',
}: {
  url?: string;
  client_id: string;
  redirect_uri: string;
  scope?: string;
  response_type?: string;
  state?: string;
  code_challenge: string;
  code_challenge_method?: string;
}) {
  const params = new URLSearchParams({
    scope,
    client_id,
    redirect_uri,
    response_type,
    code_challenge,
    code_challenge_method,
  }).toString();

  window.location.href = `${url}/protocol/openid-connect/auth?${params}`;
}

export async function getTokens({
  url = defaultURL,
  code,
  grant_type = 'authorization_code',
  client_id,
  redirect_uri,
  code_verifier,
}: {
  url?: string;
  code: string;
  grant_type?: string;
  client_id: string;
  redirect_uri: string;
  code_verifier: string;
}) {
  const tokens = await axios.post(
    `${url}/protocol/openid-connect/token`,
    {
      code,
      grant_type,
      client_id,
      code_verifier,
      redirect_uri,
    },
    {
      headers: {
        'content-type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
    },
  );
  setTokens(tokens.data);
  return tokens.data;
}

export async function refreshTokens({
  url = defaultURL,
  grant_type = 'refresh_token',
  client_id,
  refreshToken,
}: {
  url?: string;
  grant_type?: string;
  client_id: string;
  refreshToken: string;
}) {
  const tokens = await axios.post(
    `${url}/protocol/openid-connect/token`,
    {
      grant_type,
      client_id,
      refresh_token: refreshToken,
    },
    {
      headers: {
        'content-type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
    },
  );
  setTokens(tokens.data);
  return tokens.data;
}

interface IEXPIRINGTOKEN {
  exp: string;
}

function isFresh({ exp }: IEXPIRINGTOKEN) {
  const SLACK = 180;
  const now = Math.floor(Date.now() / 1000);
  const expiration = Number.parseInt(exp);

  return now + SLACK < expiration;
}

export async function checkRefresh({
  url,
  accessToken,
  refreshToken,
  clientId,
}: {
  url: string;
  accessToken: string;
  refreshToken: string;
  clientId: string;
}): Promise<ITokenPair | undefined> {
  const atokendecoded = jwtDecode<IEXPIRINGTOKEN>(accessToken);

  // everyone is fresh, we don't do anything
  if (isFresh(atokendecoded)) {
    return { accessToken, refreshToken };
  }

  // if the access token is expiring but the refresh token is still fresh
  if (!isFresh(atokendecoded)) {
    const tokens = await refreshTokens({
      url,
      refreshToken,
      client_id: clientId,
    });
    return {
      accessToken: tokens.access_token,
      refreshToken: tokens.refresh_token,
    };
  }

  return undefined;
}

export function setTokens(accessToken: string) {
  axiosInstance.defaults.headers.common[
    'Authorization'
  ] = `Bearer ${accessToken}`;
  axiosInstanceGlass.defaults.headers.common[
    'Authorization'
  ] = `Bearer ${accessToken}`;
  // add tokens to axios here
}
