const EmptyComponent = () => <div />;

const EmptyField = {
  label: '',
  name: '',
  description: '',
  placeholder: '',
  type: 'CUSTOM',
  component: EmptyComponent,
};

export default EmptyField;
