import { Icons, TextInput } from '@sede-x/shell-ds-react-framework';
import { useRef } from 'react';
import { CenteredForm } from './SearchBar.styles';

interface ISearchBar {
  onSubmit: (keywords: string) => void;
  placeholder: string;
  defaultValue?: string;
}

export default function SearchBar({
  onSubmit,
  placeholder,
  defaultValue,
}: ISearchBar) {
  const inputRef = useRef<HTMLInputElement>(null);
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit(inputRef.current?.value ?? '');
  };

  return (
    <CenteredForm onSubmit={handleSubmit} data-testid="search-form">
      <TextInput
        prefix={{
          node: <Icons.Search height={24} />,
          transparent: true,
        }}
        placeholder={placeholder}
        ref={inputRef}
        defaultValue={defaultValue}
      />
    </CenteredForm>
  );
}
