import { RematchDispatch } from '@rematch/core';
import { RootModel } from '../../store';
import { navigationType } from './types';

const effects = (dispatch: RematchDispatch<RootModel>) => ({
  saveUserData(props: {
    email: string;
    name: string;
    roles: string[];
    navigationHistory: {
      type: navigationType;
      item?: string;
    };
  }) {
    dispatch.user.setUserData(props);
  },
});

export default effects;
