import { Button } from '@sede-x/shell-ds-react-framework';
import styled from 'styled-components';

export const DetailButton = styled(Button)`
  padding: 0.8rem 1.5rem;
`;

export const StyledAnchor = styled.a`
  text-decoration: none;
`;
