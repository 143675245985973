import { useTranslation } from 'react-i18next';
import GenericResponsePage from '../../GenericResponsePage/GenericResponsePage';
import SuccessIcon from '../../ConfirmationPage/ConfirmationPageContent/SuccessIcon';
import { useLocation, useNavigate } from 'react-router-dom';
import { Icons } from '@sede-x/shell-ds-react-framework';
import { productTypes } from '../../../../../utils/dataProductTypes';
import { use } from 'i18next';
import { useEffect } from 'react';
import routes from '../../../../../routes/routes';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../state/store';

const UpdatingConfirmationPageContent = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const navigate = useNavigate();
  const versionChanged = state?.versionChanged;
  const isGlass = state.createdType === productTypes.Glass;
  const { navigationHistory } = useSelector(
    (state: RootState) => state.user.user,
  );

  useEffect(() => {
    if (navigationHistory.type === 'details') {
      navigate(`${routes.details}/${state.dataProductName}`);
    }
  }, [state.dataProductName]);

  return isGlass ? (
    <GenericResponsePage
      icon={<SuccessIcon />}
      title={t('admin_success_update_glass-title')}
      description={t('admin_success_update_glass-description')}
      email={t('admin_response_glass-email')}
      textEmail={t('admin_response_glass-email_text')}
      textCreateButton={t('admin_success_create_glass-button_create')}
      textManageButton={t('admin_success_create_glass-button_manage')}
      isGlassProduct
    />
  ) : (
    <GenericResponsePage
      icon={
        versionChanged ? (
          <Icons.CheckCloud width={172} height={172} />
        ) : (
          <SuccessIcon />
        )
      }
      title={t('admin_success_update-title')}
      description={t(
        versionChanged
          ? 'admin_success_update-description_new_version'
          : 'admin_success_update-description_old_version',
        {
          version: state?.version,
          dataProductName: state?.dataProductName,
        },
      )}
      email={t('admin_success_update-email')}
      textEmail={t('admin_success_update-email_text')}
      textCreateButton={t('admin_success_update-button_create')}
      textManageButton={t('admin_success_update-button_manage')}
    />
  );
};

export default UpdatingConfirmationPageContent;
