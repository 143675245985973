import { Heading } from '@sede-x/shell-ds-react-framework';
import {
  Container,
  IconContainer,
  StyledLink,
  StyledText,
  StyledButton,
} from '../common/ResponsePages.styles';
import routes from '../../../../routes/routes';
import { Link } from 'react-router-dom';

type Props = {
  icon: React.ReactNode;
  title: string;
  description: string;
  email: string;
  textEmail: string;
  textCreateButton: string;
  textManageButton: string;
  buttonsInverted?: boolean;
  isGlassProduct?: boolean;
};
const GenericResponsePage = ({
  icon,
  title,
  description,
  email,
  textEmail,
  textCreateButton,
  textManageButton,
  buttonsInverted = false,
  isGlassProduct = false,
}: Props) => {
  const manageProductRoute = isGlassProduct
    ? `${routes.admin}${routes.adminGlassHubRelative}`
    : routes.admin;
  const createProductRoute = isGlassProduct
    ? routes.createGlassHub
    : routes.create;
  return (
    <Container>
      <IconContainer>{icon}</IconContainer>
      <Heading level={2} bold>
        {title}
      </Heading>
      <StyledText>{description}</StyledText>
      <StyledLink href={email}>{textEmail}</StyledLink>
      <Link to={buttonsInverted ? createProductRoute : manageProductRoute}>
        <StyledButton variant="filled">
          {buttonsInverted ? textCreateButton : textManageButton}
        </StyledButton>
      </Link>
      <Link to={buttonsInverted ? manageProductRoute : createProductRoute}>
        <StyledButton variant="transparent">
          {buttonsInverted ? textManageButton : textCreateButton}
        </StyledButton>
      </Link>
    </Container>
  );
};

export default GenericResponsePage;
