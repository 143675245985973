import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

export const PointColoured = styled.div<{ background: string }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: ${({ background }) => background};
`;
