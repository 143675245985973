import { ShellThemeProvider } from '@sede-x/shell-ds-react-framework';
import { Provider } from 'react-redux';
import store from './state/store';
import Router from './routes/Router';
import translate from './locale/en/translation.json';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';

import { retrieveContentFromServer } from './services/content';
import { useEffect, useState } from 'react';

import './utils/i18n/i18nInit';

function App() {
  const [, setNamespace] = useState('translation');

  // @ts-ignore
  window.dobby = () => {
    i18n.setDefaultNamespace('null');
    setNamespace('null');
  };

  useEffect(() => {
    retrieveContentFromServer().then((pack) => {
      i18n.addResourceBundle('en', 'remote', { ...translate, ...pack });
      i18n.setDefaultNamespace('remote');
      setNamespace('remote');
    });
  }, []);

  useTranslation();

  return (
    <ShellThemeProvider theme="dark">
      <Provider store={store}>
        <Router />
      </Provider>
    </ShellThemeProvider>
  );
}

export default App;
