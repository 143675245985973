import { saveAs } from 'file-saver';
import multipartParser from './multipartParser';
import jszipDownloader from './JSZipDownloader';

export function downloadFiles(data: string, dpName: string) {
  const response = multipartParser(data);

  if (response.files.length <= 0) {
    return response.multipartState;
  }

  if (response.files.length === 1) {
    const currentFile = response.files[0];

    const blob = new Blob([currentFile.content], {
      type: currentFile.contentType,
    });
    saveAs(blob, currentFile.fileName);
    return response.multipartState;
  }

  jszipDownloader(response.files, dpName);
  return response.multipartState;
}
