import React from 'react';
import SwaggerUI, { SwaggerUIProps } from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';
import './swagger-ui-modified.css';

const SwaggerComponent: React.FC<SwaggerUIProps> = ({ spec }) => {
  return <SwaggerUI spec={spec} />;
};

export default SwaggerComponent;
