import { Modal } from '@sede-x/shell-ds-react-framework';
import { Trans, useTranslation } from 'react-i18next';
import { StyledText } from './ErrorModal.styles';

const ErrorModal = ({
  onTryAgain,
  onCancel,
  title,
  isGlass = false,
}: {
  onTryAgain: () => void;
  onCancel: () => void;
  title: string;
  isGlass?: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      open
      onClose={onCancel}
      closable={false}
      title={t('admin_list-delete_modal-error_modal-title')}
      actions={[
        {
          action: onCancel,
          label: t('admin_list-delete_modal-error_modal-cancel'),
          props: { variant: 'outlined' },
        },
        {
          action: onTryAgain,
          label: t('admin_list-delete_modal-error_modal-try_again'),
        },
      ]}
    >
      <StyledText>
        <Trans
          t={t}
          i18nKey={
            isGlass
              ? 'admin_list-delete_modal_glass-error_modal-body'
              : 'admin_list-delete_modal-error_modal-body'
          }
          values={{ product: title }}
          components={{ bold: <strong /> }}
        />
      </StyledText>
    </Modal>
  );
};

export default ErrorModal;
