import { Heading, Text, Divider, Link } from '@sede-x/shell-ds-react-framework';
import { ReactNode } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
interface Props {
  children: string;
}

interface CustomComponentProps {
  children: ReactNode;
}

const components = {
  h1: ({ children, ...props }: CustomComponentProps) => (
    <Heading type="h1" gutter {...props}>
      <strong>{children}</strong>
    </Heading>
  ),
  h2: ({ children, ...props }: CustomComponentProps) => (
    <Heading type="h2" gutter {...props}>
      <strong>{children}</strong>
    </Heading>
  ),
  h3: ({ children, ...props }: CustomComponentProps) => (
    <Heading type="h3" gutter {...props}>
      <strong>{children}</strong>
    </Heading>
  ),
  h4: ({ children, ...props }: CustomComponentProps) => (
    <Heading type="h4" gutter {...props}>
      <strong>{children}</strong>
    </Heading>
  ),
  h5: ({ children, ...props }: CustomComponentProps) => (
    <Heading type="h5" gutter {...props}>
      <strong>{children}</strong>
    </Heading>
  ),
  h6: ({ children, ...props }: CustomComponentProps) => (
    <Heading type="h6" gutter {...props}>
      <strong>{children}</strong>
    </Heading>
  ),
  p: ({ children, ...props }: CustomComponentProps) => (
    <Text gutter {...props}>
      {children}
    </Text>
  ),
  hr: () => <Divider />,
  a: ({ children, href }: { children: ReactNode; href?: string }) => (
    <Link>
      <a href={href} target="_blank" className="link" rel="noreferrer">
        {children}
      </a>
    </Link>
  ),
};

function MarkdownText({ children }: Props) {
  return (
    <ReactMarkdown
      children={children}
      components={components}
      remarkPlugins={[remarkGfm]}
    />
  );
}

export default MarkdownText;
