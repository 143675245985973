import { Link, Text } from '@sede-x/shell-ds-react-framework';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const YellowSeparator = styled.div`
  width: 47px;
  height: 6px;
  background: ${({ theme }) => theme.accent.primary.strong};
`;

export const LinkStyled = styled(Link)`
  margin-left: 4px;
  margin-right: 4px;
`;

export const DescriptionStyled = styled(Text)`
  margin-top: 36px;
  margin-bottom: 24px;
`;
