import { DataDomain, InsightsState, RequestsDataProduct } from './types';

const reducers = {
  setDataDomainsData(
    state: InsightsState,
    {
      dataDomains,
      totalDataProducts,
    }: { dataDomains: DataDomain[]; totalDataProducts: number },
  ) {
    return {
      ...state,
      dataDomains: {
        ...state.dataDomains,
        items: dataDomains,
        totalDataProducts,
      },
    };
  },
  setIsDataDomainsLoading(state: InsightsState, isLoading: boolean) {
    return {
      ...state,
      dataDomains: { ...state.dataDomains, isLoading },
    };
  },
  setHasErrorDataDomains(state: InsightsState, hasError: boolean) {
    return {
      ...state,
      dataDomains: { ...state.dataDomains, error: hasError },
    };
  },
  setLastUpdateDataDomains(state: InsightsState, lastUpdate: number) {
    return {
      ...state,
      dataDomains: { ...state.dataDomains, lastUpdate },
    };
  },
  setRequestsData(
    state: InsightsState,
    {
      requests,
      totalDataProducts,
    }: {
      requests: RequestsDataProduct[];
      totalDataProducts: number;
    },
  ) {
    return {
      ...state,
      requests: {
        ...state.requests,
        items: requests,
        totalDataProducts,
      },
    };
  },
  setIsRequestsLoading(state: InsightsState, isLoading: boolean) {
    return {
      ...state,
      requests: { ...state.requests, isLoading },
    };
  },
  setHasErrorRequests(state: InsightsState, hasError: boolean) {
    return {
      ...state,
      requests: { ...state.requests, error: hasError },
    };
  },
  setLastUpdateRequests(state: InsightsState, lastUpdate: number) {
    return {
      ...state,
      requests: { ...state.requests, lastUpdate },
    };
  },
};
export default reducers;
