import { LoadingItem } from '../../../../../../components/LoadingItem/LoadingItem';
import {
  LeftContainer,
  StyledCategory,
} from '../../../Header/components/LeftHeader/LeftHeader.styles';
import { LoadingTabsContainer, CategoryText } from './LoadingLeftHeader.styles';

const LoadingLeftHeader = () => {
  return (
    <LeftContainer>
      <LoadingItem
        type={'normal'}
        width={'500px'}
        margin={'5px 0 5px 0'}
        height={'30px'}
      />
      <StyledCategory>
        <LoadingItem
          type={'round'}
          width={'15px'}
          margin={'10px 0 0 0'}
          height={'10px'}
        />

        <CategoryText>
          <LoadingItem
            type={'normal'}
            width={'300px'}
            margin={'10px 0 0 0'}
            height={'10px'}
          />
        </CategoryText>
      </StyledCategory>

      <LoadingItem
        type={'normal'}
        width={'200px'}
        margin={'0 0 30px 0'}
        height={'50px'}
      />
      <LoadingTabsContainer>
        <LoadingItem
          type={'normal'}
          width={'60px'}
          margin={'40px 0 0 0'}
          height={'10px'}
        />
        <LoadingItem
          type={'normal'}
          width={'60px'}
          margin={'40px 0 0 0'}
          height={'10px'}
        />
      </LoadingTabsContainer>
    </LeftContainer>
  );
};

export default LoadingLeftHeader;
