import { Heading, Icons } from '@sede-x/shell-ds-react-framework';
import {
  Container,
  DescriptionStyled,
  LinkStyled,
  YellowSeparator,
} from './AdminCreationMainPage.styles';
import routes from '../../../../../../routes/routes';
import CreationCard from './CreationCard/CreationCard';
import TypeTranslation from '../../../../../../utils/i18n/TypeTranslation';
import { useTranslation } from 'react-i18next';

const iconSize = 36;

const currentProducts = (t: TypeTranslation) => [
  {
    icon: <Icons.DesignSystem width={iconSize} height={iconSize} />,
    title: t('admin_create-product-selector-api_title'),
    description: t('admin_create-product-selector-api_description'),
    urlWiki: t('admin_create-product-selector-api_wiki_url'),
    textWiki: t('admin_create-product-selector-api_wiki_text'),
    urlCreate: routes.createApi,
  },
  {
    icon: <Icons.FileText width={iconSize} height={iconSize} />,
    title: t('admin_create-product-selector-resource_title'),
    description: t('admin_create-product-selector-resource_description'),
    urlWiki: t('admin_create-product-selector-resource_wiki_url'),
    textWiki: t('admin_create-product-selector-resource_wiki_text'),
    urlCreate: routes.createResource,
  },
  {
    icon: <Icons.Calendar width={iconSize} height={iconSize} />,
    title: t('admin_create-product-selector-event_title'),
    description: t('admin_create-product-selector-event_description'),
    urlWiki: t('admin_create-product-selector-event_wiki_url'),
    textWiki: t('admin_create-product-selector-event_wiki_text'),
    urlCreate: routes.createEvent,
  },
  {
    icon: <Icons.LineChartSolid width={iconSize} height={iconSize} />,
    title: t('admin_create-product-selector-streaming_title'),
    description: t('admin_create-product-selector-streaming_description'),
    urlWiki: t('admin_create-product-selector-streaming_wiki_url'),
    textWiki: t('admin_create-product-selector-streaming_wiki_text'),
    urlCreate: routes.createStreaming,
  },
  {
    icon: <Icons.RowsSolid width={iconSize} height={iconSize} />,
    title: t('admin_create-product-selector-batch_title'),
    description: t('admin_create-product-selector-batch_description'),
    urlWiki: t('admin_create-product-selector-batch_wiki_url'),
    textWiki: t('admin_create-product-selector-batch_wiki_text'),
    urlCreate: routes.createBatch,
  },
];
const AdminCreationMainPage = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <Heading level={1} bold>
        {t('admin_create-product-selector-title')}
      </Heading>
      <DescriptionStyled>
        {t('admin_create-product-selector-description_first_part')}
        <LinkStyled>
          <a
            href={t('admin_create-product-selector-description_link')}
            target="_blank"
            rel="noreferrer"
          >
            {t('admin_create-product-selector-description_link_text')}
          </a>
        </LinkStyled>
        {t('admin_create-product-selector-description_second_part')}
      </DescriptionStyled>
      <YellowSeparator />
      {currentProducts(t).map((cP) => (
        <CreationCard {...cP} key={cP.title} />
      ))}
    </Container>
  );
};

export default AdminCreationMainPage;
