import { EmptyState } from '@sede-x/glass-design-library';
import { useNavigate } from 'react-router-dom';
import routes from '../../../../../../../routes/routes';
import { useTranslation } from 'react-i18next';

const AdminListGlassHubEmptyState = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  return (
    <EmptyState
      action={{
        onClick: () => navigate(routes.createGlassHub),
        text: t('admin_list_glass-empty_state-register_product'),
      }}
      image
      link={{
        text: t('admin_list_glass-empty_state-learn_more_link_text'),
        url: t('admin_list_glass-empty_state-learn_more_link'),
      }}
      message={t('admin_list_glass-empty_state-message')}
      title={t('admin_list_glass-empty_state-title')}
    />
  );
};

export default AdminListGlassHubEmptyState;
