const boundary = '--StreamingBoundary--';

export function multipartParser(data: string) {
  const boundaryParts = data
    .split(boundary)
    .map((i) => i.trim())
    .slice(1);

  const multipartState = boundaryParts.pop() ?? '[{}]';

  const multipartStateParsed: {
    title: string;
    endpoint: string;
    status: number;
    error?: string;
  }[] = JSON.parse(multipartState);

  const boundaryPartsParsed = boundaryParts.map((i) => {
    const startLineContent = 3;
    const lines = i.split('\n');
    return {
      content: lines.slice(startLineContent).join('\n'),
      contentType: lines[1].split(' ')[1].trim(),
      fileName: lines[0].split('filename=')[1].trim(),
    };
  });

  return { files: boundaryPartsParsed, multipartState: multipartStateParsed };
}

export default multipartParser;
