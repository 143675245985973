import { Heading } from '@sede-x/shell-ds-react-framework';
import React from 'react';
import { DataProductType } from '../../../../../../state/models/dataProductsModel/types';
import { Container } from './Schema.styles';
import SwaggerComponent from '../../../../../../components/SwaggerComponent/SwaggerComponent';
import { ErrorBoundary } from 'react-error-boundary';

interface Props {
  dataProduct: DataProductType;
}

const Schema: React.FC<Props> = ({ dataProduct }) => {
  return (
    <Container>
      <Heading level={2} bold gutter>
        Schema
      </Heading>
      <ErrorBoundary fallback={<Heading>GraqhQL Not supported</Heading>}>
        {dataProduct.endpoints.map((ep) =>
          ep.schema && (ep.schema.openapi || ep.schema.swagger) ? (
            <SwaggerComponent
              spec={ep.schema}
              key={`${ep.path}-${ep.serviceUrl}`}
            />
          ) : (
            <></>
          ),
        )}
      </ErrorBoundary>
    </Container>
  );
};

export default Schema;
