import styled from 'styled-components';

export const PopupContainer = styled.div`
  display: flex;
  flex-direction: column;
  &:hover {
    cursor: pointer;
  }
`;

export const VersionItem = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 12px;
  &:hover {
    background: ${({ theme }) => theme.focus.medium};
  }
`;
