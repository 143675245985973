import { useEffect, useState } from 'react';
import {
  useSearchParams,
  createSearchParams,
  useNavigate,
} from 'react-router-dom';
import routes from '../routes/routes';

const generateParams = (
  id: string,
  params: string[] | undefined,
  defaultParams: string | null,
) => {
  return {
    id,
    params: params ? params.join(',') : defaultParams ?? '',
  };
};

const currentFilterNames = ['search', 'type', 'terms', 'tags'] as const;
export type FilterNames = typeof currentFilterNames[number];

const generateCurrentFilter: (
  type: string,
  value?: string[],
) => {
  id: string;
  values: string[] | undefined;
} = (type, value) => {
  return { id: type, values: value ?? undefined };
};

const getParams = (params: URLSearchParams) => {
  return currentFilterNames.reduce((acc: { [key: string]: string[] }, cFN) => {
    const value = params.get(cFN)?.split(',');
    if (value) {
      acc[cFN] = value;
    }
    return acc;
  }, {});
};

function arrayEquals(a: string[], b: string[]) {
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[index])
  );
}

const hasSameParams = (
  oldParams: { [key: string]: string[] },
  newParams: { [key: string]: string[] },
) => {
  const hasPropsSameParams = currentFilterNames.map(
    (param) =>
      (!oldParams[param] && !newParams[param]) ||
      arrayEquals(oldParams[param], newParams[param]),
  );

  return hasPropsSameParams.reduce((acc, item) => acc && item, true);
};

const useParamsHandler = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [params, setParams] = useState(getParams(searchParams));

  useEffect(() => {
    const newParams = getParams(searchParams);
    if (!hasSameParams(params, newParams)) {
      setParams(newParams);
    }
  }, [searchParams]);

  const resetFilters = () => {
    const currentSearch = searchParams.get('search');

    navigate({
      pathname: routes.home,
      search: currentSearch
        ? createSearchParams({ search: currentSearch }).toString()
        : undefined,
    });
  };

  const generateFilters = (filterParams: {
    type?: string[];
    search?: string;
    terms?: string[];
    tags?: string[];
  }) => {
    const currentFilters: {
      id: string;
      values: string[] | undefined;
    }[] = currentFilterNames.map((cFN) =>
      cFN === 'search'
        ? {
            id: cFN,
            values:
              filterParams.search !== undefined
                ? [filterParams.search]
                : undefined,
          }
        : generateCurrentFilter(
            cFN,
            filterParams[cFN as keyof typeof filterParams] as string[],
          ),
    );

    return currentFilters.reduce((acc: { [key: string]: string }, cF) => {
      const currentValues = searchParams.get(cF.id);
      const valueParsed = generateParams(
        cF.id,
        cF.values,
        currentValues,
      ).params;
      if (valueParsed) {
        acc[cF.id] = valueParsed;
      }
      return acc;
    }, {});
  };

  const getHref = ({
    type,
    search,
    terms,
    tags,
  }: {
    type?: string[];
    search?: string;
    terms?: string[];
    tags?: string[];
  }) => {
    const filters = generateFilters({ type, search, terms, tags });
    return `${routes.home}?${createSearchParams(filters).toString()}`;
  };

  const navigateWithParams = ({
    type,
    search,
    terms,
    tags,
  }: {
    type?: string[];
    search?: string;
    terms?: string[];
    tags?: string[];
  }) => {
    const filters = generateFilters({ type, search, terms, tags });

    navigate({
      pathname: routes.home,
      search: createSearchParams(filters).toString(),
    });
  };

  return {
    navigateWithParams,
    params,
    resetFilters,
    getHref,
  };
};

export default useParamsHandler;
