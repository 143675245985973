import { Form } from '@sede-x/glass-design-library';
import LinkFormHelper from '../../../../../components/LinkFormHelper/LinkFormHelper';
import {
  SHORT_DESCRIPTION_FORMAT,
  URL_FORMAT,
} from '../../../../../utils/regexpConst';
import { useTranslation } from 'react-i18next';
import {
  ProductDetailsSectionButtons,
  ProductDetailsSectionHeading,
} from './ProductDetailsSection';
import CustomInputField from '../../../../../components/FormFields/CustomInputField/CustomInputField';

const ProductDetailsSectionGlass = ({
  onSubmit,
  onCancel,
  defaultValues = {},
  nameEditable = false,
}: {
  onSubmit: (values: { [key: string]: unknown }) => void;
  onCancel: () => void;
  defaultValues?: { [key: string]: unknown };
  nameEditable?: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <Form
      onCancel={onCancel}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      {...ProductDetailsSectionButtons(t)}
      formConfig={[
        { ...ProductDetailsSectionHeading(t) },
        [
          {
            label: t('admin_create-product_details_glass_step-name_title'),
            name: 'title',
            description: t(
              'admin_create-product_details_glass_step-name_description',
            ),
            type: 'TEXT_INPUT',
          },
          {
            label: t('admin_create-product_details_glass_step-icon_title'),
            name: 'icon',
            description: t(
              'admin_create-product_details_glass_step-icon_description',
            ),
            topHelper: (
              <LinkFormHelper
                href={t(
                  'admin_create-product_details_glass_step-icon_helper_link',
                )}
              >
                {t('admin_create-product_details_glass_step-icon_helper_text')}
              </LinkFormHelper>
            ),
            placeholder: t(
              'admin_create-product_details_glass_step-icon_placeholder',
            ),
            type: 'TEXT_INPUT',
          },
        ],
        {
          label: t(
            'admin_create-product_details_glass_step-short_description_title',
          ),
          name: 'description',
          description: t(
            'admin_create-product_details_glass_step-short_description_description',
          ),
          placeholder: t(
            'admin_create-product_details_glass_step-short_description_placeholder',
          ),
          required: true,
          type: 'TEXT_INPUT',
          validator: new RegExp(SHORT_DESCRIPTION_FORMAT(1, 100)),
        },
        {
          label: t('admin_create-product_details_glass_step-description_title'),
          name: 'longDescription',
          description: t(
            'admin_create-product_details_glass_step-description_description',
          ),
          placeholder: t(
            'admin_create-product_details_glass_step-description_placeholder',
          ),
          type: 'TEXT_AREA',
          required: true,
        },
        {
          label: t('admin_create-product_details_glass_step-link_title'),
          name: 'link',
          description: t(
            'admin_create-product_details_glass_step-link_description',
          ),
          placeholder: t(
            'admin_create-product_details_glass_step-link_placeholder',
          ),
          required: true,
          type: 'CUSTOM',
          component: CustomInputField,
          validator: new RegExp(URL_FORMAT),
        },
        [
          {
            label: t('admin_create-product_details_glass_step-external_title'),
            name: 'external',
            description: t(
              'admin_create-product_details_glass_step-external_description',
            ),
            type: 'CHECKBOX',
            text: t('admin_create-product_details_glass_step-external_label'),
          },
          {
            label: t('admin_create-product_details_glass_step-3paw_title'),
            name: 'threePaw',
            description: t(
              'admin_create-product_details_glass_step-3paw_description',
            ),
            type: 'CHECKBOX',
            text: t('admin_create-product_details_glass_step-3paw_label'),
          },
        ],
      ]}
    />
  );
};

export default ProductDetailsSectionGlass;
