import { Text } from '@sede-x/shell-ds-react-framework';
import { Container, LabelStyled, TextContainer } from './BatchSection.styles';
import { useTranslation } from 'react-i18next';

type Props = {
  values: {
    dataVolume?: number;
    batchSize?: number;
    numberOfPages?: number;
    batchFrequency?: string;
    dataSource?: string;
  };
};

const BatchSection = ({ values }: Props) => {
  const { t } = useTranslation();
  return (
    <Container>
      <TextContainer>
        <LabelStyled bold>{t('details-batch-data_volume_label')}</LabelStyled>
        <Text>{values.dataVolume}</Text>
      </TextContainer>
      <TextContainer>
        <LabelStyled bold>{t('details-batch-batch_size')}</LabelStyled>
        <Text>{values.batchSize}</Text>
      </TextContainer>
      <TextContainer>
        <LabelStyled bold>{t('details-batch-batch_frequency')}</LabelStyled>
        <Text>{values.batchFrequency}</Text>
      </TextContainer>
      <TextContainer>
        <LabelStyled bold>{t('details-batch-number_pages')}</LabelStyled>
        <Text>{values.numberOfPages}</Text>
      </TextContainer>
      <TextContainer>
        <LabelStyled bold>{t('details-batch-data_source')}</LabelStyled>
        <Text>{values.dataSource}</Text>
      </TextContainer>
    </Container>
  );
};

export default BatchSection;
