import { RematchDispatch } from '@rematch/core';
import { RootModel } from '../../store';
import GlassProductService, {
  GlassProductType,
} from '../../../services/GlassProductService';
import { GlassProductAdminType } from './types';

const effects = (dispatch: RematchDispatch<RootModel>) => ({
  cleanError() {
    dispatch.adminGlass.setCurrentError(undefined);
    dispatch.adminGlass.setHasError(false);
  },
  cleanCurrentGlassProduct() {
    dispatch.adminGlass.setCurrentGlassProduct({} as GlassProductAdminType);
  },
  async createGlassProduct(values: GlassProductType) {
    let status = null;
    try {
      dispatch.adminGlass.setIsLoading(true);
      await GlassProductService.createGlassProduct(values);

      dispatch.adminGlass.setHasError(false);
      dispatch.adminGlass.setCurrentError(undefined);
      status = 200;
    } catch (e) {
      dispatch.adminGlass.setCurrentError(
        //@ts-ignore
        e.response?.data?.message || 'An error occurred',
      );
      dispatch.adminGlass.setHasError(true);
      //@ts-ignore
      status = e?.response?.status || 500;
    } finally {
      dispatch.adminGlass.setIsLoading(false);
    }
    return status;
  },

  async getAllGlassProducts({ page }: { page: number }): Promise<any> {
    try {
      const pageSize = 20;
      dispatch.adminGlass.setIsLoading(true);

      const response = await GlassProductService.getAllGlassProducts({
        limit: pageSize,
        offset: (page - 1) * pageSize,
      });

      dispatch.adminGlass.setAllGlassProduct({
        allGlassProducts: response,
      });
      dispatch.adminGlass.setHasError(false);
    } catch (e) {
      dispatch.adminGlass.setHasError(true);
    } finally {
      dispatch.adminGlass.setIsLoading(false);
    }
  },

  async getOwnGlassProducts({ page }: { page: number }) {
    try {
      const pageSize = 10;
      dispatch.adminGlass.setIsLoading(true);

      const response = await GlassProductService.getOwnGlassProducts({
        limit: pageSize,
        offset: (page - 1) * pageSize,
      });

      const { totalCount, glassProducts } = response;
      dispatch.adminGlass.setOwnGlassProducts({
        ownGlassProducts: glassProducts,
        totalGlassItems: totalCount,
      });
      dispatch.adminGlass.setHasError(false);
    } catch (e) {
      dispatch.adminGlass.setHasError(true);
    } finally {
      dispatch.adminGlass.setIsLoading(false);
    }
  },
  async getCurrentGlassProduct({ id }: { id: string }) {
    try {
      dispatch.adminGlass.setIsLoading(true);
      const glassProduct = await GlassProductService.getGlassProduct(id);

      dispatch.adminGlass.setCurrentGlassProduct(glassProduct);

      dispatch.adminGlass.setHasError(false);
    } catch (e) {
      dispatch.adminGlass.setHasError(true);
    } finally {
      dispatch.adminGlass.setIsLoading(false);
    }
  },
  async updateGlassProduct(values: GlassProductType) {
    let status = null;
    try {
      dispatch.adminGlass.setIsLoading(true);

      await GlassProductService.updateGlassProduct(values);

      dispatch.adminGlass.setHasError(false);
      dispatch.adminGlass.setCurrentError(undefined);
      status = 200;
    } catch (e) {
      dispatch.adminGlass.setCurrentError(
        //@ts-ignore
        e.response?.data?.message || 'An error occurred during update',
      );
      dispatch.adminGlass.setHasError(true);
      //@ts-ignore
      status = e?.response?.status || 500;
    } finally {
      dispatch.adminGlass.setIsLoading(false);
    }
    return status;
  },
  async deleteGlassProduct({ id }: { id: string }) {
    try {
      dispatch.adminGlass.setIsLoading(true);
      await GlassProductService.deleteGlassProduct(id);
      dispatch.adminGlass.setHasError(false);
    } catch (e) {
      dispatch.adminGlass.setCurrentError(
        //@ts-ignore
        e.response?.data?.message || 'An error occurred during delete',
      );
      dispatch.adminGlass.setHasError(true);
    } finally {
      dispatch.adminGlass.setIsLoading(false);
    }
  },
});

export default effects;
