const SuccessIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={173} height={172} fill="none">
    <path
      fill="#fff"
      d="M154.448 85.565c.005 16.765-5.39 33.047-15.321 46.237-9.932 13.19-23.824 22.524-39.453 26.507-15.628 3.983-32.087 2.384-46.74-4.541-14.651-6.924-26.649-18.774-34.07-33.649l9.104-4.941c6.293 12.672 16.455 22.802 28.884 28.79 12.429 5.988 26.417 7.495 39.758 4.283 13.342-3.212 25.278-10.961 33.926-22.024 8.648-11.063 13.516-24.811 13.836-39.076.321-14.264-3.925-28.235-12.067-39.707-8.142-11.473-19.717-19.794-32.9-23.653-13.182-3.86-27.223-3.036-39.907 2.34-12.684 5.376-23.29 14.999-30.145 27.35H51.82v10.695h-35.92l-5.132-5.347v-37.43H21.03V47c8.09-14.03 20.37-24.91 34.96-30.974s30.684-6.979 45.82-2.604 28.478 13.798 37.985 26.828c9.507 13.03 14.654 28.946 14.653 45.314Z"
    />
    <path
      fill="#fff"
      fillOpacity={0.994}
      d="M81.783 113.222h-9.526l-23.214-24.19 9.527-9.927 18.517 19.296 39.917-37.692 9.709 10.117-44.93 42.396Z"
    />
  </svg>
);
export default SuccessIcon;
