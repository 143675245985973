import { Sentiments } from '@sede-x/shell-ds-react-framework';
import { AlertStyled } from './BannerField.styles';

interface Props {
  sentiment: Sentiments;
  text: string;
}

const BannerField =
  ({ sentiment, text }: Props) =>
  () => {
    return <AlertStyled title={text} float size="small" />;
  };

export default BannerField;
