import { mySubscriptionsState, DataDomainType } from './types';

const reducers = {
  setDataDomainsData(
    state: mySubscriptionsState,
    payload: { DataDomain: DataDomainType[] },
  ) {
    return {
      ...state,
      DataDomain: payload.DataDomain,
    };
  },
};
export default reducers;
