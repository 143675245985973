import { Icons, Loading } from '@sede-x/shell-ds-react-framework';
import { useState } from 'react';
import { DataProductType } from '../../../../../../../../../state/models/dataProductsModel/types';
import { DetailButton } from '../../TheButton.styles';
import { useDispatch } from 'react-redux';
import ResultModal from '../../ResultModal/ResultModal';
import { AxiosError } from 'axios';
import RequestAccessModal from '../components/RequestAccessModal';
import AccessRequiredModal from '../components/AccessRequiredModal';

interface Props {
  dataProduct: DataProductType;
}

const errorCode400 = 400;
const errorCode401 = 401;
const successCode = 200;

const DirectDownloadButton = ({ dataProduct }: Props) => {
  const dispatch = useDispatch();
  const [accessRequired, setAccessRequired] = useState(false);
  const [requestingAccess, setRequestingAccess] = useState(false);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleRequestAccess = () => {
    setAccessRequired(false);
    setRequestingAccess(true);
  };

  const handleDownload = async () => {
    try {
      setIsLoading(true);
      const response: {
        title: string;
        endpoint: string;
        status: number;
        error?: string;
      }[] = await dispatch.dataProducts.downloadQueries({
        queries: [0],
        dpName: dataProduct.name,
      });
      const currentStatus = response?.[0]?.status || successCode;
      if (currentStatus >= errorCode400) {
        if (currentStatus === errorCode401) {
          setAccessRequired(true);
        } else {
          setError(true);
        }
      }
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.response?.status && err.response.status === errorCode401) {
          setAccessRequired(true);
        } else {
          setError(true);
        }
      } else {
        setError(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <DetailButton
        icon={!isLoading ? <Icons.InboxDownloadFilled /> : <Loading />}
        disabled={isLoading}
        iconPosition="right"
        onClick={handleDownload}
      >
        Download data
      </DetailButton>
      {accessRequired && (
        <AccessRequiredModal
          onCloseDialog={() => setAccessRequired(false)}
          onRequestAccess={handleRequestAccess}
        />
      )}
      {requestingAccess && (
        <RequestAccessModal
          dataProduct={dataProduct}
          onCancel={() => setRequestingAccess(false)}
        />
      )}
      {error && (
        <ResultModal
          variant="error"
          onClose={() => {
            setError(false);
          }}
        />
      )}
    </>
  );
};

export default DirectDownloadButton;
