import React, { useState } from 'react';
import { TagType } from '../../state/models/dataProductsModel/types';
import { StyledTag, StyledTagTerm } from './TagComponent.styles';
import { useNavigate } from 'react-router-dom';

interface Props {
  tag: TagType;
  selectable?: boolean;
  selected?: boolean;
  href?: string;
  type?: 'tag' | 'term';
}

const tagVariant = {
  tag: StyledTag,
  term: StyledTagTerm,
};

const TagComponent: React.FC<Props> = ({
  tag,
  selectable = false,
  selected = false,
  href,
  type = 'tag',
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();

  const Variant = tagVariant[type];
  const handleClick = (e: React.MouseEvent<HTMLElement>, link: string) => {
    e.preventDefault();
    e.stopPropagation();
    navigate(link);
  };

  return (
    <Variant
      selectable={selectable}
      hovered={isHovered}
      onMouseEnter={() => selectable && setIsHovered(true)}
      onMouseLeave={() => selectable && setIsHovered(false)}
      key={tag.name}
      shape="round"
      variant={isHovered || selected ? 'filled' : 'outlined'}
      sentiment={'neutral'}
      size="medium"
      selected={selected}
      onClick={(e) => selectable && href && handleClick(e, href)}
    >
      {tag.name}
    </Variant>
  );
};

export default TagComponent;
