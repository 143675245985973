const SuccessIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={58}
    height={60}
    fill="none"
    data-testid="resultmodal-icon-success"
  >
    <path
      fill="#98CBB1"
      fillRule="evenodd"
      d={`M32.87 1.505a5.308 5.308 0 0 0-7.406 0l-4.196 4.077-5.78-.821c-2.845-.404-5.495 1.53-5.992 
      4.372l-1.008 5.776-5.158 2.748C.793 19.01-.22 22.14 1.042 24.731L3.606 30l-2.564 5.269C-.219 
      37.86.793 40.99 3.33 42.343l5.158 2.748 1.008 5.776c.497 2.842 3.147 4.776 5.991 4.372l5.781-.821 
      4.196 4.078a5.308 5.308 0 0 0 7.405 0l4.196-4.078 5.781.821c2.844.404 5.495-1.53 5.99-4.372l1.01-5.776 
      5.157-2.748c2.537-1.353 3.55-4.482 2.288-7.074L54.727 
      30l2.564-5.269c1.262-2.592.25-5.721-2.288-7.074l-5.157-2.748-1.01-5.776c-.495-2.842-3.146-4.776-5.99-4.372l-5.78.821-4.197-4.077Zm10.722 
      8.552-8.358 1.187-6.067-5.895-6.067 5.895-8.359-1.187-1.458 8.351-7.457 3.974L9.533 30l-3.707 7.618 7.457 3.974 1.458 
      8.351 8.359-1.187 6.067 5.895 6.067-5.895 8.358 1.187 1.458-8.351 7.457-3.974L48.8 
      30l3.707-7.618-7.457-3.974-1.458-8.351ZM23.082 40.32l-7.578-7.802a1.21 1.21 0 0 1 0-1.675l1.619-1.667a1.128 
      1.128 0 0 1 1.626-.002l5.147 5.279 14.35-14.773a1.128 1.128 0 0 1 1.63.003l1.623 1.683a1.21 1.21 0 0 1-.003 
      1.673L24.71 40.319a1.128 1.128 0 0 1-1.627 0Z`}
      clipRule="evenodd"
    />
  </svg>
);
export default SuccessIcon;
