import axiosInstance from './GlassAxios';

//props required from form
export interface GlassProductType {
  title: string;
  description: string;
  icon: string;
  link: string;
  longDescription: string;
  allowInfo?: boolean;
  owner: string;
  technicalOwner: string;
  responsible: string;
  responsibleEmail: string;
  folder?: boolean;
  parent?: string;
  category?: string;
  external?: boolean;
}

//full product model from Mastermind response
export interface GlassProductProps {
  title: string;
  lowername: string;
  description: string;
  icon: string;
  extra?: string;
  link: string;
  visible?: boolean;
  longDescription: string;
  allowInfo?: boolean;
  owner: string;
  technicalOwner: string;
  responsible: string;
  responsibleLink: string; //to be removed
  responsibleEmail: string;
  folder?: boolean;
  parent?: string;
  category?: string;
  external?: boolean;
}

const createGlassProduct = async (values: GlassProductType) => {
  const formData = JSON.stringify(values);
  return axiosInstance.post(`products/`, formData, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'text/plain',
    },
  });
};

const ownProductsLimit = 1000;

const getOwnGlassProducts = async ({
  limit = ownProductsLimit,
  offset = 0,
}: {
  limit: number;
  offset: number;
}) => {
  const result = await axiosInstance.get(
    `products/user?limit=${limit}&offset=${offset}`,
  );
  return {
    totalCount: result?.data?.totalItems || 0,
    glassProducts: result?.data?.items || [],
  };
};

const getGlassProduct = async (id: string) => {
  const result = await axiosInstance.get(`products/id/${id}`);
  return result?.data || {};
};

const getAllGlassProducts = async ({
  limit = ownProductsLimit,
  offset = 0,
}: {
  limit: number;
  offset: number;
}) => {
  const result = await axiosInstance.get(`products/`);
  return result?.data.items || {};
};

const updateGlassProduct = async (values: GlassProductType) => {
  const formData = JSON.stringify(values);
  return axiosInstance.patch(`products/`, formData, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'text/plain',
    },
  });
};

const deleteGlassProduct = async (id: string) => {
  await axiosInstance.delete(`products/${id}`);
};

const getLikes = async (param: string | string[]) => {
  const dataParam = Array.isArray(param) ? param.join(',') : param;
  const response = await axiosInstance.get(`/likes?name=${dataParam}`);
  return Array.isArray(param) ? response.data : response.data[0];
};

const saveLike = async (name: string, active: boolean) => {
  return axiosInstance.post(`/likes?name=${name}&liked=${active}`);
};

const GlassProductService = {
  createGlassProduct,
  getOwnGlassProducts,
  getGlassProduct,
  updateGlassProduct,
  deleteGlassProduct,
  getLikes,
  saveLike,
  getAllGlassProducts,
};

export default GlassProductService;
