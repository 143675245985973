import { Form } from '@sede-x/glass-design-library';
import { DataProductVariant } from '../../../../../utils/dataProductTypes';
import ConfigurationFieldsAPI from './ConfigurationFieldsAPI';
import ConfigurationFieldsEvents from './ConfigurationFieldsEvent';
import ConfigurationFieldsStreaming from './ConfigurationFieldsStreaming';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import routes from '../../../../../routes/routes';

const ConfigurationSection = ({
  onSubmit,
  onCancel,
  defaultValues,
  type,
  isUpdating = false,
}: {
  onSubmit: (values: { [key: string]: unknown }) => void;
  onCancel: () => void;
  defaultValues?: { [key: string]: unknown };
  type: DataProductVariant;
  isUpdating?: boolean;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const fields = {
    API: ConfigurationFieldsAPI(t, isUpdating),
    Event: ConfigurationFieldsEvents(t, isUpdating),
    Streaming: ConfigurationFieldsStreaming(t, isUpdating),
    Resource: [],
    Batch: [],
    Glass: [],
  };
  return (
    <Form
      onCancel={onCancel}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      submitButton={{
        text: t('admin_create-configuration_step-next_button'),
        variant: 'filled',
      }}
      cancelButton={{
        text: t('admin_create-configuration_step-back_button'),
        variant: 'outlined',
      }}
      customButtons={
        isUpdating
          ? [
              {
                text: t('admin_create-product_details_back_button'),
                variant: 'outlined',
                onClick: () => navigate(routes.admin),
              },
            ]
          : []
      }
      formConfig={fields[type]}
    />
  );
};

export default ConfigurationSection;
