import { RematchDispatch } from '@rematch/core';
import DataProductService from '../../../services/DataProductService';
import { RootModel, RootState } from '../../store';
import {
  DataProductType,
  DownloadQuery,
  EndpointType,
  VersionType,
} from './types';
import dataProductTypes from '../../../utils/dataProductTypes';
import GlassProductService from '../../../services/GlassProductService';

export type LikesType = { name: string; likes: number; userHasLiked: boolean };

async function getLikesForDataProducts(dataProducts: DataProductType[]) {
  try {
    const productNames = dataProducts.map((dP: DataProductType) => dP.name);
    return await GlassProductService.getLikes(productNames);
  } catch (error) {
    return [];
  }
}

function enrichResponse(
  response: { dataProducts: DataProductType[]; totalCount: number },
  likes: LikesType[],
) {
  return {
    ...response,
    dataProducts: response.dataProducts.map((dP: DataProductType) => {
      const likesNumber = likes.find(
        (like: LikesType) => like.name === dP.name,
      );
      return {
        ...dP,
        likes: likesNumber?.likes ?? 0,
      };
    }),
  };
}

const effects = (dispatch: RematchDispatch<RootModel>) => ({
  async getDataProducts(
    {
      keywords,
      limit,
      offset,
      sort = 'name',
      filters = [],
    }: {
      keywords: string;
      limit: number;
      offset: number;
      sort?: string;
      filters?: { id: string; values: string[] }[];
    },
    state: RootState,
  ) {
    try {
      dispatch.dataProducts.setIsLoading(true);

      const hasNotFilters =
        filters.length === 0 ||
        (filters.every((f) => f.values.length === 0) && keywords === '');

      if (hasNotFilters && offset === 0) {
        //Cached version
        if (state.dataProducts.cache.dataProducts.length > 0) {
          dispatch.dataProducts.setDataProducts({
            dataProducts: state.dataProducts.cache.dataProducts,
            totalItems: state.dataProducts.cache.totalItems,
          });
        } else {
          // Renew cached version
          const response = await DataProductService.searchDataProducts({
            keywords: '',
            limit,
            offset,
            sort,
            filters: {},
          });

          const likes = await getLikesForDataProducts(response.dataProducts);
          const { totalCount, dataProducts } = enrichResponse(response, likes);

          dispatch.dataProducts.setDataProducts({
            dataProducts,
            totalItems: totalCount,
          });
          dispatch.dataProducts.setCacheDataProducts({
            dataProducts,
            totalItems: totalCount,
          });
        }
      } else {
        //Uncached version
        const currentFiltersFixed = filters.reduce(
          (
            acc: { [id: string]: string },
            item: { id: string; values: string[] },
          ) => {
            if (item.values.length > 0) {
              acc[item.id] = item.values.join(',');
            }
            return acc;
          },
          {},
        );
        const response = await DataProductService.searchDataProducts({
          keywords,
          limit,
          offset,
          sort,
          filters: currentFiltersFixed,
        });

        const likes = await getLikesForDataProducts(response.dataProducts);

        const { totalCount, dataProducts } = enrichResponse(response, likes);

        dispatch.dataProducts.setDataProducts({
          dataProducts,
          totalItems: totalCount,
        });
      }

      dispatch.dataProducts.setHasError(false);
    } catch (e) {
      dispatch.dataProducts.setHasError(true);
    } finally {
      dispatch.dataProducts.setIsLoading(false);
    }
  },

  async resetCache() {
    dispatch.dataProducts.setCacheDataProducts({
      dataProducts: [],
      totalItems: 0,
    });
  },

  async getCurrentDataProduct({
    id,
    version,
    referral,
  }: {
    id: string;
    version?: string;
    referral?: string;
  }) {
    try {
      dispatch.dataProducts.setIsLoading(true);
      const dataProduct = await DataProductService.getCurrentDataProduct({
        id,
        version,
        referral,
      });

      let likes;
      try {
        likes = await GlassProductService.getLikes(dataProduct.name);
      } catch (error) {
        likes = {
          likes: 0,
          userHasLiked: false,
        };
      }
      let queries: DownloadQuery[] = [];
      try {
        if (dataProduct.type === 'API') {
          queries = await DataProductService.getDPQueries(
            dataProduct.name,
            version,
          );
        }
      } catch (error) {
        queries = [];
      }

      let endpoints: EndpointType[] = dataProduct.endpoints || [];
      try {
        if (
          endpoints.length > 0 &&
          dataProduct.accessType !== 'protected' &&
          dataProduct.type === dataProductTypes.API
        ) {
          const endpointRequest = endpoints.map((ep) =>
            DataProductService.getEndpointSchema(
              dataProduct.name,
              ep.path,
              version,
            ),
          );
          const result = await Promise.all(endpointRequest);
          endpoints = endpoints.map((ep, index) => ({
            ...ep,
            schema: result[index],
          }));
        }
      } catch (error) {
        endpoints = [];
      }

      const versions: VersionType[] = await DataProductService.getVersions(
        dataProduct.name,
      );

      dispatch.dataProducts.setCurrentDataProduct({
        ...dataProduct,
        queries,
        endpoints,
        versions,
        likes: likes.likes,
        userHasLiked: likes.userHasLiked,
      });
      dispatch.dataProducts.setHasError(false);
    } catch (e) {
      dispatch.dataProducts.setHasError(true);
    } finally {
      dispatch.dataProducts.setIsLoading(false);
    }
  },

  async downloadQueries({
    queries,
    dpName,
  }: {
    queries: number[];
    dpName: string;
  }) {
    return DataProductService.downloadQueries(dpName, queries);
  },

  async getDomains(_?: undefined, state?: RootState) {
    const terms = state?.dataProducts.terms;
    if (!terms || terms.length <= 0) {
      const response = await DataProductService.getDataDomains();
      dispatch.dataProducts.setTerms(response);
    }
  },

  async getTags(_?: undefined, state?: RootState) {
    const tags = state?.dataProducts.tags;
    if (!tags || tags.length <= 0) {
      const response = await DataProductService.getTags();
      dispatch.dataProducts.setTags(response);
    }
  },

  async saveLike({ liked, name }: { liked: boolean; name: string }) {
    await GlassProductService.saveLike(name, liked);
    const likesNumber = await GlassProductService.getLikes(name);
    dispatch.dataProducts.setLikes(likesNumber);
  },
});

export default effects;
