import React, { useState } from 'react';
import {
  Divider,
  Heading,
  Icons,
  Modal,
  Text,
} from '@sede-x/shell-ds-react-framework';
import {
  BottomContainer,
  TopContainer,
  ConfirmButton,
  TextContainer,
} from './ConfirmModal.style';
import { useTranslation } from 'react-i18next';

const ConfirmModal = ({ onClose }: { onClose: () => void }) => {
  const [modalOpen, setModalOpen] = useState(true);
  const { t } = useTranslation();
  const handleClose = () => {
    setModalOpen(false);
    onClose();
  };
  return (
    <Modal
      open={modalOpen}
      onClose={handleClose}
      padding="tight"
      showHeader={false}
      bodyPadding={false}
      width={414}
      closable={false}
    >
      <>
        <TopContainer>
          <Icons.CheckCircle width={32} height={32} />
          <Heading level={3} bold>
            {t('feedback_form_confirmation-title')}
          </Heading>
          <TextContainer>
            <Text>{t('feedback_form_confirmation-description')}</Text>
          </TextContainer>
        </TopContainer>
        <Divider />
        <BottomContainer>
          <ConfirmButton variant="filled" onClick={onClose}>
            {t('feedback_form_confirmation_button-text')}
          </ConfirmButton>
        </BottomContainer>
      </>
    </Modal>
  );
};

export default ConfirmModal;
