import styled from 'styled-components';

export const Container = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.background.section};
  border: 2px dashed ${({ theme }) => theme.border.medium};
  cursor: pointer;
`;
