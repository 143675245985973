import Layout from '../../components/Layout/Layout';
import InsightsPageComponent from './components/InsightsPageComponent/InsightsPageComponent';

const InsightsPage = () => {
  return (
    <Layout
      variant="CENTERED_COLUMN"
      content={<InsightsPageComponent />}
      hasSearchBar
    />
  );
};

export default InsightsPage;
