import Layout from '../../../../components/Layout/Layout';
import AdminFormCreateSection from '../sections/CreationForms/AdminFormCreateSection/AdminFormCreateSection';

const AdminFormCreatePageAPI = () => {
  return (
    <Layout
      variant="CENTERED_COLUMN"
      content={<AdminFormCreateSection type="API" />}
      padding="0px"
    />
  );
};

export default AdminFormCreatePageAPI;
