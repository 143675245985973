import { Form } from '@sede-x/glass-design-library';
import { SHELL_EMAIL_FORMAT } from '../../../../../utils/regexpConst';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const GovernanceSectionGlass = ({
  onSubmit,
  onCancel,
  defaultValues,
}: {
  onSubmit: (values: { [key: string]: unknown }) => void;
  onCancel: () => void;
  defaultValues?: { [key: string]: unknown };
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [currentOwner, setCurrentOwner] = useState<string>();

  return (
    <Form
      onCancel={onCancel}
      onSubmit={onSubmit}
      onChange={({ owner }) => setCurrentOwner(owner)}
      defaultValues={defaultValues}
      submitButton={{
        text: t('admin_create-governance_step-submit_button'),
        variant: 'filled',
      }}
      cancelButton={{
        text: t('admin_create-governance_step-back_button'),
        variant: 'outlined',
      }}
      customButtons={[
        {
          text: t(
            'admin_create-glass_product_governance_step-custom-cancel-button',
          ),
          variant: 'outlined',
          onClick: () => navigate(-1),
        },
      ]}
      formConfig={[
        {
          name: 'governanceTitle',
          subtitle: t('admin_create-governance_step-title_description'),
          title: t('admin_create-governance_step-title'),
          type: 'HEADING',
        },
        [
          {
            label: t('admin_create-governance_glass_step-owner_title'),
            name: 'owner',
            description: t(
              'admin_create-governance_glass_step-owner_description',
            ),
            placeholder: t(
              'admin_create-governance_glass_step-owner_placeholder',
            ),
            required: true,
            type: 'TEXT_INPUT',
            validator: new RegExp(SHELL_EMAIL_FORMAT),
          },
          {
            label: t('admin_create-governance_glass_step-expert_title'),
            name: 'technicalOwner',
            description: t(
              'admin_create-governance_glass_step-expert_description',
            ),
            placeholder: t(
              'admin_create-governance_glass_step-expert_placeholder',
            ),
            required: true,
            type: 'TEXT_INPUT',
            customValidators: [
              {
                reason: t('error_generic-invalid_format'),
                validate: async (value) => !!SHELL_EMAIL_FORMAT.exec(value),
              },
              {
                reason: t(
                  'admin_create-governance_glass_step-technical_owner_duplicate_error',
                ),
                validate: async (value) => value !== currentOwner,
              },
            ],
          },
        ],
        [
          {
            label: t('admin_create-governance_glass_step-focal_title'),
            name: 'responsible',
            placeholder: t(
              'admin_create-governance_glass_step-focal_placeholder',
            ),
            description: t(
              'admin_create-governance_glass_step-focal_description',
            ),
            type: 'TEXT_INPUT',
            required: true,
          },
          {
            label: t('admin_create-governance_glass_step-focal_email_title'),
            name: 'responsibleEmail',
            placeholder: t(
              'admin_create-governance_glass_step-focal_email_placeholder',
            ),
            description: t(
              'admin_create-governance_glass_step-focal_email_description',
            ),
            type: 'TEXT_INPUT',
            validator: new RegExp(SHELL_EMAIL_FORMAT),
            required: true,
          },
        ],
      ]}
    />
  );
};

export default GovernanceSectionGlass;
