import 'react-quill/dist/quill.snow.css';
import { TextInput } from '@sede-x/shell-ds-react-framework';

interface Props {
  value: string;
}

const DisabledNameField = ({ value }: Props) => {
  return <TextInput disabled value={value} />;
};

export default DisabledNameField;
