const InfoIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={55}
    height={54}
    fill="none"
    data-testid="resultmodal-icon-info"
  >
    <path
      fill="#FAD491"
      fillRule="evenodd"
      d="M48.833 27c0 11.782-9.551 21.333-21.333 21.333-11.782 0-21.334-9.551-21.334-21.333 0-11.782 9.552-21.334 
      21.334-21.334S48.833 15.218 48.833 27Zm5.333 0c0 14.727-11.939 26.666-26.666 26.666C12.772 53.666.833 
      41.727.833 27 .833 12.272 12.773.333 27.5.333 42.227.333 54.166 12.273 54.166 27Zm-28 13.333A1.333 1.333 
      0 0 1 24.833 39v-2.667c0-.736.597-1.333 1.333-1.333h2.667c.736 0 1.333.597 1.333 1.333V39c0 .736-.597 1.333-1.333 
      1.333h-2.667Zm2.667-26.667c.736 0 1.333.597 1.333 1.334v13.333c0 .736-.597 1.333-1.333 1.333h-2.667a1.333 1.333 0 0 
      1-1.333-1.333V15c0-.737.597-1.334 1.333-1.334h2.667Z"
      clipRule="evenodd"
    />
  </svg>
);
export default InfoIcon;
