import { TextInput } from '@sede-x/shell-ds-react-framework';
import React, { useEffect, useState } from 'react';

interface Props {
  onChange: (value: string) => void;
  value: string;
}

const CustomInputField = ({ onChange, value }: Props) => {
  const [embedUrl, setEmbedUrl] = useState(value);

  useEffect(() => {
    try {
      const url = new URL(value);
      const embedParam = url.searchParams.get('embed');
      if (embedParam) {
        setEmbedUrl(embedParam);
      }
    } catch (error) {}
  }, []);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setEmbedUrl(event.target.value);
    onChange(event.target.value);
  };

  return <TextInput value={embedUrl} onChange={handleChange} />;
};

export default CustomInputField;
