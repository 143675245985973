import { Icons } from '@sede-x/shell-ds-react-framework';
import { useTranslation } from 'react-i18next';
import GenericResponsePage from '../../GenericResponsePage/GenericResponsePage';
import { useLocation } from 'react-router-dom';
import { productTypes } from '../../../../../utils/dataProductTypes';

const ErrorPageContent = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const isUpdating = !!state?.isUpdating;
  const isGlass = state?.createdType === productTypes.Glass;

  const props = isGlass
    ? {
        email: t('admin_response_glass-email'),
        textEmail: t('admin_response_glass-email_text'),
        textCreateButton: t('admin_success_create_glass-button_create'),
        textManageButton: t('admin_success_create_glass-button_manage'),
      }
    : {
        email: t('admin_error_create-email'),
        textEmail: t('admin_error_create-email_text'),
        textCreateButton: t('admin_success_create-button_create'),
        textManageButton: t('admin_success_create-button_manage'),
      };

  if (isUpdating) {
    return (
      <GenericResponsePage
        icon={<Icons.BlockCloud height={120} width={172} />}
        title={t('admin_error_update-title')}
        description={t('admin_error_update-description')}
        {...props}
        buttonsInverted
      />
    );
  } else {
    return isGlass ? (
      <GenericResponsePage
        icon={<Icons.BlockCloud height={120} width={172} />}
        title={t('admin_error_create_glass-title')}
        description={t('admin_error_create_glass-description')}
        {...props}
      />
    ) : (
      <GenericResponsePage
        icon={<Icons.BlockCloud height={120} width={172} />}
        title={t('admin_error_create-title')}
        description={t('admin_error_create-description')}
        {...props}
      />
    );
  }
};

export default ErrorPageContent;
