import { navigationType, UserState } from './types';

const reducers = {
  setUserData(
    state: UserState,
    userData: {
      email: string;
      name: string;
      roles: string[];
      navigationHistory: {
        type: navigationType;
        item?: string;
      };
    },
  ) {
    return {
      ...state,
      user: {
        ...state.user,
        ...userData,
      },
    };
  },
};
export default reducers;
