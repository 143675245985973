import { createModel } from '@rematch/core';
import reducers from './reducers';
import effects from './effects';
import { RootModel } from '../../store';
import { InsightsState } from './types';
const InsightsModel = createModel<RootModel>()({
  state: {
    dataDomains: {
      isLoading: false,
      items: [],
      totalDataProducts: 0,
      error: false,
    },
    requests: {
      isLoading: false,
      items: [],
      totalDataProducts: 0,
      error: false,
    },
  } as InsightsState,
  effects,
  reducers,
});
export default InsightsModel;
