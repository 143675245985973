import { Container, PointColoured } from './FieldCompleteness.styles';
import { Text } from '@sede-x/shell-ds-react-framework';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const minimalToPartialNumber = 4;
const partialToCompleteNumber = 6;
const FieldCompleteness = ({ value }: { value: number }) => {
  const { t } = useTranslation();
  const completenessValues = useMemo(
    () => ({
      minimal: {
        colour: '#DD1D21',
        text: t('admin_list-column_title-completeness_minimal-tag'),
      },
      partial: {
        colour: '#FFB15B',
        text: t('admin_list-column_title-completeness_partial-tag'),
      },
      complete: {
        colour: '#008557',
        text: t('admin_list-column_title-completeness_complete-tag'),
      },
    }),
    [],
  );

  const completenessPartialOrComplete =
    value <= partialToCompleteNumber
      ? completenessValues.partial
      : completenessValues.complete;
  const completenessValue =
    value <= minimalToPartialNumber
      ? completenessValues.minimal
      : completenessPartialOrComplete;

  return (
    <Container>
      <Text>{completenessValue.text}</Text>
      <PointColoured background={completenessValue.colour} />
    </Container>
  );
};

export default FieldCompleteness;
