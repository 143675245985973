import React from 'react';
import { Route, Routes } from 'react-router-dom';
import routes from '../../../routes/routes';
import AdminListSection from './components/AdminListSection/AdminListSection';
import AdminListSectionGlass from './components/AdminListSection/AdminListSectionGlass';

const AdminListPageRouting = () => {
  return (
    <Routes>
      <Route path="/" element={<AdminListSection />} />
      <Route
        path={routes.adminGlassHubRelative}
        element={<AdminListSectionGlass />}
      />
    </Routes>
  );
};

export default AdminListPageRouting;
