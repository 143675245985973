import { VERSION_FORMAT } from '../../../../../utils/regexpConst';
import TypeTranslation from '../../../../../utils/i18n/TypeTranslation';
import EmptyField from '../../../../../components/FormFields/EmptyField/EmptyField';

const ProductDetailsFieldsAPI = (t: TypeTranslation, isUpdating: boolean) =>
  !isUpdating
    ? [
        [
          {
            label: t('admin_create-product_details_step-version_title'),
            name: 'version',
            description: t(
              'admin_create-product_details_step-version_description',
            ),
            placeholder: t(
              'admin_create-product_details_step-version_placeholder',
            ),
            required: true,
            type: 'TEXT_INPUT',
            validator: new RegExp(VERSION_FORMAT),
          },
          EmptyField,
        ],
      ]
    : [];

export default ProductDetailsFieldsAPI;
