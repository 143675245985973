import Layout from '../../../../components/Layout/Layout';
import AdminCreationMainPage from '../sections/CreationForms/AdminCreationMainPage/AdminCreationMainPage';

const AdminFormCreatePageMain = () => {
  return (
    <Layout
      variant="CENTERED_COLUMN"
      content={<AdminCreationMainPage />}
      padding="0px"
    />
  );
};

export default AdminFormCreatePageMain;
