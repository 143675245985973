import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 2rem 0;
  max-width: 1024px;
  width: 100%;
`;
