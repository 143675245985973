import React, { useState } from 'react';
import { DataProductType } from '../../../../../../../state/models/dataProductsModel/types';
import { Icons } from '@sede-x/shell-ds-react-framework';
import { ReportIssueButtonStyled } from './ReportIssueButton.styles';
import ReportIssueModal from './components/ReportIssueModal';

interface Props {
  dataProduct: DataProductType;
}

const ReportIssueButton: React.FC<Props> = ({ dataProduct }) => {
  const [show, setShow] = useState(false);
  return (
    <>
      <ReportIssueButtonStyled
        icon={<Icons.CommentExlamationMark />}
        iconPosition="right"
        variant="outlined"
        onClick={() => setShow(true)}
      >
        Report issue
      </ReportIssueButtonStyled>
      {show && (
        <ReportIssueModal
          dataProduct={dataProduct}
          onCancel={() => setShow(false)}
        />
      )}
    </>
  );
};

export default ReportIssueButton;
