import FiltersComponent from '../../../../sections/FiltersComponent/FiltersComponent';
import ListComponent from '../../../../sections/ListComponent/ListComponent';
import MainBanner from '../MainBanner/MainBanner';
import {
  BodyContainer,
  CatalogueContainer,
  CenteredContainer,
  MainBannerContainer,
  InnerBodyContainer,
} from './MainSectionStyles';

const MainSection = () => {
  return (
    <CenteredContainer>
      <MainBannerContainer>
        <MainBanner />
      </MainBannerContainer>
      <BodyContainer>
        <InnerBodyContainer>
          <FiltersComponent />
          <CatalogueContainer>
            <ListComponent />
          </CatalogueContainer>
        </InnerBodyContainer>
      </BodyContainer>
    </CenteredContainer>
  );
};

export default MainSection;
