import { Divider, Heading } from '@sede-x/shell-ds-react-framework';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledDivider = styled(Divider)`
  margin-top: 24px;
  margin-bottom: 24px;
`;

export const ChartHeadingStyled = styled(Heading)`
  margin-top: 24px;
`;

export const HeadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const LeftHeadingContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DividerStyled = styled(Divider)`
  margin: 8px 0;
`;
