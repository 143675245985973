import Layout from '../../components/Layout/Layout';

const DataMapPage = () => {
  return (
    <Layout
      variant="FULLPAGE_CONTENT"
      content={
        <iframe
          style={{ display: 'flex', flex: 1 }}
          title="Cap_Data_Report"
          src="https://app.powerbi.com/reportEmbed?reportId=f513f6b8-1287-41fe-b342-b443b60edd27&appId=d4a1e6fd-538b-4c27-8dfd-997b98c410c9&autoAuth=true&ctid=db1e96a8-a3da-442a-930b-235cac24cd5c"
          allowFullScreen={true}
        ></iframe>
      }
      hasSearchBar
    />
  );
};

export default DataMapPage;
