import styled from 'styled-components';
import { Button, Text } from '@sede-x/shell-ds-react-framework';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${({ theme }) => theme.background.surface};
  padding: 24px;
`;

export const IconContainer = styled.div`
  margin-top: 56px;
  margin-bottom: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledText = styled(Text)`
  margin-top: 12px;
  white-space: pre-wrap;
  text-align: center;
`;

export const StyledLink = styled.a`
  text-decoration: none;
  color: #0497bb;
  margin-bottom: 24px;
`;

export const StyledButton = styled(Button)`
  width: 247px;
`;
