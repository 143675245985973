export const getPageInfo = (
  page: number,
  maxRowsPerPage: number,
  totalItems: number,
  currentPageItems: number,
  searchText?: string,
) => {
  const firstItem = (page - 1) * maxRowsPerPage + 1;
  const lastItem = (page - 1) * maxRowsPerPage + currentPageItems;
  const resultText = `Showing ${firstItem}-${lastItem} out of ${totalItems}`;
  return searchText ? `${resultText} results for "${searchText}"` : resultText;
};
