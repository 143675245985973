import TypeTranslation from '../../../../../utils/i18n/TypeTranslation';
import {
  PollTimeField,
  RetentionTimeField,
  TopicNameField,
  URIField,
  BannerUpdating,
} from './ConfigurationFieldsEventStreaming';

const ConfigurationFieldsEvent = (t: TypeTranslation, isUpdating: boolean) => [
  ...(isUpdating ? [BannerUpdating(t)] : []),
  URIField(t),
  TopicNameField(t),
  [RetentionTimeField(t), PollTimeField(t)],
];

export default ConfigurationFieldsEvent;
