const ErrorIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={63}
    height={54}
    fill="none"
    data-testid="resultmodal-icon-error"
  >
    <path
      fill="#EDA4A5"
      fillRule="evenodd"
      d="M34.939 2.296c-1.538-2.617-5.34-2.617-6.877 0L1.38 47.702c-1.588 2.702.432 5.964 3.439 5.964h53.363c3.006 0 5.026-3.262 
      3.438-5.964L34.94 2.296ZM7.195 48.333 31.5 6.971l24.305 41.362H7.195ZM30.167 43a1.333 1.333 0 0 1-1.333-1.334V39c0-.737.596-1.334 
      1.333-1.334h2.667c.736 0 1.333.597 1.333 1.334v2.666c0 .737-.597 1.334-1.333 1.334h-2.667Zm2.667-24c.736 0 1.333.597 1.333 
      1.333V31c0 .736-.597 1.333-1.333 1.333h-2.667A1.333 1.333 0 0 1 28.834 31V20.333c0-.736.596-1.333 1.333-1.333h2.667Z"
      clipRule="evenodd"
    />
  </svg>
);
export default ErrorIcon;
