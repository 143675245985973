import { Divider, Heading } from '@sede-x/shell-ds-react-framework';
import React from 'react';
import { TagContainer } from '../../../../../../components/Item/Item.style';
import ConsumeCard from './components/ConsumeCard';
import { OverviewContainer } from './Overview.styles';
import TagComponent from '../../../../../../components/TagComponent/TagComponent';
import MarkdownText from '../../../../../../components/MarkdownText/MarkdownText';
import useParamsHandler from '../../../../../../hooks/useParamsHandler';
import BatchSection from './components/BatchSection/BatchSection';
import dataProductTypes, {
  DataProductVariant,
} from '../../../../../../utils/dataProductTypes';
import MetadataSection from './components/MetadataSection/MetadataSection';

interface Props {
  dpDescription: string;
  dpTags: string[];
  dpTerms: string[];
  dpBatchProps: {
    dataVolume?: number;
    batchSize?: number;
    numberOfPages?: number;
    batchFrequency?: string;
    dataSource?: string;
  };
  dpMetadata: { key: string; value: string }[];
  type: DataProductVariant;
}

const Overview: React.FC<Props> = ({
  dpDescription,
  dpTags,
  dpTerms,
  dpBatchProps,
  dpMetadata,
  type,
}) => {
  const { getHref } = useParamsHandler();
  return (
    <OverviewContainer>
      <Heading level={2} bold gutter>
        Key Features
      </Heading>
      <MarkdownText>{dpDescription}</MarkdownText>
      {type === dataProductTypes.Batch && (
        <BatchSection values={dpBatchProps} />
      )}
      {(dpTags?.length >= 0 || dpTags === null) && (
        <>
          <Heading level={2} bold>
            Tags
          </Heading>
          <TagContainer>
            {dpTerms?.map((term) => (
              <TagComponent
                tag={{ name: term, description: '' }}
                selectable
                type="term"
                key={term}
                href={getHref({ terms: [term] })}
              />
            ))}
            {dpTags?.map((tag) => (
              <TagComponent
                tag={{ name: tag, description: '' }}
                selectable
                type="tag"
                key={tag}
                href={getHref({ tags: [tag] })}
              />
            ))}
          </TagContainer>
        </>
      )}
      <MetadataSection metadata={dpMetadata} />
      <Divider gutter="generous" />
      <ConsumeCard type={type} />
    </OverviewContainer>
  );
};

export default Overview;
