import { Modal, Text } from '@sede-x/shell-ds-react-framework';
import { useTranslation } from 'react-i18next';

const SucessModal = ({
  onConfirm,
  productName,
  isGlass = false,
}: {
  onConfirm: () => void;
  productName?: string;
  isGlass?: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      open
      onClose={onConfirm}
      closable={false}
      title={t('admin_list-delete_modal-success_modal-title')}
      actions={[
        {
          action: onConfirm,
          label: t('admin_list-delete_modal-success_modal-close'),
          props: { variant: 'outlined' },
        },
      ]}
    >
      <Text>
        {t(
          isGlass
            ? 'admin_list-delete_modal_glass-success_modal-body'
            : 'admin_list-delete_modal-success_modal-body',
          { product: productName },
        )}
      </Text>
    </Modal>
  );
};

export default SucessModal;
