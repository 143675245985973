const SmallInfoIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={20} fill="none">
    <path
      fill="#FDB840"
      fillRule="evenodd"
      d="M13.29.736a1.497 1.497 0 0 0-2.58 0L.705 17.763C.109 18.776.867 20 1.995 20h20.01c1.128 0 1.885-1.224 1.29-2.237L13.289.736ZM2.884 
      18 12 2.49 21.114 18H2.885Zm8.615-2a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1Zm1-9a.5.5 0 0 
      1 .5.5v4a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 .5-.5h1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SmallInfoIcon;
