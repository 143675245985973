import styled from 'styled-components';

export const Container = styled.div`
  background: ${({ theme }) => theme.background.surface};
  & .ql-editor {
    max-height: 400px;
    overflow: auto;
  }
  & .ql-picker-options {
    background: ${({ theme }) => theme.background.surface};
    border-color: ${({ theme }) => theme.border.medium} !important;
    color: ${({ theme }) => theme.text.onBase.strong};
  }
  & .ql-picker-label {
    color: ${({ theme }) => theme.text.onBase.strong};
  }
  & .ql-stroke {
    stroke: ${({ theme }) => theme.text.onBase.strong};
  }
  & .ql-fill {
    fill: ${({ theme }) => theme.text.onBase.strong};
  }
  & .ql-toolbar.ql-snow {
    border: 1px solid ${({ theme }) => theme.border.medium};
  }
  & .ql-container.ql-snow {
    border: 1px solid ${({ theme }) => theme.border.medium};
  }
  & .ql-snow .ql-tooltip {
    background: ${({ theme }) => theme.background.surface};
    border: 1px solid ${({ theme }) => theme.border.medium};
    color: ${({ theme }) => theme.text.onBase.strong};
    box-shadow: none;

    & input[type='text'] {
      background: ${({ theme }) => theme.background.surface};
      border: 1px solid ${({ theme }) => theme.border.medium};
      color: ${({ theme }) => theme.text.onBase.strong};
    }

    & a {
      color: ${({ theme }) => theme.text.onBase.strong};
    }
  }
`;
