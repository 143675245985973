import LoadingCard from '../LoadingCard/LoadingCard';
import {
  Line,
  LoadingContainerCards,
  LoadingContainer,
} from './LoadingList.styles';

const LoadingList = () => {
  const mockedColumns = Array.from({ length: 5 }, (_, i) => i);

  return (
    <LoadingContainer data-testid="Loading-list">
      {mockedColumns.map((row) => (
        <LoadingContainerCards key={row}>
          <LoadingCard />
          <Line width="94%" margin="10px 0 0 6%" />
        </LoadingContainerCards>
      ))}
    </LoadingContainer>
  );
};
export default LoadingList;
