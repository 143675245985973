import { CenteredContainer } from '../../../../globalStyles';
import { HeaderContainer } from '../Header/DetailHeader.styles';
import { StyledHeaderWrapper } from './LoadingItemDetail.styles';
import LoadingLeftHeader from './components/LoadingHeader/LoadingLeftHeader';
import LoadingRightHeader from './components/LoadingHeader/LoadingRightHeader';
import LoadingCardDetail from './components/LoadingCardDetail/LoadingCardDetail';

const LoadingItemDetail = () => {
  return (
    <CenteredContainer>
      <HeaderContainer>
        <StyledHeaderWrapper>
          <LoadingLeftHeader />
          <LoadingRightHeader />
        </StyledHeaderWrapper>
      </HeaderContainer>
      <LoadingCardDetail />
    </CenteredContainer>
  );
};

export default LoadingItemDetail;
