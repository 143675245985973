import { Button, ButtonLink, Loading } from '@sede-x/shell-ds-react-framework';
import React from 'react';
import { Container, RightContainer } from './CheckBoxModalFooter.styles';

interface Props {
  onDownload: () => void;
  onCancel: () => void;
  onSelectAll: () => void;
  onDismissAll: () => void;
  totalItems: number;
  isDisabled?: boolean;
  isLoading?: boolean;
}

const CheckBoxModalFooter: React.FC<Props> = ({
  onDownload,
  onCancel,
  onSelectAll,
  onDismissAll,
  totalItems,
  isDisabled = false,
  isLoading = false,
}) => {
  return (
    <Container>
      <ButtonLink
        onClick={totalItems > 0 ? onDismissAll : onSelectAll}
        disabled={isLoading}
      >
        {totalItems > 0 ? 'Dismiss all' : 'Select all'}
      </ButtonLink>
      <RightContainer>
        <Button onClick={onCancel} variant="outlined" disabled={isLoading}>
          Cancel
        </Button>
        <Button
          iconPosition="right"
          disabled={isDisabled}
          icon={
            isLoading && (
              <Loading data-testid="download-button-loading" type="primary" />
            )
          }
          onClick={onDownload}
        >
          {totalItems > 0 ? `Download (${totalItems})` : 'Download'}
        </Button>
      </RightContainer>
    </Container>
  );
};

export default CheckBoxModalFooter;
