import { ContentLoadingElement, LoadingElement } from './LoadingItem.styles';

export const LoadingItem = ({
  type,
  width,
  margin,
  height,
}: {
  type: string;
  width: string;
  margin: string;
  height: string;
}) => {
  return (
    <ContentLoadingElement>
      <LoadingElement
        data-testid="Loading-item"
        type={type}
        width={width}
        margin={margin}
        height={height}
      />
    </ContentLoadingElement>
  );
};
