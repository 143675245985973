import { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import useAuth from '../hooks/useAuthentication';
import { AuthDone, AuthLoading } from '../pages/AuthPages';
import DetailPage from '../pages/DetailPage/DetailPage';
import { setToken } from '../services/DPAxios';
import { setMastermindToken } from '../services/GlassAxios';
import InsightsPage from '../pages/InsightsPage/InsightsPage';
import MySubscriptionPage from '../pages/MySubscriptionPage/MySubscriptionPage';
import parseJWT from '../utils/parseJWT';
import { useDispatch } from 'react-redux';
import AdminListPage from '../pages/AdminPage/AdminListPage/AdminListPage';
import AdminFormCreatePageMain from '../pages/AdminPage/AdminFormCreatePage/pages/AdminFormCreatePageMain';
import AdminFormCreatePageAPI from '../pages/AdminPage/AdminFormCreatePage/pages/AdminFormCreatePageAPI';
import AdminFormCreatePageEvent from '../pages/AdminPage/AdminFormCreatePage/pages/AdminFormCreatePageEvent';
import AdminFormCreatePageResource from '../pages/AdminPage/AdminFormCreatePage/pages/AdminFormCreatePageResource';
import AdminFormCreatePageStreaming from '../pages/AdminPage/AdminFormCreatePage/pages/AdminFormCreatePageStreaming';
import MainPage from '../pages/MainPage/MainPage';
import routes from './routes';
import ConfirmationPage from '../pages/AdminPage/ResponsePages/ConfirmationPage/ConfirmationPage';
import ErrorPage from '../pages/AdminPage/ResponsePages/ErrorPage/ErrorPage';
import AdminFormEditPage from '../pages/AdminPage/AdminFormEditPage/pages/AdminFormEditPage';
import AdminFormEditPageGlass from '../pages/AdminPage/AdminFormEditPage/pages/AdminFormEditPageGlass';
import UpdatingConfirmationPage from '../pages/AdminPage/ResponsePages/UpdatingConfirmationPage/UpdatingConfirmationPage';
import AdminFormCreatePageBatch from '../pages/AdminPage/AdminFormCreatePage/pages/AdminFormCreatePageBatch';
import AdminFormCreatePageGlass from '../pages/AdminPage/AdminFormCreatePage/pages/AdminFormCreatePageGlass';
import DataMapPage from '../pages/DataMap/DataMapPage';

const provider = process.env.REACT_APP_AUTHORITY ?? 'error';
const clientId = process.env.REACT_APP_CLIENT_ID ?? 'error';
const redirect = `${window.location.origin}/authentication/callback/`;

function Router() {
  const auth = useAuth();
  const dispatch = useDispatch();
  const [updatingToken, setUpdatingToken] = useState(true);
  useEffect(() => {
    auth.login({ provider, clientId, redirect });
  }, []);

  useEffect(() => {
    if (auth.accessToken) {
      setUpdatingToken(true);
      const {
        email,
        realm_access: { roles },
      } = parseJWT(auth.accessToken);
      dispatch.user.saveUserData({
        name: email.split('@')[0],
        email,
        roles,
      });
      setToken(auth.accessToken);
      setMastermindToken(auth.accessToken);
      dispatch.dataProducts.resetCache();
      setUpdatingToken(false);
    }
  }, [auth.accessToken]);

  return !updatingToken ? (
    <BrowserRouter>
      <Routes>
        <Route path={routes.home} element={<MainPage />} />
        <Route path={routes.mySubscriptions} element={<MySubscriptionPage />} />
        <Route path={`${routes.details}/:name`} element={<DetailPage />} />
        <Route path="/authentication/callback/" element={<AuthDone />} />
        <Route path="/authentication/loading/" element={<AuthLoading />} />
        <Route path={routes.insights} element={<InsightsPage />} />
        <Route path={routes.datamap} element={<DataMapPage />} />
        <Route path={`${routes.admin}/*`} element={<AdminListPage />} />

        <Route path={`${routes.edit}/:name`} element={<AdminFormEditPage />} />
        <Route
          path={`${routes.editGlassHub}/:id`}
          element={<AdminFormEditPageGlass />}
        />
        <Route
          path={`${routes.create}`}
          element={<AdminFormCreatePageMain />}
        />
        <Route
          path={`${routes.createApi}`}
          element={<AdminFormCreatePageAPI />}
        />
        <Route
          path={`${routes.createStreaming}`}
          element={<AdminFormCreatePageStreaming />}
        />
        <Route
          path={`${routes.createEvent}`}
          element={<AdminFormCreatePageEvent />}
        />
        <Route
          path={`${routes.createResource}`}
          element={<AdminFormCreatePageResource />}
        />
        <Route
          path={`${routes.createBatch}`}
          element={<AdminFormCreatePageBatch />}
        />
        <Route
          path={`${routes.createGlassHub}`}
          element={<AdminFormCreatePageGlass />}
        />
        <Route path={`${routes.success}`} element={<ConfirmationPage />} />
        <Route
          path={`${routes.updateSuccess}`}
          element={<UpdatingConfirmationPage />}
        />
        <Route path={`${routes.error}`} element={<ErrorPage />} />
      </Routes>
    </BrowserRouter>
  ) : (
    <></>
  );
}

export default Router;
