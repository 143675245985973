import { Heading, Icons, Text } from '@sede-x/shell-ds-react-framework';
import {
  ConsumeButton,
  ConsumeCardContainer,
  ConsumeCardLeft,
  ConsumeCardRight,
} from './ConsumeCard.styles';
import downloadCard from '../../../../../../../assets/images/download-card.svg';
import { DataProductVariant } from '../../../../../../../utils/dataProductTypes';
import { useTranslation } from 'react-i18next';

interface Props {
  type: DataProductVariant;
}

const ConsumeCard = ({ type }: Props) => {
  const { t } = useTranslation();
  const article = type?.match('^[AIEOU].*') ? 'an' : 'a';
  const dataProductType = type !== 'API' ? ((type || '').toLowerCase() as DataProductVariant) : type;

  const documentationURL = t('consume_card-consume_button_link', {
    type: dataProductType,
    article,
  });

  return (
    <ConsumeCardContainer>
      <ConsumeCardLeft>
        <Heading level={2} bold gutter>
          {t('consume_card-heading')}
        </Heading>
        <Text gutter>{t('consume_card-text')}</Text>
        <a href={documentationURL} target="_blank" rel="noreferrer">
          <ConsumeButton
            icon={<Icons.Open />}
            iconPosition="right"
            size="medium"
            variant="outlined"
          >
            {t('consume_card-consume_button_text', { article, type })}
          </ConsumeButton>
        </a>
      </ConsumeCardLeft>
      <ConsumeCardRight>
        <img src={downloadCard} alt="download explanation card" />
      </ConsumeCardRight>
    </ConsumeCardContainer>
  );
};

export default ConsumeCard;
