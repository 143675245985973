import React from 'react';
import { DataProductType } from '../../../../state/models/dataProductsModel/types';
import LeftHeader from './components/LeftHeader/LeftHeader';
import RightHeader from './components/RightHeader/RightHeader';
import { HeaderContainer, HeaderWrapper } from './DetailHeader.styles';

interface Props {
  dataProduct: DataProductType;
}

const DetailHeader: React.FC<Props> = ({ dataProduct }) => {
  return (
    <HeaderContainer>
      <HeaderWrapper>
        <LeftHeader dataProduct={dataProduct} />
        <RightHeader />
      </HeaderWrapper>
    </HeaderContainer>
  );
};

export default DetailHeader;
