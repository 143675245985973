import React from 'react';
import { ShareContainer } from './ShareBar.style';
import DataProductService from '../../../../../../../services/DataProductService';
import ActionButton from '../../../../../../../components/ActionButton/ActionButton';
import { useTranslation } from 'react-i18next';
import { Icons } from '@sede-x/shell-ds-react-framework';
import { configureShareEmail } from '../../../../../../../utils/emailHelper';

interface Props {
  name: string;
  businessName: string;
  description: string;
}

const ShareBar = ({ name, businessName, description }: Props) => {
  const { t } = useTranslation();

  const getReferral = async (dPname: string) => {
    const referral = await DataProductService.getReferralLink(dPname);
    return referral ? `${window.location.href}?referral=${referral}` : null;
  };

  const handleCopyToClipboard = async () => {
    const url = await getReferral(name);
    if (url !== null) {
      navigator.clipboard.writeText(url);
    }
  };

  const handleEmail = async () => {
    const url = await getReferral(name);
    if (url !== null) {
      window.location.href = configureShareEmail(
        businessName,
        description,
        url,
      );
    }
  };

  return (
    <ShareContainer>
      <ActionButton
        helperText={t('details-share-copy_to_clipboard_text')}
        onClick={handleCopyToClipboard}
        icon={<Icons.Link width={24} height={24} />}
        data-testid="copy-to-clipboard-button"
      />

      <ActionButton
        onClick={handleEmail}
        data-testid="share-via-email-button"
        icon={<Icons.MailClosedHollow width={24} height={24} />}
      />
    </ShareContainer>
  );
};

export default ShareBar;
