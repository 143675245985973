import React from 'react';
import { DataProductType } from '../../../../../../../state/models/dataProductsModel/types';
import DirectDownloadButton from './variants/DirectDownloadButton/DirectDownloadButton';
import MultipleDownloadButton from './variants/MultipleDownloadButton/MultipleDownloadButton';
import RequestAccessButton from './variants/RequestAccessButton/RequestAccessButton';

interface Props {
  dataProduct: DataProductType;
}

const TheButton: React.FC<Props> = ({ dataProduct }) => {
  const currentQueriesNumber = dataProduct?.queries?.length ?? 0;

  const currentQueriesIndex =
    currentQueriesNumber > 1 ? 2 : currentQueriesNumber;

  const buttonVariants = [
    <RequestAccessButton dataProduct={dataProduct} key="requestAccessButton" />,
    <DirectDownloadButton
      dataProduct={dataProduct}
      key="directDownloadButton"
    />,
    <MultipleDownloadButton
      dataProduct={dataProduct}
      key="multipleDownloadButton"
    />,
  ];

  return buttonVariants[currentQueriesIndex];
};

export default TheButton;
