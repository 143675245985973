import { useSearchParams } from 'react-router-dom';
import SearchBar from '../../components/SearchBar/SearchBar';
import { SearchBarContainer } from './SearchHeader.styles';
import useParamsHandler from '../../hooks/useParamsHandler';

const SearchHeader = () => {
  const { navigateWithParams } = useParamsHandler();
  const [searchParams] = useSearchParams();
  const searchKeywords = searchParams.get('search') ?? '';

  const onChangeURL = (keywords: string) => {
    navigateWithParams({ search: keywords });
  };

  return (
    <SearchBarContainer>
      <SearchBar
        onSubmit={onChangeURL}
        placeholder="Search for data"
        defaultValue={searchKeywords}
      />
    </SearchBarContainer>
  );
};

export default SearchHeader;
