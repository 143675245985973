import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const ErrorText = styled.p`
  color: red;
  font-size: 20px;
`;

export const ErrorSpan = styled.span`
  border: 1px solid red;
  border-radius: 5px;
  padding: 5px;
`;
