import EmptyField from '../../../../../components/FormFields/EmptyField/EmptyField';
import TypeTranslation from '../../../../../utils/i18n/TypeTranslation';

const GovernanceFieldsAPI = (t: TypeTranslation) => [
  {
    label: t('admin_create-governance_step-access_type_title'),
    name: 'accessType',
    description: t('admin_create-governance_step-access_type_description'),
    placeholder: t('admin_create-governance_step-access_type_placeholder'),
    required: true,
    type: 'SELECT',
    options: [
      { label: 'Public', value: 'public' },
      { label: 'Private', value: 'private' },
      { label: 'Protected', value: 'protected' },
    ],
  },
  EmptyField,
];

export default GovernanceFieldsAPI;
