import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Container } from './Dropzone.styles';
import { Icons, Label, Text } from '@sede-x/shell-ds-react-framework';
import { bytesToMbText } from '../../utils/sizeConverters';
import { useTranslation } from 'react-i18next';

interface Props {
  onChange: (value: File) => void;
  value?: File;
  allowedFiles?: { [key: string]: string[] };
}

const getAllowedFilesText = (allowedFiles: { [key: string]: string[] }) => {
  return Object.values(allowedFiles).flat().join(' ');
};

const defaultAllowedFiles = {
  'application/json': ['.json'],
};

const Dropzone = ({ onChange, value, allowedFiles }: Props) => {
  const { t } = useTranslation();
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      onChange(acceptedFiles[0]);
    },
    [onChange],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: allowedFiles ?? defaultAllowedFiles,
    multiple: false,
  });

  return (
    <Container {...getRootProps()}>
      <input {...getInputProps()} />
      {value ? (
        <>
          <Icons.CheckCircle width={36} height={36} color="#008557" />
          <Text>{value.name}</Text>
          <Label prominence="subtle">{bytesToMbText(value.size)}</Label>
        </>
      ) : (
        <>
          <Icons.UploadCloud width={36} height={36} />
          <Text>
            {isDragActive
              ? t('dropzone_component-label-dragging')
              : t('dropzone_component-label')}
          </Text>
          <Label prominence="subtle">
            {t('dropzone_component-accepts_text', {
              formats: getAllowedFilesText(allowedFiles ?? defaultAllowedFiles),
            })}
          </Label>
        </>
      )}
    </Container>
  );
};

export default Dropzone;
