import Layout from '../../components/Layout/Layout';
import ItemDetail from './components/ItemDetail/ItemDetail';

const DetailPage = () => {
  return (
    <Layout
      variant="FULLPAGE_CONTENT"
      content={<ItemDetail />}
      hasSearchBar
      padding="0px"
    />
  );
};

export default DetailPage;
