import TypeTranslation from '../../../../../utils/i18n/TypeTranslation';
import BannerField from '../../../../../components/FormFields/BannerField/BannerField';
import DisabledNameField from '../../../../../components/FormFields/DisabledNameField/DisabledNameField';
import { VERSION_FORMAT } from '../../../../../utils/regexpConst';
import { isVersionNewer } from '../../../../../utils/comparingFunctions';
import { useState } from 'react';
import EmptyField from '../../../../../components/FormFields/EmptyField/EmptyField';
import { Sentiments } from '@sede-x/shell-ds-react-framework';

const UpdatingVersionFields = ({
  t,
  currentVersion,
  requireNewVersion,
  type,
}: {
  t: TypeTranslation;
  currentVersion: string;
  requireNewVersion: boolean;
  type: string;
}) => {
  const [isVersionValid, setIsVersionValid] = useState(true);

  const bannerTexts = {
    api: {
      warn: 'admin_update-deployment_step-banner_version_text_api',
      err: 'admin_update-deployment_step-banner_version_error_api',
    },
    event: {
      warn: 'admin_update-deployment_step-banner_version_text_event',
      err: 'admin_update-deployment_step-banner_version_error_event',
    },
    streaming: {
      warn: 'admin_update-deployment_step-banner_version_text_streaming',
      err: 'admin_update-deployment_step-banner_version_error_streaming',
    },
    resource: {
      warn: 'admin_update-deployment_step-banner_version_text_resource',
      err: 'admin_update-deployment_step-banner_version_error_resource',
    },
    batch: {
      warn: 'admin_update-deployment_step-banner_version_text_batch',
      err: 'admin_update-deployment_step-banner_version_error_batch',
    },
  };

  const bannerVersionInvalid = !isVersionValid
    ? [
        {
          label: '',
          name: 'bannerEndpoints',
          description: '',
          type: 'CUSTOM',
          component: BannerField({
            sentiment: Sentiments.Negative,
            text: t(
              bannerTexts[(type as keyof typeof bannerTexts) || 'api'].err,
            ),
          }),
        },
      ]
    : [EmptyField];

  const bannerVersionRequired = [
    {
      label: '',
      name: 'bannerEndpoints',
      description: '',
      type: 'CUSTOM',
      component: BannerField({
        sentiment: Sentiments.Warning,
        text: t(bannerTexts[(type as keyof typeof bannerTexts) || 'api'].warn),
      }),
    },
  ];

  const bannerFields =
    (requireNewVersion || type === 'resource') && isVersionValid
      ? bannerVersionRequired
      : bannerVersionInvalid;

  return [
    ...bannerFields,
    [
      {
        label: t('admin_update-product_details_step-old_version_title'),
        name: 'version',
        description: t(
          'admin_update-product_details_step-old_version_description',
        ),
        type: 'CUSTOM',
        component: DisabledNameField,
      },
      {
        label: t('admin_update-product_details_step-new_version_title'),
        name: 'newVersion',
        description: t(
          'admin_update-product_details_step-new_version_description',
        ),
        placeholder: t(
          'admin_update-product_details_step-new_version_placeholder',
        ),
        required: requireNewVersion,
        customValidators: [
          {
            reason: t(
              'admin_update-product_details_step-version_bottom_text_error',
            ),
            validate: async (value: string) => {
              if (!value) {
                if (requireNewVersion) {
                  setIsVersionValid(false);
                  return false;
                }
                setIsVersionValid(true);
                return true;
              }
              if (
                (value === currentVersion && !requireNewVersion) ||
                isVersionNewer(value, currentVersion)
              ) {
                const isValid = new RegExp(VERSION_FORMAT).test(value);
                setIsVersionValid(isValid);
                return isValid;
              }
              setIsVersionValid(false);
              return false;
            },
          },
        ],
        type: 'TEXT_INPUT',
      },
    ],
  ];
};

export default UpdatingVersionFields;
