import { Text } from '@sede-x/shell-ds-react-framework';
import React from 'react';
import { documentationURL } from '../../../../../../utils/consts';
import ContactCard from './components/ContactCard';
import { CardsWrapper } from './components/ContactCard.styles';
import { ContactsContainer, StyledLink } from './Contacts.styles';
import { Trans, useTranslation } from 'react-i18next';

interface ContactType {
  name: string;
  email: string;
  profileLink: string;
}
interface Props {
  dataProductOwner: ContactType;
  dataProductExpert: ContactType;
}

const Contacts: React.FC<Props> = ({ dataProductOwner, dataProductExpert }) => {
  const { t } = useTranslation();
  return (
    <ContactsContainer>
      <Text gutter>
        <Trans i18nKey="contact_tab-header_description">
          <StyledLink href={documentationURL} target="_blank" />
        </Trans>
      </Text>

      <CardsWrapper>
        {dataProductOwner?.name && (
          <ContactCard
            name={dataProductOwner.name}
            email={dataProductOwner.email}
            profileLink={dataProductOwner.profileLink}
            role={t('contact_tab-product_owner-role')}
            description={t('contact_tab-product_owner-profile_description')}
          />
        )}

        {dataProductExpert?.name && (
          <ContactCard
            name={dataProductExpert.name}
            email={dataProductExpert.email}
            profileLink={dataProductExpert.profileLink}
            role={t('contact_tab-product_expert-role')}
            description={t('contact_tab-product_expert-profile_description')}
          />
        )}
      </CardsWrapper>
    </ContactsContainer>
  );
};

export default Contacts;
