const dataProductTypes = {
  API: 'API',
  Resource: 'Resource',
  Event: 'Event',
  Streaming: 'Streaming',
  Batch: 'Batch',
};

export const productTypes = {
  ...dataProductTypes,
  Glass: 'Glass',
};

export type DataProductVariant = keyof typeof dataProductTypes;

export type ProductVariant = keyof typeof productTypes;

export default dataProductTypes;
