import { Heading, Text } from '@sede-x/shell-ds-react-framework';
import {
  EmptyStateContainer,
  StyledKeywords,
  StyledList,
} from '../EmptyState.styles';

const maxCharacters = 40;

const trimKeywords = (keywords: string) => {
  if (keywords.length > maxCharacters && keywords !== null) {
    return keywords.substring(0, maxCharacters) + '...';
  }
  return keywords;
};

const EmptyStateWithKeywords = ({ keywords }: { keywords: string }) => {
  return (
    <EmptyStateContainer>
      <Heading level={2}>
        No results found for{' '}
        <StyledKeywords>{trimKeywords(keywords)}</StyledKeywords>
      </Heading>

      <Text bold size="medium">
        Suggestions:
      </Text>
      <StyledList>
        <li>Make sure all words are spelled correctly.</li>
        <li>Try different keywords.</li>
        <li>Try more general keywords.</li>
        <li>Try fewer keywords.</li>
      </StyledList>
    </EmptyStateContainer>
  );
};

export default EmptyStateWithKeywords;
