import { RematchDispatch } from '@rematch/core';
import DataProductService from '../../../services/DataProductService';
import { RootModel, RootState } from '../../store';
import { DataProductType, TagType } from '../dataProductsModel/types';

const effects = (dispatch: RematchDispatch<RootModel>) => ({
  async getDataDomains(_?: undefined, state?: RootState) {
    try {
      dispatch.insights.setIsDataDomainsLoading(true);
      const response = await DataProductService.searchDataProducts({
        keywords: '',
        limit: 1000,
        offset: 0,
        sort: 'name',
        filters: {},
      });
      const { totalCount, dataProducts } = response;

      let dataDomainTags = state?.dataProducts.terms ?? [];
      if (!dataDomainTags || dataDomainTags?.length <= 0) {
        const tempDataDomainTags: TagType[] =
          await DataProductService.getDataDomains();
        dataDomainTags = tempDataDomainTags || [];
      }

      const dataDomainTerms = dataDomainTags.map((dDT) => ({
        tag: dDT.name,
        itemNumber: dataProducts.filter((dp: DataProductType) =>
          dp?.terms?.includes(dDT.name),
        ).length,
        description: dDT.description,
      }));
      dispatch.insights.setDataDomainsData({
        dataDomains: dataDomainTerms.filter((dDT) => dDT.itemNumber > 0),
        totalDataProducts: totalCount,
      });

      dispatch.insights.setHasErrorDataDomains(false);
    } catch (e) {
      dispatch.insights.setHasErrorDataDomains(true);
    } finally {
      dispatch.insights.setIsDataDomainsLoading(false);
      dispatch.insights.setLastUpdateDataDomains(new Date().getTime());
    }
  },
  async getRequests({ fromDate }: { fromDate: number }) {
    try {
      dispatch.insights.setIsRequestsLoading(true);
      const { totalCount, dataProducts } = await DataProductService.getRequests(
        fromDate,
      );
      dispatch.insights.setRequestsData({
        requests: dataProducts,
        totalDataProducts: totalCount,
      });
      dispatch.insights.setHasErrorRequests(false);
    } catch (e) {
      dispatch.insights.setHasErrorRequests(true);
    } finally {
      dispatch.insights.setIsRequestsLoading(false);
      dispatch.insights.setLastUpdateRequests(new Date().getTime());
    }
  },
});

export default effects;
