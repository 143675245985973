import { LoadingItem } from '../../../../../../components/LoadingItem/LoadingItem';
import {
  RightContainer,
  StyledAvatar,
} from '../../../Header/components/RightHeader/RightHeader.styles';

const LoadingRightHeader = () => {
  return (
    <RightContainer>
      <StyledAvatar>
        <LoadingItem
          type={'round'}
          width={'120px'}
          margin={'20px 0'}
          height={'120px'}
        />
      </StyledAvatar>
    </RightContainer>
  );
};

export default LoadingRightHeader;
