import React from 'react';
import { StyledLink } from './LinkFormHelper.styles';

interface Props {
  children: string;
  href: string;
}

const LinkFormHelper: React.FC<Props> = ({ children, href }) => {
  return (
    <StyledLink>
      <a href={href} target="_blank">
        {children}
      </a>
    </StyledLink>
  );
};

export default LinkFormHelper;
