import { Button } from '@sede-x/shell-ds-react-framework';
import styled from 'styled-components';

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
`;

export const ButtonStyled = styled(Button)`
  width: auto;
  height: auto;
  min-height: auto;
  svg {
    height: auto;
    width: auto;
  }
`;
