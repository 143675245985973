import styled from 'styled-components';

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1;
  max-width: 1024px;
`;

export const LoadingContainerCards = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  width: 100%;
`;

export const Line = styled.div<{
  width: string;
  margin: string;
}>`
  width: ${(props) => (props.width ? props.width : '100%')};
  background-color: ${({ theme }) => theme.border.subtle};
  height: 1px;
  margin: ${(props) => (props.margin ? props.margin : '10px 0 0 0')};
`;
