import { Link, Text } from '@sede-x/shell-ds-react-framework';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 4px;
`;

export const IconCircle = styled.div`
  background: ${({ theme }) => theme.accent.secondary.strong};
  width: 64px;
  height: 64px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 30px;
  flex: 1;
`;

export const LinkStyled = styled(Link)`
  text-decoration: none;
  display: flex;
  align-items: flex-end;
  & a {
    text-decoration: none;
    border: none;
  }
`;

export const StyledText = styled(Text)``;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
