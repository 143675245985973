import { Tag } from '@sede-x/shell-ds-react-framework';
import styled from 'styled-components';

export const StyledTag = styled(Tag)<{ selectable: boolean }>`
  opacity: 0.5;
  user-select: none;
  &:hover {
    opacity: ${({ selectable }) => (selectable ? '1' : '0.5')};
    cursor: ${({ selectable }) => (selectable ? 'pointer' : 'inherit')};
  }
`;

export const StyledTagTerm = styled(StyledTag)<{
  selectable: boolean;
  hovered: boolean;
  selected: boolean;
}>`
  border: 1px solid ${({ theme }) => theme.accent.primary.strong};
  background-color: ${({ theme, hovered, selected }) =>
    hovered || selected ? theme.accent.primary.strong : undefined};
  color: ${({ theme, hovered, selected }) =>
    hovered || selected ? theme.text.onSystem.strong.warning : undefined};
`;

export const StyledAnchor = styled.a`
  text-decoration: none;
`;
