export const isVersionNewer = (v1: string, v2: string) => {
  const v1Parts = v1.split('.').map((vP) => parseInt(vP, 10));
  const v2Parts = v2.split('.').map((vP) => parseInt(vP, 10));

  const isGreater = v1Parts.map((v1P, index) => v1P - v2Parts[index]);

  if (isGreater[0] !== 0) {
    return isGreater[0] > 0;
  }
  if (isGreater[1] !== 0) {
    return isGreater[1] > 0;
  }
  if (isGreater[2] !== 0) {
    return isGreater[2] > 0;
  }

  return false;
};

export const areAllParamsEqual = (
  obj1: { [key: string]: unknown },
  obj2: { [key: string]: unknown },
  params: string[],
) => {
  return params.every((p) => obj1[p] === obj2[p]);
};
