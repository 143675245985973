import {
  AdminState,
  DataProductAdminListType,
  DataProductAdminType,
} from './types';
const reducers = {
  setOwnDataProducts(
    state: AdminState,
    {
      ownDataProducts,
      totalItems,
    }: { ownDataProducts: DataProductAdminListType[]; totalItems: number },
  ) {
    return {
      ...state,
      ownDataProducts,
      totalItems,
    };
  },

  setIsLoading(state: AdminState, isLoading: boolean) {
    return {
      ...state,
      isLoading,
    };
  },
  setHasError(state: AdminState, hasError: boolean) {
    return {
      ...state,
      hasError,
    };
  },

  setCurrentDataProduct(
    state: AdminState,
    currentDataProduct: DataProductAdminType,
  ) {
    return {
      ...state,
      currentDataProduct,
    };
  },

  setCurrentError(state: AdminState, currentError?: string) {
    return {
      ...state,
      currentError,
    };
  },
};

export default reducers;
