import { Form } from '@sede-x/glass-design-library';
import LinkFormHelper from '../../../../../components/LinkFormHelper/LinkFormHelper';
import RichTextEditor from '../../../../../components/FormFields/RichTextEditor/RichTextEditor';
import {
  NAME_FORMAT_RANGE,
  TAG_FORMAT,
  BUSINESS_NAME_FORMAT,
} from '../../../../../utils/regexpConst';
import { RootState } from '../../../../../state/store';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { DataProductVariant } from '../../../../../utils/dataProductTypes';
import ProductDetailsFieldsAPI from './ProductDetailsFieldsAPI';
import ProductDetailsFieldsResource from './ProductDetailsFieldsResource';
import { useTranslation } from 'react-i18next';
import DisabledNameField from '../../../../../components/FormFields/DisabledNameField/DisabledNameField';
import MetadataField from '../../../../../components/FormFields/MetadataField/MetadataField';
import { TagType } from '../../../../../state/models/dataProductsModel/types';
import TagsField from '../../../../../components/FormFields/TagsField/TagsField';

export const ProductDetailsSectionButtons = (
  t: Function,
): { [key: string]: unknown } => ({
  submitButton: {
    text: t('admin_create-product_details_next_button'),
    variant: 'filled',
  },
  cancelButton: {
    text: t('admin_create-product_details_back_button'),
    variant: 'outlined',
  },
});

export const ProductDetailsSectionHeading = (
  t: Function,
): {
  name: string;
  type: string;
  title: string;
  subtitle: string;
} => ({
  name: 'productDetailsTitle',
  subtitle: t('admin_create-product_details_step-title_description'),
  title: t('admin_create-product_details_step-title'),
  type: 'HEADING',
});

const ProductDetailsSection = ({
  onSubmit,
  onCancel,
  defaultValues = {},
  type,
  nameEditable = true,
  showName = false,
  isUpdating = false,
}: {
  onSubmit: (values: { [key: string]: unknown }) => void;
  onCancel: () => void;
  defaultValues?: { [key: string]: unknown };
  type: DataProductVariant;
  nameEditable?: boolean;
  showName?: boolean;
  isUpdating?: boolean;
}) => {
  const { t } = useTranslation();
  const terms = useSelector((state: RootState) => state.dataProducts.terms);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch.dataProducts.getDomains();
    dispatch.dataProducts.getTags();
  }, []);

  const extraFields = {
    API: ProductDetailsFieldsAPI(t, isUpdating),
    Event: ProductDetailsFieldsAPI(t, isUpdating),
    Streaming: ProductDetailsFieldsAPI(t, isUpdating),
    Resource: ProductDetailsFieldsResource(t),
    Batch: ProductDetailsFieldsAPI(t, isUpdating),
  };

  return (
    <Form
      onCancel={onCancel}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      {...ProductDetailsSectionButtons(t)}
      formConfig={[
        { ...ProductDetailsSectionHeading(t) },
        [
          ...(showName
            ? [
                nameEditable
                  ? {
                      label: t('admin_create-product_details_step-name_title'),
                      name: 'name',
                      description: t(
                        'admin_create-product_details_step-name_description',
                      ),
                      placeholder: t(
                        'admin_create-product_details_step-name_placeholder',
                      ),
                      required: true,
                      type: 'TEXT_INPUT',
                      validator: new RegExp(NAME_FORMAT_RANGE(3, 8)),
                    }
                  : {
                      label: t('admin_create-product_details_step-name_title'),
                      name: 'name',
                      description: t(
                        'admin_create-product_details_step-name_description',
                      ),
                      type: 'CUSTOM',
                      component: DisabledNameField,
                    },
              ]
            : []),
          {
            label: t('admin_create-product_details_step-business_name_title'),
            name: 'businessName',
            description: t(
              'admin_create-product_details_step-business_name_description',
            ),
            placeholder: t(
              'admin_create-product_details_step-business_name_placeholder',
            ),
            required: true,
            type: 'TEXT_INPUT',
            validator: new RegExp(BUSINESS_NAME_FORMAT(3, 64)),
          },
        ],
        {
          label: t('admin_create-product_details_step-description_title'),
          name: 'description',
          description: t(
            'admin_create-product_details_step-description_description',
          ),
          placeholder: t(
            'admin_create-product_details_step-description_placeholder',
          ),
          type: 'CUSTOM',
          required: true,
          component: RichTextEditor,
        },
        [
          {
            label: t('admin_create-product_details_step-data_domain_title'),
            name: 'terms',
            description: t(
              'admin_create-product_details_step-data_domain_description',
            ),
            topHelper: (
              <LinkFormHelper
                href={t(
                  'admin_create-product_details_step-data_domain_helper_link',
                )}
              >
                {t('admin_create-product_details_step-data_domain_helper_text')}
              </LinkFormHelper>
            ),
            options: terms.map((term: TagType) => ({
              label: term.name,
              value: term.name,
            })),
            mode: 'single',
            placeholder: t(
              'admin_create-product_details_step-data_domain_placeholder',
            ),
            type: 'SELECT',
            required: true,
          },
          {
            className: 'tagsUppercase',
            label: t('admin_create-product_details_step-tags_title'),
            name: 'tags',
            topHelper: (
              <LinkFormHelper
                href={t('admin_create-product_details_step-tags_helper_link')}
              >
                {t('admin_create-product_details_step-tags_helper_text')}
              </LinkFormHelper>
            ),
            description: t(
              'admin_create-product_details_step-tags_description',
            ),
            customValidators: [
              {
                reason: t('error_invalid_tag'),
                validate: async (value: string[]) =>
                  !value || value.every((tag) => TAG_FORMAT.test(tag)),
              },
            ],
            component: TagsField,
            type: 'CUSTOM',
          },
        ],
        ...extraFields[type],
        {
          label: t('admin_create-product_details_step-metadata_title'),
          name: 'customMetadata',
          description: t(
            'admin_create-product_details_step-metadata_description',
          ),
          validator: /^.+$/,
          type: 'CUSTOM',
          component: MetadataField,
        },
      ]}
    />
  );
};

export default ProductDetailsSection;
