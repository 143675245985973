import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_PRODUCTS_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    // Careful with this piece of code with creation flow
    const originalConfig = error.config;
    if (
      !originalConfig._retry &&
      !originalConfig.url.includes('dx-data-product-management-api')
    ) {
      originalConfig._retry = true;
      return instance(originalConfig);
    }
    return Promise.reject(error);
  },
);

export const setToken = (token: string) => {
  instance.defaults.headers['Authorization'] = `Bearer ${token}`;
};

export default instance;
