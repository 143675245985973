import { Divider, Modal } from '@sede-x/shell-ds-react-framework';
import React, { useState } from 'react';
import { Container } from './CheckBoxModal.styles';
import CheckBoxModalFooter from './CheckBoxModalFooter/CheckBoxModalFooter';
import CheckBoxModalItem from './CheckBoxModalItem/CheckBoxModalItem';

export interface CheckboxItem {
  title: string;
  id: string;
  description: string;
  active?: boolean;
}

interface Props {
  title: string;
  onClose: () => void;
  items: CheckboxItem[];
  onDownload: (
    queries: number[],
    totalQueriesRequested: number,
    callback: () => void,
  ) => void;
}

const CheckBoxModal: React.FC<Props> = ({
  title,
  onClose,
  onDownload,
  items,
}) => {
  const [currentItems, setCurrentItems] = useState(items);
  const [isDownloading, setIsDownloading] = useState(false);

  const onSelectInput = (id: string) => (active: boolean) => {
    setCurrentItems(
      currentItems.map((i) => (i.id === id ? { ...i, active } : i)),
    );
  };

  const updateStatus = (selecting: boolean) => () => {
    setCurrentItems(currentItems.map((i) => ({ ...i, active: selecting })));
  };

  const downloadFiles = () => {
    setIsDownloading(true);
    const selectedQueries = currentItems
      .map((cI, index) => {
        if (cI.active) {
          return index;
        }
        return -1;
      })
      .filter((sQ) => sQ >= 0);
    onDownload(
      selectedQueries,
      currentItems.filter((cI) => cI.active).length,
      () => {
        onClose();
        setIsDownloading(false);
      },
    );
  };

  const totalItems = currentItems.filter((i) => i.active).length;

  return (
    <Modal
      onClose={onClose}
      open
      title={title}
      bodyPadding={false}
      footer={
        <CheckBoxModalFooter
          onDownload={downloadFiles}
          onCancel={onClose}
          onSelectAll={updateStatus(true)}
          onDismissAll={updateStatus(false)}
          isDisabled={isDownloading || totalItems <= 0}
          totalItems={totalItems}
          isLoading={isDownloading}
        />
      }
      width="40%"
    >
      <Container>
        {currentItems.map((i, index) => (
          <React.Fragment key={i.title}>
            {index > 0 && <Divider />}
            <CheckBoxModalItem
              title={i.title}
              description={i.description}
              active={!!i.active}
              id={i.id}
              onChange={onSelectInput(i.id)}
              isDisabled={isDownloading}
            />
          </React.Fragment>
        ))}
      </Container>
    </Modal>
  );
};

export default CheckBoxModal;
