import { useState, useEffect } from 'react';
import { TabsStyled } from './TabsPanel.style';

export interface ITab<T> {
  name: string;
  id: T;
}
export interface IDetailTabs<T> {
  tabs: ITab<T>[];
  onTabClick: (value: T) => void;
  defaultTab: T;
}

export default function TabsPanel<T>({
  tabs,
  onTabClick,
  defaultTab,
}: IDetailTabs<T>) {
  const [selected, setSelected] = useState(defaultTab);

  useEffect(() => {
    setSelected(defaultTab);
  }, [defaultTab]);

  return (
    <TabsStyled
      tabBarStyle={{ justifyContent: 'flex-start' }}
      emphasis
      onTabClick={(activeKey) => {
        setSelected(activeKey as T);
        onTabClick?.(activeKey as T);
      }}
      activeKey={selected as string}
      items={tabs?.map((t) => ({
        children: <div key={t.id as string} />,
        key: t.id as string,
        label: t.name,
      }))}
      size="large"
      tabPosition="top"
    />
  );
}
