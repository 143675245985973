import { useTranslation } from 'react-i18next';
import { Modal } from '@sede-x/shell-ds-react-framework';

const RequestAccessSuccessModal = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation();
  return (
    <Modal
      open
      onClose={onClose}
      title={t('request_access_modal-success_modal_header')}
      actions={[
        {
          label: t('modal_generic-close'),
          action: onClose,
          props: {
            variant: 'outlined',
          },
        },
      ]}
    >
      {t('request_access_modal-success_modal_body')}
    </Modal>
  );
};

export default RequestAccessSuccessModal;
