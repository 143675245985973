export const configureEmail = (
  id: string,
  email: string,
  name: string,
  dataOwner: string,
  version: string,
) => {
  return encodeURI(
    `mailto:${email}?subject=Request for access to ${name}&body=${dataOwner},\n\nI request access to the following data product:\n\n Name: ${name}\nVersion: ${version}\nID: ${id}\n\nMy details:\n\nName: [write here your name]\n Email: [write here your email]\n\n Business Justification:\n[write here the business justification for this request]\n\n\nThank you`,
  );
};

export const configureShareEmail = (
  businessName: string,
  description: string,
  url: string,
) => {
  return encodeURI(
    `mailto:?subject=DataXplorer - ${businessName}&body=I want to share this data product with you. I found it on DataXplorer.\n\nProduct: ${businessName} - ${url}\n\nDescription: ${description}\n\n [your name]`,
  );
};

export const configureEmailForFolderChange = (
  t: (key: string) => string,
  title: string | undefined,
  id: string | undefined,
  parent: string | null | undefined,
  env: string | undefined,
  folderName: string | null | undefined,
  technicalOwner: string | undefined,
  owner: string | undefined,
) => {
  return encodeURI(
    `${t(
      'admin_success_create-email',
    )}?subject=ST-SEADS_GLASS | Glass Hub | ${title}  (${id}) folder change request&body=I would like to move  my Glass hub product to :\n\n "INSERT NAME OF TARGET FOLDER HERE"\n\n\nDetails of my Glass Hub Product:\n\nEnvironment: ${env}\nProduct name: ${title}\nProduct:id: ${id}\n\n\nCurrent folder: ${folderName}\nCurrent folder id: ${parent}\n\n\nProduct Owner: ${owner}\nTechnical Owner: ${technicalOwner}\n`,
  );
};
