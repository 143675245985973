import { Modal } from '@sede-x/shell-ds-react-framework';
import { useTranslation } from 'react-i18next';

interface Props {
  onCloseDialog: () => void;
  onRequestAccess: () => void;
}

const AccessRequiredModal = ({ onCloseDialog, onRequestAccess }: Props) => {
  const { t } = useTranslation();

  return (
    <Modal
      open
      onClose={onCloseDialog}
      title={t('access_required_modal-header')}
      actions={[
        {
          action: onRequestAccess,
          label: t('access_required_modal-request_access_button'),
        },
        {
          label: t('modal_generic-cancel'),
          action: onCloseDialog,
          props: {
            variant: 'outlined',
          },
        },
      ]}
    >
      {t('access_required_modal-body')}
    </Modal>
  );
};

export default AccessRequiredModal;
