import styled from 'styled-components';

export const HeaderContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: ${(props) => props.theme.background.surface};
  width: 100%;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 40px 0 0 0;
  flex: 1;
  max-width: 1024px;
`;
