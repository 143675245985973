import { Container, StyledLoaderContainer } from './TagsChart.styles';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highchartsAccessibility from 'highcharts/modules/accessibility';
import { GlassChartTheme } from '@sede-x/glass-design-library';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../state/store';
import { useNavigate } from 'react-router-dom';
import { Heading, Loading } from '@sede-x/shell-ds-react-framework';
import { ChartHeadingStyled } from '../InsightsPageComponent/InsightsPageComponent.styles';
import {
  getLastUpdateText,
  getMinutesDifference,
} from '../../../../utils/dateHelper';
import genericChartStyles from '../utils/genericChartStyles';

highchartsAccessibility(Highcharts);

type CustomOptions = (
  data: { name: string; y: number }[],
  navigate: (url: string) => void,
  getDescription: (name: string) => string,
) => Highcharts.Options;

const options: CustomOptions = (
  data: { name: string; y: number }[],
  navigate: (url: string) => void,
  getDescription: (name: string) => string,
) => ({
  colors: [
    '#81CCD9',
    '#54B8C8',
    '#0097A9',
    '#D3B8D7',
    '#C39EC9',
    '#AF81B7',
    '#ACC3DE',
    '#8FADD1',
    '#6E94C0',
  ],

  chart: {
    type: 'pie',
    height: '400px',
  },
  series: [
    {
      type: 'pie',
      name: 'Data Products',
      colorByPoint: true,
      events: {
        click: function (
          this: Highcharts.Series,
          event: Highcharts.PointClickEventObject,
        ): void {
          const pointName = event.point.name as string;
          navigate(`/?terms=${pointName}`);
        } as Highcharts.SeriesClickCallbackFunction,
      },
      cursor: 'pointer',
      tooltip: {
        useHTML: true,
        pointFormatter() {
          return `<b>Data Products: ${this.y}</b><br>${getDescription(
            this.name as string,
          )}`;
        },
      },
      data,
    },
  ],
  ...genericChartStyles,
});

const TagsChart = () => {
  const navigate = useNavigate();
  const { items, isLoading, lastUpdate } = useSelector(
    (state: RootState) => state.insights.dataDomains,
  );

  const dataDomainsParsed = items?.map((dD) => ({
    name: dD.tag,
    y: dD.itemNumber,
  }));

  const getDescription = (name: string) => {
    const description = items.find((i) => i.tag === name)?.description ?? '';
    const values = description.replace(/.{40}\S*\s+/g, '$&@').split(/\s+@/);
    return values.reduce((acc, val) => {
      return `${acc}<span>${val}</span><br>`;
    }, '');
  };

  return (
    <>
      <ChartHeadingStyled level={3} bold>
        Products Number per Data Domain
      </ChartHeadingStyled>
      <Heading level={4} color="light">
        {!isLoading
          ? getLastUpdateText(getMinutesDifference(lastUpdate))
          : 'Loading...'}
      </Heading>
      <Container>
        {!isLoading && (
          <HighchartsReact
            highcharts={Highcharts}
            options={GlassChartTheme({
              options: options(
                dataDomainsParsed.length > 0 ? dataDomainsParsed : [],
                navigate,
                getDescription,
              ),
            })}
          />
        )}
        {isLoading && (
          <StyledLoaderContainer>
            <Loading size="large" />
          </StyledLoaderContainer>
        )}
      </Container>
    </>
  );
};

export default TagsChart;
