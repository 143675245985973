import { EmptyState } from '@sede-x/glass-design-library';

export const ErrorState = () => {
  const action = {
    text: 'Refresh',
    onClick: () => {
      window.location.reload();
    },
  };
  return (
    <EmptyState
      image
      title={'Something went wrong'}
      message={'An issue occurred. Please try again'}
      action={action}
    />
  );
};
