import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import resourcesToBackend from 'i18next-resources-to-backend';

i18n
  .use(initReactI18next)
  .use(
    resourcesToBackend(
      (language: string, namespace: string) =>
        import(`../../locale/${language}/${namespace}.json`),
    ),
  )
  .init(
    {
      lng: 'en',
      fallbackLng: 'en',
      interpolation: {
        escapeValue: false,
      },
    },
    (err) => {
      if (err) {
        console.log('something went wrong loading', err);
      }
    },
  );

export default i18n;
