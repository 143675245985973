import { URL_FORMAT } from '../../../../../utils/regexpConst';
import TypeTranslation from '../../../../../utils/i18n/TypeTranslation';

const ProductDetailsFieldsResource = (t: TypeTranslation) => [
  {
    name: 'productDetailsURL',
    subtitle: t('admin_create-product_details_step-heading_url_description'),
    title: t('admin_create-product_details_step-heading_url_title'),
    type: 'HEADING',
  },
  [
    {
      label: t('admin_create-product_details_step-url_title'),
      name: 'url',
      description: t('admin_create-product_details_step-url_description'),
      placeholder: t('admin_create-product_details_step-url_placeholder'),
      required: true,
      type: 'TEXT_INPUT',
      validator: new RegExp(URL_FORMAT),
    },
  ],
];

export default ProductDetailsFieldsResource;
