import Layout from '../../components/Layout/Layout';
import MySubscriptionPageComponent from './components/MySubscriptionPageComponent/MySubscriptionPageComponent';

const MySubscriptionPage = () => {
  return (
    <Layout
      variant="CENTERED_COLUMN"
      content={<MySubscriptionPageComponent />}
      hasSearchBar
    />
  );
};

export default MySubscriptionPage;
