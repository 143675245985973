import {
  LoadingCardContainer,
  RightLoadingCardContainer,
} from '../../../../../../components/LoadingCard/LoadingCard.styles';
import { LoadingItem } from '../../../../../../components/LoadingItem/LoadingItem';
import { LoadingCards, LoadingContainer } from './LoadingCardDetail.styles';

const LoadingCardDetail = () => {
  const props = [
    { margin: '30px 45% 5px 0px', key: '1' },
    { margin: '5px 0', key: '2' },
    { margin: '5px 0', key: '3' },
    { margin: '5px 0', key: '4' },
    { margin: '13px 5% 0 0', key: '5' },
    { margin: '14px 80% 20px 0', key: '6' },
  ];
  return (
    <LoadingContainer>
      <LoadingCards>
        <LoadingCardContainer>
          <RightLoadingCardContainer>
            {props.map((item) => (
              <LoadingItem
                type={'normal'}
                width={'100%'}
                margin={item.margin}
                key={`loading${item.key}`}
                height="10px"
              />
            ))}
          </RightLoadingCardContainer>
        </LoadingCardContainer>
      </LoadingCards>
    </LoadingContainer>
  );
};
export default LoadingCardDetail;
