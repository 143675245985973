import { Option } from '@sede-x/shell-ds-react-framework';
import { StyledSelect } from './PoliciesField.styles';
import POLICIES from '../../../utils/policiesConsts';
interface Props {
  onChange: (value: string[]) => void;
  value?: string[];
}

const PoliciesField = ({ onChange, value }: Props) => {
  const Policies = Object.values(POLICIES).map((p) => ({
    label: p,
    value: p,
  }));

  const handleChange = (e: string[]) => {
    onChange(e.map((v) => v.toLowerCase()));
  };

  return (
    <StyledSelect onChange={handleChange} value={value} mode="multiple">
      {Policies.map((policy) => (
        <Option key={policy.value} value={policy.value}>
          {policy.value}
        </Option>
      ))}
    </StyledSelect>
  );
};

export default PoliciesField;
