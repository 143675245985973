import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const AppContainer = styled.div`
  height: 100vh;
  width: 100vw;
  [data-testid='globalheader-leftArea'] {
    margin-right: 36px;
  }
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.background.base};
`;

export const CenteredContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;
