import { Select } from '@sede-x/shell-ds-react-framework';
import styled from 'styled-components';

export const StyledSelect = styled(Select)`
  .shell-select-selection-item-content,
  .shell-select-selection-search input,
  .shell-select-item {
    text-transform: uppercase;
  }
`;
