import { Link } from '@sede-x/shell-ds-react-framework';
import styled from 'styled-components';

export const StyledLink = styled(Link)<{ underlined: boolean; bold: boolean }>`
  text-decoration: none;
  display: flex;
  align-items: flex-end;
  & a {
    text-decoration: none;
    border: none;
    & span {
      text-decoration: ${({ underlined }) =>
        underlined ? 'underline' : 'none'};
      font-weight: ${({ bold }) => (bold ? 600 : 400)};
    }
  }
`;
