import Layout from '../../../../components/Layout/Layout';
import UpdatingConfirmationPageContent from './UpdatingConfirmationPageContent/UpdatingConfirmationPageContent';

const UpdatingConfirmationPage = () => {
  return (
    <Layout
      variant="CENTERED_CONTENT"
      content={<UpdatingConfirmationPageContent />}
    />
  );
};

export default UpdatingConfirmationPage;
