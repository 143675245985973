import React from 'react';
import { DataProductType } from '../../../../../../../state/models/dataProductsModel/types';
import { useDispatch } from 'react-redux';
import { Icons, Label } from '@sede-x/shell-ds-react-framework';
import { LikeContainer } from './LikeButton.style';
import { useTranslation } from 'react-i18next';
interface Props {
  dataProduct: DataProductType;
}

const LikeButton: React.FC<Props> = ({ dataProduct }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleLike = () => {
    dispatch.dataProducts.saveLike({
      liked: !dataProduct.userHasLiked,
      name: dataProduct.name,
      version: dataProduct.version,
    });
  };

  const likedText =
    dataProduct.likes === 1
      ? t('details-likes-single_like_text')
      : t('details-likes-multi_like_text', {
          likes: dataProduct.likes?.toLocaleString('en-US'),
        });

  return (
    <LikeContainer onClick={handleLike}>
      {dataProduct.userHasLiked ? (
        <Icons.HeartFilled width={24} height={24} data-testid="unlike-button" />
      ) : (
        <Icons.HeartHollow width={24} height={24} data-testid="like-button" />
      )}
      <Label>{likedText}</Label>
    </LikeContainer>
  );
};

export default LikeButton;
