import { Text } from '@sede-x/shell-ds-react-framework';
import {
  Container,
  HeadingStyled,
  TextContainer,
} from './MetadataSection.styles';

type Props = {
  metadata: { key: string; value: string }[];
};

const MetadataSection = ({ metadata = [] }: Props) => {
  return (
    <Container>
      {metadata.map((md) => (
        <TextContainer key={md.key}>
          <HeadingStyled level={1} bold>
            {md.key}
          </HeadingStyled>
          <Text>{md.value}</Text>
        </TextContainer>
      ))}
    </Container>
  );
};

export default MetadataSection;
