import { Heading } from '@sede-x/shell-ds-react-framework';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px 0 24px 0;
`;

export const HeadingStyled = styled(Heading)`
  font-size: 24px;
  margin-bottom: 8px;
`;
