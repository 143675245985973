import { ProductVariant } from '../../../../../utils/dataProductTypes';
import { Header } from './StepperHeader.styles';
import { Stepper } from '@sede-x/shell-ds-react-framework';
import { useTranslation } from 'react-i18next';

interface StepperHeaderProps {
  currentStep: number;
  onChangeStep: (n: number) => void;
  type: ProductVariant;
}

const StepperHeader = ({
  currentStep,
  onChangeStep,
  type,
}: StepperHeaderProps) => {
  const { t } = useTranslation();
  const commonPropsBuilder = (currentPage: number) => ({
    key: String(currentPage),
    onClick:
      currentStep > currentPage ? () => onChangeStep(currentPage) : undefined,
    order: currentPage + 1,
  });

  const commonLastPropsBuilder = (currentPage: number) => ({
    last: true,
    order: currentPage + 1,
    width: 130,
  });

  const productDetailsTitle = t('admin_create-stepper-product_details');
  const configurationTitle = t('admin_create-stepper-configuration');
  const governanceTitle = t('admin_create-stepper-governance');
  const deploymentTitle = t('admin_create-stepper-deployment');
  const propertiesTitle = t('admin_create-stepper-properties');

  const steps = {
    API: [
      <Stepper.Step {...commonPropsBuilder(0)} title={productDetailsTitle} />,
      <Stepper.Step {...commonPropsBuilder(1)} title={configurationTitle} />,
      <Stepper.Step {...commonPropsBuilder(2)} title={governanceTitle} />,
      <Stepper.Step {...commonLastPropsBuilder(3)} title={deploymentTitle} />,
    ],
    Event: [
      <Stepper.Step {...commonPropsBuilder(0)} title={productDetailsTitle} />,
      <Stepper.Step {...commonPropsBuilder(1)} title={configurationTitle} />,
      <Stepper.Step {...commonLastPropsBuilder(2)} title={governanceTitle} />,
    ],
    Streaming: [
      <Stepper.Step {...commonPropsBuilder(0)} title={productDetailsTitle} />,
      <Stepper.Step {...commonPropsBuilder(1)} title={configurationTitle} />,
      <Stepper.Step {...commonLastPropsBuilder(2)} title={governanceTitle} />,
    ],
    Resource: [
      <Stepper.Step {...commonPropsBuilder(0)} title={productDetailsTitle} />,
      <Stepper.Step {...commonLastPropsBuilder(1)} title={governanceTitle} />,
    ],
    Batch: [
      <Stepper.Step {...commonPropsBuilder(0)} title={productDetailsTitle} />,
      <Stepper.Step {...commonPropsBuilder(1)} title={propertiesTitle} />,
      <Stepper.Step {...commonLastPropsBuilder(2)} title={governanceTitle} />,
    ],
    Glass: [
      <Stepper.Step {...commonPropsBuilder(0)} title={productDetailsTitle} />,
      <Stepper.Step {...commonLastPropsBuilder(1)} title={governanceTitle} />,
    ],
  };
  return (
    <Header>
      <Stepper
        alignment="right"
        current={currentStep}
        size="small"
        mode="horizontal"
        type="navigation"
      >
        {steps[type]}
      </Stepper>
    </Header>
  );
};

export default StepperHeader;
