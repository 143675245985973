import styled from 'styled-components';
import BackgroundImage from './BannerImage.svg';

export const BannerContainer = styled.div`
  background-image: url(${BackgroundImage});
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
  background-color: #14191f;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
`;

// I know, but SDS doesn't alow us to get light theme in dark theme, so we have to do this
export const InputContainer = styled.div`
  margin-bottom: 30px;
  margin-top: 16px;
  width: 50%;
  display: flex;
  justify-content: center;

  input {
    color: rgba(64, 64, 64, 1);
    background-color: rgba(255, 255, 255, 1);

    &::placeholder {
      color: rgba(117, 117, 117, 1);
    }

    &:disabled {
      background-color: rgba(245, 245, 245, 1);
      color: rgba(217, 217, 217, 1);
      border-color: rgba(0, 0, 0, 0.06);
    }
  }

  form div {
    background: rgba(255, 255, 255, 1);

    &:hover {
      border-color: rgba(0, 151, 187, 1);
    }

    &:focus-within {
      border: 1px solid rgba(0, 151, 187, 1);
      box-shadow: 0 0 0 3px rgba(0, 151, 187, 0.4);
    }
  }

  svg {
    path {
      fill: rgba(64, 64, 64, 1);
    }
  }
`;
