import { BannerContainer, InputContainer } from './MainBanner.styles';
import SearchBar from '../../../../components/SearchBar/SearchBar';
import useParamsHandler from '../../../../hooks/useParamsHandler';
import { Heading } from '@sede-x/shell-ds-react-framework';

const MainBanner = () => {
  const { params, navigateWithParams } = useParamsHandler();

  const handleSubmit = (keywords: string) => {
    navigateWithParams({ search: keywords });
  };

  return (
    <BannerContainer>
      <Heading level={2} bold>
        DataXplorer
      </Heading>
      <InputContainer>
        <SearchBar
          onSubmit={handleSubmit}
          placeholder="Search for data"
          defaultValue={params.search?.[0]}
        />
      </InputContainer>
    </BannerContainer>
  );
};

export default MainBanner;
