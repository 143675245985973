import { Loading } from '@sede-x/shell-ds-react-framework';
import { Container } from './LoadingFullPageComponent.styles';

const LoadingFullPageComponent = () => {
  return (
    <Container>
      <Loading size="large" data-testid="loading-full-page-component" />
    </Container>
  );
};

export default LoadingFullPageComponent;
