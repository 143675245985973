import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 24px;
  width: 100%;
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  user-select: none;
  margin-left: 12px;
`;
