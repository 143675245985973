import { Heading, Icons } from '@sede-x/shell-ds-react-framework';
import React from 'react';
import { StyledMailLink } from '../Contacts.styles';
import {
  CardHeaderText,
  ContactCardContainer,
  ContactCardContent,
  ContactCardHeader,
  ContactHeaderLeft,
  ContactHeaderRight,
} from './ContactCard.styles';

interface Props {
  name: string;
  email: string;
  role: string;
  profileLink: string;
  description: string;
}

const ContactCard: React.FC<Props> = ({
  name,
  email,
  role,
  profileLink,
  description,
}) => {
  const handleProfileClick = () => {
    window.open(`${profileLink}`, '_blank');
  };

  const handleMailClick = () => {
    window.open(`mailto:${email}`, '_blank');
  };

  return (
    <ContactCardContainer>
      <ContactCardHeader onClick={handleProfileClick}>
        <ContactHeaderLeft>
          <Heading bold level={3}>
            {name}
          </Heading>
          <CardHeaderText size="small">{role}</CardHeaderText>
          <CardHeaderText size="small">{description}</CardHeaderText>
        </ContactHeaderLeft>
        <ContactHeaderRight>
          <Icons.ChevronSmallRight width={32} height={32} />
        </ContactHeaderRight>
      </ContactCardHeader>
      <ContactCardContent>
        <StyledMailLink onClick={handleMailClick}>{email}</StyledMailLink>
      </ContactCardContent>
    </ContactCardContainer>
  );
};

export default ContactCard;
