import TypeTranslation from '../../../../../utils/i18n/TypeTranslation';
import EmptyField from '../../../../../components/FormFields/EmptyField/EmptyField';
import {
  BannerUpdating,
  RetentionTimeField,
  TopicNameField,
  URIField,
} from './ConfigurationFieldsEventStreaming';

const ConfigurationFieldsStreaming = (
  t: TypeTranslation,
  isUpdating: boolean,
) => [
  ...(isUpdating ? [BannerUpdating(t)] : []),
  URIField(t),
  TopicNameField(t),
  [RetentionTimeField(t), EmptyField],
];

export default ConfigurationFieldsStreaming;
