import { DataProductType } from '../../../../../../../../state/models/dataProductsModel/types';
import { useTranslation } from 'react-i18next';
import {
  ButtonVariant,
  FormField,
  Modal,
  TextArea,
} from '@sede-x/shell-ds-react-framework';
import { useState } from 'react';
import ReportIssueErrorModal from './ReportIssueErrorModal';
import ReportIssueSuccessModal from './ReportIssueSuccessModal';
import { StyledText } from './ReportIssueModal.styles';
import DataProductService from '../../../../../../../../services/DataProductService';
import { AxiosError } from 'axios';

interface Props {
  dataProduct: DataProductType;
  onCancel: () => void;
}

const maxChar = 500;

const ReportIssueModal = ({ dataProduct, onCancel }: Props) => {
  const { t } = useTranslation();
  const [description, setDescription] = useState<string>('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState(t('error_generic-required'));

  const validate = (text: string) => {
    if (!text) {
      setMessage(t('error_generic-required'));
      return;
    }
    if (text.length > maxChar) {
      setMessage(t('error_generic-char_limit'));
      return;
    }
    setMessage('');
  };

  const onSend = async () => {
    setIsLoading(true);
    try {
      await DataProductService.reportIssue(dataProduct.name, {
        businessName: dataProduct.businessName,
        dpOwnerEmail: dataProduct.owner.email,
        issueDescription: description,
        link: window.location.href,
      });
      setIsSuccess(true);
    } catch (error) {
      if (error instanceof AxiosError) {
        setIsError(true);
      }
    }
  };

  const actions = [
    {
      label: t('modal_generic-cancel'),
      action: () => {
        onCancel();
      },
      props: {
        variant: 'outlined' as ButtonVariant,
        disabled: isLoading,
      },
    },
    {
      label: t('modal_generic-send'),
      action: onSend,
      props: { disabled: isLoading || !!message },
    },
  ];

  if (isSuccess) {
    return <ReportIssueSuccessModal onClose={onCancel} />;
  }
  if (isError) {
    return <ReportIssueErrorModal onClose={onCancel} />;
  }
  return (
    <Modal
      open
      onClose={onCancel}
      closable={false}
      title={t('report_issue_modal-header')}
      actions={actions}
      loading={isLoading}
    >
      <StyledText>{t('report_issue_modal-body')}</StyledText>
      <FormField
        label={t('report_issue_modal-body_description_label')}
        mandatory
        bottomLeftHelper={{
          content: message,
          sentiment: 'negative',
        }}
        bottomRightHelper={{
          content: `${description.length}/${maxChar}`,
          sentiment: 'secondary',
        }}
      >
        <TextArea
          value={description}
          onChange={(e) => {
            setDescription(e.target.value);
            validate(e.target.value);
          }}
          placeholder={t('report_issue_modal-body_description_placeholder')}
          rows={3}
          disabled={isLoading}
          invalid={!!message}
        />
      </FormField>
    </Modal>
  );
};

export default ReportIssueModal;
