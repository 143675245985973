import { Button } from '@sede-x/shell-ds-react-framework';
import styled from 'styled-components';

export const StyledAnchor = styled.a`
  text-decoration: none;
  color: #f8f8f8;
`;

export const ReportIssueButtonStyled = styled(Button)`
  padding: 0.8rem 1.5rem;
`;
