import TypeTranslation from '../../utils/i18n/TypeTranslation';

export const headerConfig = (t: TypeTranslation) => ({
  title: t('navbar_subtitle'),
  subtitle: t('navbar_title'),
});

type LayoutVariants =
  | 'FULLPAGE_CONTENT'
  | 'CENTERED_COLUMN'
  | 'LEFT_SIDEBAR'
  | 'CENTERED_CONTENT';

export interface LayoutProps {
  variant: LayoutVariants;
  content: React.ReactElement;
  rightHeaderContent?: React.ReactElement;
  padding?: string;
  hasSearchBar?: boolean;
  leftSideBar?: React.ReactElement;
}
