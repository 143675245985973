import { createModel } from '@rematch/core';
import reducers from './reducers';
import effects from './effects';
import { RootModel } from '../../store';
import { UserState } from './types';
const UserModel = createModel<RootModel>()({
  state: {
    user: {
      email: '',
      name: '',
      roles: [],
      navigationHistory: {
        type: 'home',
        item: '',
      },
    },
  } as UserState,
  effects,
  reducers,
});
export default UserModel;
