import { Button, Label, Text } from '@sede-x/shell-ds-react-framework';
import styled from 'styled-components';
import LinkWithIcon from '../../../../../../components/LinkWithIcon/LinkWithIcon';

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const StyledCategory = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 1.5rem;
`;

export const CategoryText = styled(Text)`
  display: flex;
  gap: 4px;
  color: ${(props) => props.theme.text.onSurface.subtle};
`;

export const DetailButton = styled(Button)`
  padding: 0.8rem 1.5rem;
`;

export const StyledAnchor = styled.a`
  text-decoration: none;
`;

export const StyledPublicLabel = styled(Label)`
  font-weight: 400;
  margin-bottom: 4px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const StyledButtonsContainer = styled.div`
  padding: 12px 4px 0px 4px;
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

export const LinkSpaced = styled(LinkWithIcon)`
  margin-top: 8px;
`;
