import styled from 'styled-components';

export const LoadingCards = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
  width: 100%;
  margin-top: 40px;
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 15px 0;
  flex: 1;
  max-width: 1024px;
  width: 100%;
`;
