import { Button } from '@sede-x/shell-ds-react-framework';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ButtonStyled = styled(Button)`
  width: 172px;
`;
