import { Divider, Heading } from '@sede-x/shell-ds-react-framework';
import styled from 'styled-components';

export const DomainSubscription = styled(Heading)`
  margin-top: 24px;
`;

export const MySubscriptionTable = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
`;
