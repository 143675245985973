import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ErrorState } from '../../../../components/ErrorState/ErrorState';
import TabsPanel, { ITab } from '../../../../components/TabsPanel/TabsPanel';
import { CenteredContainer } from '../../../../globalStyles';
import { RootState } from '../../../../state/store';
import DetailHeader from '../Header/DetailHeader';
import LoadingItemDetail from '../LoadingItemDetail/LoadingItemDetail';
import Code from './tabs/Code/Code';
import Schema from './tabs/Schema/Schema';
import { DataProductType } from '../../../../state/models/dataProductsModel/types';
import dataProductTypes from '../../../../utils/dataProductTypes';
import Contacts from './tabs/Contacts/Contacts';
import Overview from './tabs/Overview/Overview';
import { TabsContainer } from './tabs/ItemDetail.styles';
import useHandleReferral from '../../../../hooks/useHandleReferral';

export interface TabsContentType {
  overview: React.ReactElement;
  contacts: React.ReactElement;
  code?: React.ReactElement;
  schema?: React.ReactElement;
}

export type TabType = keyof TabsContentType;

const tabs: ITab<TabType>[] = [
  {
    name: 'Overview',
    id: 'overview',
  },
  {
    name: 'Code',
    id: 'code',
  },
  {
    name: 'Schema',
    id: 'schema',
  },
  {
    name: 'Contacts',
    id: 'contacts',
  },
];

const selectTabs = (tabsSelected: string[]) =>
  tabs.filter((t) => tabsSelected.includes(t.id));

const getTabsFromDP = (dataProduct: DataProductType) => {
  const tabsValidators = [
    { id: 'overview', validator: true },
    {
      id: 'code',
      validator:
        dataProduct.type === dataProductTypes.API ||
        dataProduct.type === dataProductTypes.Event ||
        dataProduct.type === dataProductTypes.Streaming,
    },
    {
      id: 'schema',
      validator:
        dataProduct.type === dataProductTypes.API &&
        dataProduct.accessType !== 'protected' &&
        dataProduct.endpoints.filter(
          (f) => f.schema && (f.schema.openapi || f.schema.swagger),
        ).length > 0,
    },
    { id: 'contacts', validator: true },
  ];

  return tabsValidators.filter((tV) => tV.validator).map((tV) => tV.id);
};

const ItemDetail = () => {
  const { name } = useParams();
  const dispatch = useDispatch();
  const defaultTab: TabType = tabs[0].id;
  const [values, setValues] = useState<TabType>(defaultTab);
  const { referral, cleanReferral } = useHandleReferral();

  useEffect(() => {
    dispatch.dataProducts.getCurrentDataProduct({ id: name, referral });
    cleanReferral();
  }, [dispatch.dataProducts, name]);

  const { currentDataProduct, isLoading, hasError } = useSelector(
    (state: RootState) => state.dataProducts,
  );

  useEffect(() => {
    dispatch.user.saveUserData({
      navigationHistory: {
        type: 'details',
        item: name,
      },
    });
  }, [dispatch.user, name]);

  const tabsContent: TabsContentType = {
    overview: (
      <Overview
        dpDescription={currentDataProduct?.description}
        dpTags={currentDataProduct?.tags}
        dpTerms={currentDataProduct?.terms}
        dpBatchProps={{
          dataVolume: currentDataProduct?.dataVolume,
          batchSize: currentDataProduct.batchSize,
          numberOfPages: currentDataProduct.numberOfPages,
          batchFrequency: currentDataProduct.batchFrequency,
          dataSource: currentDataProduct.dataSource,
        }}
        dpMetadata={currentDataProduct?.customMetadata ?? []}
        type={currentDataProduct.type}
      />
    ),
    contacts: (
      <Contacts
        dataProductOwner={currentDataProduct?.owner}
        dataProductExpert={currentDataProduct.expert}
      />
    ),
    code: <Code dataProduct={currentDataProduct} />,
    schema: <Schema dataProduct={currentDataProduct} />,
  };

  const onTabClick = (newValues: TabType) => {
    setValues(newValues);
  };

  if (isLoading) {
    return <LoadingItemDetail />;
  }

  if (hasError) {
    return <ErrorState />;
  }

  return (
    <CenteredContainer>
      <DetailHeader dataProduct={currentDataProduct} />
      <TabsContainer>
        <TabsPanel
          tabs={selectTabs(getTabsFromDP(currentDataProduct))}
          onTabClick={onTabClick}
          defaultTab={defaultTab}
        />
      </TabsContainer>

      {values && tabsContent[values]}
    </CenteredContainer>
  );
};

export default ItemDetail;
