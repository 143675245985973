import Layout from '../../../../components/Layout/Layout';
import AdminFormCreateSection from '../sections/CreationForms/AdminFormCreateSection/AdminFormCreateSection';

const AdminFormCreatePageResource = () => {
  return (
    <Layout
      variant="CENTERED_COLUMN"
      content={<AdminFormCreateSection type="Resource" />}
      padding="0px"
    />
  );
};

export default AdminFormCreatePageResource;
