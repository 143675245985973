import { Text } from '@sede-x/shell-ds-react-framework';
import styled from 'styled-components';

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 5rem;
  max-width: 1024px;
`;

export const ResultsCount = styled.div`
  margin: 20px 0;
  width: 100%;
`;

export const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;
  padding: 0 40px;
`;

export const DefaultResultsCount = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 0;
`;

export const DefaultResultsText = styled(Text)`
  color: ${({ theme }) => theme.background.raised};
`;
