import { EmptyState } from '@sede-x/glass-design-library';
import Layout from '../../components/Layout/IndependentLayout';
import { Icons } from '@sede-x/shell-ds-react-framework';

export const AuthLoading = () => {
  return (
    <Layout
      variant="FULLPAGE_CONTENT"
      content={
        <EmptyState
          icon={<Icons.Lock />}
          title="Authentication Loading"
          message="this can take some seconds..."
        />
      }
      padding="0px"
    />
  );
};

export const AuthError = () => {
  return (
    <Layout
      variant="FULLPAGE_CONTENT"
      content={
        <EmptyState
          image
          title="Authentication failed"
          message="Something went wrong..."
          action={{
            text: 'Try Again',
            onClick: nuke,
          }}
        />
      }
      padding="0px"
    />
  );
};

function nuke() {
  localStorage.clear();
  sessionStorage.clear();
  window.location.href = window.location.origin;
}

export const AuthLost = () => {
  return (
    <Layout
      variant="FULLPAGE_CONTENT"
      content={
        <EmptyState
          image
          title="Session expired"
          action={{
            text: 'Log in',
            onClick: nuke,
          }}
        />
      }
      padding="0px"
    />
  );
};

export const AuthDone = () => {
  return (
    <Layout
      variant="FULLPAGE_CONTENT"
      content={
        <EmptyState icon={<Icons.Check />} title="Authentication Completed" />
      }
      padding="0px"
    />
  );
};
