import { Text } from '@sede-x/shell-ds-react-framework';
import styled from 'styled-components';

export const ContactCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ContactCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${(props) => props.theme.background.surface};
  padding: 1rem 1rem;
  border-radius: 8px 8px 0 0;
  gap: 2rem;
  cursor: pointer;
`;

export const ContactHeaderLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const ContactHeaderRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContactCardContent = styled.div`
  padding: 1.2rem 1rem;
  background-color: ${(props) => props.theme.background.raised};
  border-radius: 0 0 8px 8px;
`;

export const CardHeaderText = styled(Text)`
  color: ${(props) => props.theme.text.onSurface.subtle};
`;

export const CardsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  flex-wrap: wrap;
  margin-top: 2rem;
`;
