import LinkFormHelper from '../../../../../components/LinkFormHelper/LinkFormHelper';
import EndpointsSelector from '../../../../../components/FormFields/EndpointSelector/EndpointsSelector';
import Dropzone from '../../../../../components/Dropzone/Dropzone';
import TypeTranslation from '../../../../../utils/i18n/TypeTranslation';
import EmptyField from '../../../../../components/FormFields/EmptyField/EmptyField';
import BannerField from '../../../../../components/FormFields/BannerField/BannerField';
import { Sentiments } from '@sede-x/shell-ds-react-framework';

const ConfigurationFieldsAPI = (t: TypeTranslation, isUpdating: boolean) => [
  {
    name: 'configurationTitle',
    subtitle: t('admin_create-configuration_step-title_description'),
    title: t('admin_create-configuration_step-title'),
    type: 'HEADING',
    topHelper: (
      <LinkFormHelper
        href={t('admin_create-configuration_step-title_helper_link')}
      >
        {t('admin_create-configuration_step-title_helper_text')}
      </LinkFormHelper>
    ),
  },
  ...(isUpdating
    ? [
        {
          label: '',
          name: 'bannerPolicies',
          description: '',
          type: 'CUSTOM',
          component: BannerField({
            sentiment: Sentiments.Warning,
            text: t('admin_update-deployment_step-banner_policies_text'),
          }),
        },
      ]
    : []),
  {
    label: t('admin_create-configuration_step-endpoints_title'),
    name: 'endpoints',
    description: t('admin_create-configuration_step-endpoints_description'),
    required: true,
    type: 'CUSTOM',
    component: EndpointsSelector,
  },
  {
    ...EmptyField,
    label: t('admin_create-configuration_step-configuration_title'),
    description: t('admin_create-configuration_step-configuration_description'),
  },
  {
    label: t('admin_create-configuration_step-prebuilt_queries_title'),
    name: 'prebuiltQueriesFile',
    description: t(
      'admin_create-configuration_step-prebuilt_queries_description',
    ),
    type: 'CUSTOM',
    component: Dropzone,
    topHelper: (
      <LinkFormHelper
        href={t('admin_create-configuration_step-prebuilt_queries_helper_link')}
      >
        {t('admin_create-configuration_step-prebuilt_queries_helper_text')}
      </LinkFormHelper>
    ),
  },
];

export default ConfigurationFieldsAPI;
