import styled from 'styled-components';

export const FeedbackContainer = styled.div`
  display: flex;
`;

export const FormContainer = styled.div`
  display: flex;
  flex: 1;
`;
