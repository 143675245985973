import { Button, Heading, Text } from '@sede-x/shell-ds-react-framework';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const HeadingStyled = styled(Heading)`
  font-size: 20px;
  margin-top: 42px;
`;

export const EndpointContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  border: 1px solid #fdb840;
  padding: 14px;
  text-align: left;
  margin-top: 16px;
  align-items: center;
`;

export const EndpointText = styled(Text)`
  flex: 1;
  margin-left: 12px;
`;

export const ButtonStyled = styled(Button)`
  margin-top: 16px;
`;
