import styled from 'styled-components';

export const ContactsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem 0;
  max-width: 1024px;
`;

export const StyledMailLink = styled.a`
  color: ${(props) => props.theme.text.onSurface.link};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export const StyledLink = styled(StyledMailLink)`
  &:hover {
    text-decoration: none;
  }
`;
