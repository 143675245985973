import { Heading } from '@sede-x/shell-ds-react-framework';
import React from 'react';
import CodeBlock from '../../../../../../components/CodeBlock/CodeBlock';
import { DataProductType } from '../../../../../../state/models/dataProductsModel/types';
import dataProductTypes from '../../../../../../utils/dataProductTypes';
import { Container } from './Code.styles';

interface Props {
  dataProduct: DataProductType;
}
const getEndpointPath = (dataProduct: DataProductType, path = true) => {
  const endpoint = dataProduct.endpoints?.[0] || {
    path: '{ENDPOINT}',
  };
  return path ? `${endpoint.path}/{optional path}` : endpoint.path;
};

const consumptionExamples = (dataProduct: DataProductType) => {
  const APICodeblock = (
    <CodeBlock
      title="Consuming an API data product"
      description="Once you are authenticated, you'll be able to perform requests to the data products. If needed, include the path you want to reach and the necessary parameters to consume it.

      Check the data product's documentation for additional references."
    >
      {`# Consume an API data product
# Your request will include the token obtained during the authentication
data = dp.consume(dataproduct_name="${dataProduct.name}-${getEndpointPath(
        dataProduct,
      )}", version="${dataProduct.version}")
        
# If you wish to send custom credentials for that particular request, include them as part of the parameters.
# data = dp.consume(dataproduct_name="${dataProduct.name}-${getEndpointPath(
        dataProduct,
      )}", version="${
        dataProduct.version
      }", client_id="{custom-clientid}", client_secret="{custom-clientsecret}")

print(data)`}
    </CodeBlock>
  );
  const EventCodeBlock = (
    <CodeBlock
      title="Consuming events from a Topic"
      description="Once you are authenticated, you'll be able to subscribe to the Topic. You must include your Kafka credentials (client ID and client secret), which are provided by the Data Product owner."
    >
      {`import json

# Look for an Event Data Product
results = dp.search("${dataProduct.name}")
dataproduct = dp.fetch(results[0].id)

print("----------DataProduct:-------------")
print(dataproduct)
print("--------------------------------")

# Consume events!
for message in dp.consume_events(
    dataproduct.uri,
    dataproduct.topic_name,
    dataproduct.poll_time,
    "<KAFKA_CLIENT_ID>",
    "<KAFKA_CLIENT_SECRET>"),
):
    print("New event!")
    json_object = json.loads(message)
    json_formatted_str = json.dumps(json_object, indent=2)
    print(json_formatted_str)`}
    </CodeBlock>
  );
  return {
    [dataProductTypes.API]: APICodeblock,
    [dataProductTypes.Event]: EventCodeBlock,
    [dataProductTypes.Streaming]: EventCodeBlock,
    [dataProductTypes.Resource]: <></>,
  };
};

const Code: React.FC<Props> = ({ dataProduct }) => {
  return (
    <Container>
      <Heading level={2} bold gutter>
        Code snippets
      </Heading>
      <CodeBlock
        language="bash"
        title="Install Handbook package (DX Python SDK)"
        description="You can get the latest version of Handbook from the Github repository."
      >
        {`%pip install git+ssh://git@github.com/sede-x/seads-dx-handbook.git@latest`}
      </CodeBlock>
      <CodeBlock
        title="Shell User authentication"
        description="You can automatically authenticate using your local Shell certificate."
      >
        {`## Import client class from DX Handbook 
from dx_sdk import DataProductClient 
## Create an instance, this will trigger the PKCE flow to read your local Shell certificate 
dp = DataProductClient()`}
      </CodeBlock>
      {dataProduct.type === dataProductTypes.API && (
        <CodeBlock
          title="Credentials based authentication"
          description="If you've been provided with credential to authenticate to the data product (client ID and client secret), include them as parameters as shown below.
Learn how to obtain your client secret at https://greenhouse.seads.shell.com/Data-Exchange/Using-Postman-and-pipelines/How-to-get-your-ClientID-and-Secret/"
        >
          {`## Import client class from DX Handbook
from dx_sdk import DataProductClient

## Create an instance with credentials
dp = DataProductClient(
  client_id="my-client-id",client_secret="my-client-secret"
)`}
        </CodeBlock>
      )}
      {consumptionExamples(dataProduct)[dataProduct.type]}
    </Container>
  );
};

export default Code;
