import { useEffect } from 'react';
import Layout from '../../../../components/Layout/Layout';
import AdminFormEditSection from '../sections/EditForms/AdminFormEditSection';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../state/store';
import LoadingFullPageComponent from '../../../../components/LoadingFullPageComponent/LoadingFullPageComponent';

const AdminFormEditPage = () => {
  const { name } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    if (name) {
      dispatch.admin.getCurrentDataProduct({ name });
    }
    return () => {
      dispatch.admin.cleanCurrentDataProduct();
    };
  }, [name]);

  const { currentDataProduct } = useSelector((state: RootState) => state.admin);

  return (
    <Layout
      variant="CENTERED_COLUMN"
      content={
        !currentDataProduct.id ? (
          <LoadingFullPageComponent />
        ) : (
          <AdminFormEditSection product={currentDataProduct} />
        )
      }
      padding="0px"
    />
  );
};

export default AdminFormEditPage;
