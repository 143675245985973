import React, { useState } from 'react';
import { Form } from '@sede-x/glass-design-library';
import { FeedbackContainer, FormContainer } from './FeedbackForm.style';
import image from '../../assets/images/feedback-form-image.svg';
import { useTranslation } from 'react-i18next';
import DataProductService, {
  Feedback,
} from '../../services/DataProductService';
import ConfirmModal from './components/ConfirmModal';

const FeedbackForm = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation();
  const [submited, setSubmited] = useState(false);
  const [kindOption, setKindOption] = useState<string>();
  const customKindOption = t('feedback_form_kind-option4');

  const formConfig = [
    {
      name: 'heading',
      title: t('feedback_form_title'),
      subtitle: t('feedback_form_subtitle'),
      type: 'HEADING',
    },

    {
      name: 'divider1',
      type: 'DIVIDER',
    },
    {
      label: t('feedback_form_kind-label'),
      name: 'kind',
      required: true,
      options: [
        {
          label: t('feedback_form_kind-option1'),
          value: t('feedback_form_kind-option1'),
        },
        {
          label: t('feedback_form_kind-option2'),
          value: t('feedback_form_kind-option2'),
        },
        {
          label: t('feedback_form_kind-option3'),
          value: t('feedback_form_kind-option3'),
        },
        {
          label: customKindOption,
          value: customKindOption,
        },
      ],
      type: 'RADIO',
    },
    ...(kindOption === customKindOption
      ? [
          {
            label: t('feedback_form_custom-kind_label'),
            name: 'customKind',
            placeholder: t('feedback_form_custom-kind_placeholder'),
            required: true,
            type: 'TEXT_INPUT',
          },
        ]
      : []),
    {
      label: t('feedback_form_feedback-label'),
      name: 'feedback',
      placeholder: t('feedback_form_feedback-placeholder'),
      required: true,
      type: 'TEXT_AREA',
    },
    {
      label: t('feedback_form_contact-label'),
      name: 'contact',
      required: true,
      options: [
        {
          label: t('feedback_form_contact-option1'),
          value: 1,
        },
        {
          label: t('feedback_form_contact-option2'),
          value: -1,
        },
      ],
      type: 'RADIO',
    },
  ];

  const handleKindChange = (values: { [key: string]: string }) => {
    setKindOption(values['kind']);
  };

  interface FeedbackData {
    kind: string;
    feedback: string;
    contact: number;
    customKind?: string;
  }

  const onSubmit = (values: FeedbackData) => {
    const feedbackData = {
      feedback: values.feedback,
      contact: values.contact > 0,
      kind: values.customKind ?? values.kind,
    } as Feedback;

    DataProductService.sendFeedback(feedbackData)
      .then(() => {
        setSubmited(true);
      })
      .finally(() => {
        onClose();
      });
  };

  if (submited) {
    return <ConfirmModal onClose={() => setSubmited(false)} />;
  }

  return (
    <FeedbackContainer>
      <img src={image} alt="DataXplorer feedback form" />
      <FormContainer>
        <Form
          formConfig={formConfig}
          onSubmit={onSubmit as (values: object) => void}
          onChange={handleKindChange}
          onCancel={() => onClose()}
          submitButton={{
            text: t('feedback_form_submit-button'),
            variant: 'filled',
          }}
        />
      </FormContainer>
    </FeedbackContainer>
  );
};

export default FeedbackForm;
