import {
  CenterContainer,
  Container,
  LeftContainer,
  RightContainer,
} from './EndpointItem.styles';
import { Endpoint } from '../EndpointsSelector';
import { Button, Icons, Text } from '@sede-x/shell-ds-react-framework';
import { bytesToMbText } from '../../../../utils/sizeConverters';

interface Props {
  endpoint: Endpoint;
  onDelete: () => void;
}

const EndpointItem = ({ onDelete, endpoint }: Props) => {
  return (
    <Container>
      <LeftContainer>
        <Icons.FileText width={28} height={28} />
      </LeftContainer>
      <CenterContainer>
        <Text>{endpoint.path}</Text>
        <Text>{endpoint.schemaFile?.name}</Text>
        <Text>{bytesToMbText(endpoint.schemaFile?.size ?? 0)}</Text>
      </CenterContainer>
      <RightContainer>
        <Button
          icon={<Icons.TrashAlt width={28} height={28} />}
          iconOnly
          size="medium"
          variant="transparent"
          onClick={onDelete}
        />
      </RightContainer>
    </Container>
  );
};

export default EndpointItem;
