import { createModel } from '@rematch/core';
import reducers from './reducers';
import effects from './effects';
import { RootModel } from '../../store';
import { AdminState, DataProductAdminType } from './types';

const DataProductsModel = createModel<RootModel>()({
  state: {
    ownDataProducts: [],
    totalItems: 0,
    isLoading: false,
    hasError: false,
    currentDataProduct: {} as DataProductAdminType,
  } as AdminState,
  effects,
  reducers,
});
export default DataProductsModel;
