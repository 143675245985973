import styled from 'styled-components';

export const LoadingCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  user-select: none;
`;

export const LeftLoadingCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

export const RightLoadingCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 20;
`;
