import { PageLayout } from '@sede-x/glass-design-library';
import { ShellThemeProvider } from '@sede-x/shell-ds-react-framework';
import { AppContainer } from '../../globalStyles';

import { headerConfig, LayoutProps } from './common';
import { useTranslation } from 'react-i18next';

const IndependentLayout = ({ variant, content, padding }: LayoutProps) => {
  const { t } = useTranslation();
  return (
    <ShellThemeProvider theme="dark">
      <AppContainer>
        <PageLayout
          globalHeader={headerConfig(t)}
          variant={variant}
          content={content}
          padding={padding}
        />
      </AppContainer>
    </ShellThemeProvider>
  );
};

export default IndependentLayout;
