import { createModel } from '@rematch/core';
import reducers from './reducers';
import effects from './effects';
import { RootModel } from '../../store';
import { AdminStateGlass, GlassProductAdminType } from './types';

const GlassProductsModel = createModel<RootModel>()({
  state: {
    ownGlassProducts: [],
    totalGlassItems: 0,
    isLoading: false,
    hasError: false,
    currentGlassProduct: {} as GlassProductAdminType,
    allGlassProducts: {} as GlassProductAdminType[]
  } as AdminStateGlass,
  effects,
  reducers,
});
export default GlassProductsModel;
