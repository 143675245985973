import styled from 'styled-components';
import { Text } from '@sede-x/shell-ds-react-framework';

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const IconContainer = styled.div`
  padding: 0 12px;
  svg path {
    fill: ${({ theme }) => theme.system.strong.warning};
  }
`;

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 12px;
`;

export const BodyText = styled(Text)`
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  white-space: pre-line;
`;

export const BoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 12px 4px;
  margin-bottom: 24px;
  border: 1px solid ${({ theme }) => theme.system.strong.warning};
`;
