import React from 'react';
import { DataProductType } from '../../../../../../../state/models/dataProductsModel/types';

import { Label } from '@sede-x/shell-ds-react-framework';

import { useTranslation } from 'react-i18next';
import { EditButtonStyled } from './EditButton.style';
import routes from '../../../../../../../routes/routes';
import { useNavigate } from 'react-router-dom';
interface Props {
  dataProduct: DataProductType;
}

const EditButton: React.FC<Props> = ({ dataProduct }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleLike = () => {
    navigate(`${routes.edit}/${dataProduct.name}`);
  };

  return (
    <EditButtonStyled
      variant="outlined"
      data-testid="edit-button"
      onClick={handleLike}
    >
      <Label>{t('admin_update-product_details_edit')}</Label>
    </EditButtonStyled>
  );
};

export default EditButton;
