import { Tabs } from '@sede-x/shell-ds-react-framework';
import styled from 'styled-components';

export const TabsStyled = styled(Tabs)`
  height: 72px;
  max-width: 1024px;
  display: flex;
  flex: 1;
  flex-direction: row;

  .shell-tabs-nav {
    height: 100%;
  }
  .shell-tabs-nav-wrap {
    height: 100%;
    width: 100%;
  }
`;
