import { Button } from '@sede-x/shell-ds-react-framework';
import styled from 'styled-components';

export const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding: 2rem 4rem;
  svg path {
    fill: ${({ theme }) => theme.system.strong.positive};
  }
`;

export const TextContainer = styled.div`
  text-align: center;
`;

export const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
`;

export const ConfirmButton = styled(Button)`
  width: 140px;
`;
