import { Container, StyledLink, StyledAnchor } from './FieldWithIcon.styles';
import { ReactElement } from 'react-markdown/lib/react-markdown';
import { Text } from '@sede-x/shell-ds-react-framework';

const FieldWithIcon = ({
  text,
  icon,
  href,
  external = false,
}: {
  text: string;
  icon: ReactElement;
  href: string;
  external?: boolean;
}) => {
  return (
    <Container>
      <Text>{text}</Text>
      {external ? (
        <StyledAnchor href={href} target="_blank" rel="noopener noreferrer">
          {icon}
        </StyledAnchor>
      ) : (
        <StyledLink to={href}>{icon}</StyledLink>
      )}
    </Container>
  );
};

export default FieldWithIcon;
