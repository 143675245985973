import { Heading, Icons, Label } from '@sede-x/shell-ds-react-framework';
import React from 'react';
import {
  Container,
  CodeContainer,
  ButtonStyled,
  TextContainer,
} from './CodeBlock.styles';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import python from 'react-syntax-highlighter/dist/esm/languages/hljs/python';
import vs2015 from 'react-syntax-highlighter/dist/esm/styles/hljs/vs2015';
import { useTheme } from 'styled-components';

SyntaxHighlighter.registerLanguage('python', python);

interface Props {
  children: string;
  language?: string;
  title?: string;
  description?: string;
}

const CodeBlock: React.FC<Props> = ({
  children,
  language = 'python',
  title,
  description,
}: Props) => {
  const theme = useTheme();
  const onCopy = () => {
    navigator.clipboard.writeText(children);
  };

  return (
    <Container>
      <TextContainer>
        <Heading level={4} bold>
          {title}
        </Heading>
        <Label size="small">{description}</Label>
      </TextContainer>
      <CodeContainer>
        <ButtonStyled
          variant="transparent"
          iconOnly
          icon={<Icons.Copy />}
          onClick={onCopy}
        />
        <SyntaxHighlighter
          customStyle={{
            marginTop: 0,
            marginBottom: 0,
            backgroundColor: theme.background.section,
          }}
          language={language}
          style={vs2015}
        >
          {children}
        </SyntaxHighlighter>
      </CodeContainer>
    </Container>
  );
};

export default CodeBlock;
