import styled from 'styled-components';

export const EmptyStateContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 20px;
  max-width: 1300px;
  width: 100%;
  gap: 6px;
`;

export const StyledKeywords = styled.span`
  font-weight: bold;
  font-size: 1.8rem;
`;

export const StyledList = styled.ul`
  margin: 0px;
  padding-bottom: 0;
`;
