import { Checkbox, Text } from '@sede-x/shell-ds-react-framework';
import React from 'react';
import { Container, InnerContainer } from './CheckBoxMotalItem.styles';
import { CheckboxItem } from '../CheckBoxModal';

interface CheckBoxProps extends CheckboxItem {
  onChange: (value: boolean) => void;
  isDisabled?: boolean;
}

const CheckBoxModalItem: React.FC<CheckBoxProps> = ({
  title,
  description,
  active,
  onChange,
  isDisabled = false,
}) => {
  return (
    <Container>
      <Checkbox
        size="large"
        checked={active}
        disabled={isDisabled}
        onChange={() => onChange(!active)}
        label={
          <InnerContainer>
            <Text bold>{title}</Text>
            <Text>{description}</Text>
          </InnerContainer>
        }
      />
    </Container>
  );
};

export default CheckBoxModalItem;
