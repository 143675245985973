import { Icons } from '@sede-x/shell-ds-react-framework';
import { ButtonStyled, Container } from './MetadataField.styles';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import FieldInputs, { MetadataFieldType } from './FieldInputs/FieldInputs';

interface Props {
  onChange: (value: string) => void;
  value?: string;
}

const maxChars = 60;

const MetadataField = ({ onChange, value }: Props) => {
  const { t } = useTranslation();
  const [metadataValues, setMetadataValues] = useState<MetadataFieldType[]>(
    value ? JSON.parse(value) : [],
  );

  const callOnChange = (values: MetadataFieldType[]) => {
    const valuesWithoutEmpty = values.filter(
      (v) => v.key !== '' || v.value !== '',
    );

    const areValuesValid =
      valuesWithoutEmpty.length === 0 ||
      valuesWithoutEmpty
        .map((v) => v.key !== '' && v.key.length <= maxChars && v.value !== '')
        .every((v) => v);

    if (areValuesValid) {
      onChange(JSON.stringify(valuesWithoutEmpty));
    } else {
      onChange('');
    }
  };

  const handleCreateNew = () => {
    const newValues = [
      ...metadataValues,
      { id: Date.now().toString(), key: '', value: '' },
    ];
    setMetadataValues(newValues);
    callOnChange(newValues);
  };

  const handleRemove = (id: string) => () => {
    const newValues = metadataValues.filter((mdV) => mdV.id !== id);
    setMetadataValues(newValues);
    callOnChange(newValues);
  };

  const handleChange = (id: string) => (mdField: MetadataFieldType) => {
    const newValues = metadataValues.map((md) => (md.id === id ? mdField : md));
    setMetadataValues(newValues);
    callOnChange(newValues);
  };

  return (
    <Container>
      {metadataValues.map((mV) => (
        <FieldInputs
          value={mV}
          onChange={handleChange(mV.id)}
          onDelete={handleRemove(mV.id)}
          key={mV.id}
        />
      ))}
      <ButtonStyled
        icon={<Icons.Add />}
        iconPosition="left"
        onClick={handleCreateNew}
        variant="outlined"
      >
        {t('metadata_field-add_new_button')}
      </ButtonStyled>
    </Container>
  );
};

export default MetadataField;
