import { Icons } from '@sede-x/shell-ds-react-framework';
import { Container } from './AdminListSection.styles';
import { TableSystem } from '@sede-x/glass-design-library';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../state/store';
import { useEffect, useState } from 'react';
import TableActions from './helpers/TableActions/TableActions';
import routes from '../../../../../routes/routes';
import AdminListEmptyState from './components/AdminListEmptyState/AdminListEmptyState';
import { useTranslation } from 'react-i18next';
import TypeTranslation from '../../../../../utils/i18n/TypeTranslation';
import FieldWithLinkIcon from './helpers/FieldWithIcon/FieldWithIcon';
import ConfirmationDeleteModal from './helpers/TableActions/ConfirmationDeleteModal/ConfirmationDeleteModal';
import ErrorModal from './helpers/TableActions/ErrorModal/ErrorModal';
import SuccessModal from './helpers/TableActions/SucessModal/SucessModal';
import { DataProductAdminListType } from '../../../../../state/models/adminModel/types';
import FieldCompleteness from './helpers/FieldCompleteness/FieldCompleteness';
import LinkWithIcon from '../../../../../components/LinkWithIcon/LinkWithIcon';
import { useNavigate } from 'react-router-dom';

const columns = (t: TypeTranslation) => [
  {
    dataIndex: 'name',
    key: 'name',
    sortable: true,
    title: t('admin_list-column_title-name'),
  },
  {
    dataIndex: 'completeness',
    key: 'completeness',
    sortable: false,
    title: t('admin_list-column_title-completeness'),
  },
  {
    dataIndex: 'type',
    key: 'type',
    sortable: true,
    title: t('admin_list-column_title-type'),
  },
  {
    dataIndex: 'version',
    key: 'version',
    sortable: true,
    title: t('admin_list-column_title-version'),
  },
  {
    dataIndex: 'update',
    key: 'update',
    sortable: true,
    title: t('admin_list-column_title-update'),
  },
  {
    dataIndex: 'likes',
    key: 'likes',
    sortable: true,
    title: t('admin_list-column_title-likes'),
  },
  {
    dataIndex: 'actions',
    key: 'actions',
    title: t('admin_list-column_title-actions'),
  },
];

const pageSize = 10;

const AdminListSection = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deletedSuccessfully, setDeletedSuccessfully] = useState(false);
  const [currentDataProduct, setCurrentDataProduct] = useState<
    DataProductAdminListType | undefined
  >(undefined);

  const { ownDataProducts, totalItems, isLoading, hasError } = useSelector(
    (state: RootState) => {
      return state.admin;
    },
  );

  useEffect(() => {
    dispatch.admin.getOwnDataProducts({ page });
  }, [page]);

  const data = ownDataProducts.map((dP) => ({
    name: (
      <FieldWithLinkIcon
        text={dP.businessName}
        href={`${routes.details}/${dP.name}`}
        icon={<Icons.Open width={20} height={20} />}
      />
    ),
    completeness: <FieldCompleteness value={dP.completeness ?? 0} />,
    type: dP.type,
    version: dP.version,
    update: new Date(dP.updateTime).toLocaleDateString('en-GB'),
    likes: t('admin_list-column_title-user_likes', {
      likes: dP.likes?.toLocaleString('en-US'),
    }),
    actions: (
      <TableActions
        onDelete={() => {
          setCurrentDataProduct(dP);
          setIsDeleting(true);
        }}
        handleEdit={() => navigate(`${routes.edit}/${dP.name}`)}
      />
    ),
  }));

  const handleDelete = async () => {
    await dispatch.admin.deleteDataProduct({
      name: currentDataProduct?.name,
    });
    if (ownDataProducts.length <= 1 && page > 1) {
      setPage(page - 1);
    }
    setDeletedSuccessfully(true);
  };

  const hasTable = isLoading || ownDataProducts.length > 0;

  const confirmModalOpen = isDeleting && !hasError && !deletedSuccessfully;
  const errorModalOpen = isDeleting && hasError;
  const successModalOpen = isDeleting && !hasError && deletedSuccessfully;

  const confirmModal = confirmModalOpen && (
    <ConfirmationDeleteModal
      onConfirm={async () => {
        await handleDelete();
      }}
      onCancel={() => setIsDeleting(false)}
      productTitle={currentDataProduct?.businessName ?? ''}
    />
  );

  const errorModal = errorModalOpen && (
    <ErrorModal
      title={currentDataProduct?.businessName ?? ''}
      onTryAgain={() => {
        setDeletedSuccessfully(false);
        setIsDeleting(true);
        dispatch.admin.cleanError();
      }}
      onCancel={() => {
        setIsDeleting(false);
        dispatch.admin.cleanError();
        setCurrentDataProduct(undefined);
      }}
    />
  );

  const successModal = successModalOpen && (
    <SuccessModal
      onConfirm={() => {
        setDeletedSuccessfully(false);
        setIsDeleting(false);
        setCurrentDataProduct(undefined);
        dispatch.admin.getOwnDataProducts({ page });
      }}
      productName={currentDataProduct?.businessName}
    />
  );

  return (
    <Container>
      {hasTable ? (
        <>
          <TableSystem
            columns={columns(t)}
            data={data}
            hasHover={false}
            onPageChange={setPage}
            totalRows={totalItems}
            loading={isLoading}
            maxRowsPerPage={pageSize}
            page={page}
            pagination
          />
          {confirmModal}
          {errorModal}
          {successModal}
          <LinkWithIcon href={t('admin_list-product_main_link')}>
            {t('admin_list-product_main_link_text')}
          </LinkWithIcon>
        </>
      ) : (
        <AdminListEmptyState />
      )}
    </Container>
  );
};

export default AdminListSection;
