import { useEffect, useState } from 'react';
import {
  ButtonStyled,
  Container,
  EndpointsContainer,
} from './EndpointsSelector.styles';
import { FormField, TextInput } from '@sede-x/shell-ds-react-framework';
import Dropzone from '../../Dropzone/Dropzone';
import EndpointItem from './EndpointItem/EndpointItem';
import { ALPHANUMERIC_FORMAT, URL_FORMAT } from '../../../utils/regexpConst';
import { useTranslation } from 'react-i18next';

interface Props {
  onChange: (value: Endpoint[] | undefined) => void;
  value: Endpoint[];
}

const checkIfValidSchema = async (schema: File | undefined) => {
  if (schema === undefined || !schema?.size) {
    return false;
  }
  if (schema.name.includes('.json')) {
    try {
      const textValue = await schema.text();
      const parseValue = JSON.parse(textValue);
      return Boolean(parseValue.swagger || parseValue.openapi);
    } catch (e) {
      return false;
    }
  }
  return true;
};

export type Endpoint = {
  path: string;
  serviceURL: string;
  schemaFile?: File;
};

type ErrorsEndpoint = {
  path?: string;
  serviceURL?: string;
  schemaFile?: string;
};

const EndpointsSelector = ({ onChange, value }: Props) => {
  const { t } = useTranslation();
  const [currentEndpoints, setCurrentEndpoints] = useState<
    Endpoint[] | undefined
  >(value || undefined);

  const [errors, setErrors] = useState<ErrorsEndpoint>({});

  const [currentEndpointValue, setCurrentEndpointValue] = useState<Endpoint>({
    path: '',
    serviceURL: '',
  });

  const calculateErrors = async (endpointsValue: Endpoint) => {
    const invalidValueKey = t('error_generic-invalid_format');
    const newErrors: ErrorsEndpoint = {};
    if (
      endpointsValue.path &&
      !new RegExp(ALPHANUMERIC_FORMAT).test(endpointsValue.path)
    ) {
      newErrors.path = invalidValueKey;
    }
    if (
      endpointsValue.serviceURL &&
      !new RegExp(URL_FORMAT).test(endpointsValue.serviceURL)
    ) {
      newErrors.serviceURL = invalidValueKey;
    }
    if (
      endpointsValue.schemaFile &&
      !(await checkIfValidSchema(endpointsValue.schemaFile))
    ) {
      newErrors.schemaFile = invalidValueKey;
    }

    setErrors(newErrors);
  };

  useEffect(() => {
    calculateErrors(currentEndpointValue);
  }, [currentEndpointValue]);

  const handleSave = () => {
    if (!errors.path && !errors.serviceURL) {
      const newEndpoints = currentEndpoints
        ? [...currentEndpoints, currentEndpointValue]
        : [currentEndpointValue];
      setCurrentEndpoints(newEndpoints);
      onChange(newEndpoints);
      setCurrentEndpointValue({
        path: '',
        serviceURL: '',
      });
    }
  };

  const addNewValue = (key: string, newValue: string | File) => {
    setCurrentEndpointValue({
      ...currentEndpointValue,
      [key]: newValue,
    });
  };

  const hasValue =
    currentEndpointValue.path &&
    currentEndpointValue.serviceURL &&
    currentEndpointValue.schemaFile;

  return (
    <Container>
      <FormField
        description={t(
          'admin_create-configuration_step-endpoints-path_description',
        )}
        id="path"
        label={t('admin_create-configuration_step-endpoints-path_title')}
        mandatory
        size="medium"
        bottomLeftHelper={
          errors.path
            ? {
                content: <b>{errors.path}</b>,
                sentiment: 'negative',
              }
            : undefined
        }
      >
        <TextInput
          aria-labelledby="path"
          id="path"
          size="medium"
          placeholder={t(
            'admin_create-configuration_step-endpoints-path_placeholder',
          )}
          onChange={(e) => addNewValue('path', e.target.value)}
          value={currentEndpointValue?.path}
        />
      </FormField>
      <FormField
        description={t(
          'admin_create-configuration_step-endpoints-service_url_description',
        )}
        id="serviceURL"
        label={t('admin_create-configuration_step-endpoints-service_url_title')}
        mandatory
        size="medium"
        bottomLeftHelper={
          errors.serviceURL
            ? {
                content: <b>{errors.serviceURL}</b>,
                sentiment: 'negative',
              }
            : undefined
        }
      >
        <TextInput
          aria-labelledby="serviceURL"
          id="serviceURL"
          size="medium"
          placeholder={t(
            'admin_create-configuration_step-endpoints-service_url_placeholder',
          )}
          onChange={(e) => addNewValue('serviceURL', e.target.value)}
          value={currentEndpointValue?.serviceURL}
        />
      </FormField>
      <FormField
        description={t(
          'admin_create-configuration_step-endpoints-schema_file_description',
        )}
        bottomLeftHelper={
          errors.schemaFile
            ? {
                content: <b>{errors.schemaFile}</b>,
                sentiment: 'negative',
              }
            : undefined
        }
        id="schemaFile"
        label={t('admin_create-configuration_step-endpoints-schema_file_title')}
        mandatory
        size="medium"
      >
        <Dropzone
          onChange={(file) => {
            addNewValue('schemaFile', file);
          }}
          value={currentEndpointValue?.schemaFile}
          allowedFiles={{
            'application/json': ['.json'],
            'application/graphql': ['.graphql'],
          }}
        />
      </FormField>
      <ButtonStyled
        disabled={!hasValue || Object.keys(errors).length > 0}
        onClick={handleSave}
      >
        {t('admin_create-configuration_step-endpoints-save_button_text')}
      </ButtonStyled>
      <EndpointsContainer>
        {currentEndpoints?.map((eP) => (
          <EndpointItem
            key={`${eP.path}/${eP.serviceURL}`}
            endpoint={eP}
            onDelete={() => {
              const newEndpoints = currentEndpoints.filter(
                (cE) => cE.path !== eP.path || cE.serviceURL !== eP.serviceURL,
              );
              setCurrentEndpoints(newEndpoints);
              onChange(newEndpoints.length > 0 ? newEndpoints : undefined);
            }}
          />
        ))}
      </EndpointsContainer>
    </Container>
  );
};

export default EndpointsSelector;
