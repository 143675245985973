import { createModel } from '@rematch/core';
import reducers from './reducers';
import effects from './effects';
import { RootModel } from '../../store';
import { mySubscriptionsState } from './types';
const MySubscriptionModel = createModel<RootModel>()({
  state: {
    DataDomain: [],
  } as mySubscriptionsState,
  effects,
  reducers,
});
export default MySubscriptionModel;
