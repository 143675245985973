import React from 'react';
import {
  Button,
  Icons,
  Label,
  Popover,
} from '@sede-x/shell-ds-react-framework';
import { PopupContainer, VersionItem } from './VersionSelectorButton.styles';
import { updateTimeAndVersion } from '../../../../../../../utils/dateHelper';

type Version = { number: string; lastUpdated: string };

interface Props {
  versions: Version[];
  currentVersion: string;
  onChangeVersion: (version: string) => void;
}

const VersionSelectorButton: React.FC<Props> = ({
  versions,
  currentVersion,
  onChangeVersion,
}) => {
  const hasMultipleVersions = versions.length > 1;
  return (
    <Popover
      popup={
        hasMultipleVersions && (
          <PopupContainer>
            {versions.map((v) => (
              <VersionItem
                onClick={() => onChangeVersion(v.number)}
                key={v.number}
              >
                <Label bold>v.{v.number}</Label>
                <Label prominence="subtle">
                  {updateTimeAndVersion(parseInt(v.lastUpdated, 10)).substring(
                    2,
                  )}
                </Label>
              </VersionItem>
            ))}
          </PopupContainer>
        )
      }
      arrow={hasMultipleVersions}
    >
      <Button
        variant="transparent"
        iconPosition="right"
        icon={hasMultipleVersions ? <Icons.ChevronDown /> : undefined}
      >
        v.{currentVersion}
      </Button>
    </Popover>
  );
};

export default VersionSelectorButton;
