import styled from 'styled-components';

export const CenteredContainer = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 178px auto;
`;

export const BodyContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const InnerBodyContainer = styled.div`
  display: grid;
  grid-template-columns: 264px minmax(auto, 1024px) 264px;
`;

export const CatalogueContainer = styled.div`
  display: flex;
  flex: 1;
  margin-top: 48px;
`;

export const MainBannerContainer = styled.div`
  width: 100%;
`;
