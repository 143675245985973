import {
  Divider,
  Heading,
  Icons,
  Label,
  Text,
} from '@sede-x/shell-ds-react-framework';
import { generatePath } from 'react-router-dom';
import { StyledLink } from '../../globalStyles';
import {
  ItemAvatar,
  ItemContainer,
  ItemHeader,
  ItemWrapper,
  TagContainer,
  TruncateText,
} from './Item.style';
import TypeBadge from '../TypeBadge/TypeBadge';
import { DataProductVariant } from '../../utils/dataProductTypes';
import TagComponent from '../TagComponent/TagComponent';
import removeMD from 'remove-markdown';
import { useTranslation } from 'react-i18next';
import useParamsHandler, { FilterNames } from '../../hooks/useParamsHandler';

export interface ItemTypes {
  id: string;
  name: string;
  description: string;
  tags?: string[];
  terms?: string[];
  businessName: string;
  type: DataProductVariant;
  likes?: number;
}

interface Props {
  item: ItemTypes;
}

const typeIcons = {
  API: 'DesignSystem',
  Event: 'Calendar',
  Resource: 'FileText',
  Streaming: 'LineChartSolid',
  Batch: 'RowsSolid',
};

function mutateIf<T>({
  condition,
  list,
  operator,
}: {
  condition: boolean;
  list: T[];
  operator: (list: T[]) => T[];
}): T[] {
  if (condition) {
    return operator(list);
  } else {
    return list;
  }
}

const Item: React.FC<Props> = ({ item }) => {
  const { t } = useTranslation();
  const { params, getHref } = useParamsHandler();
  const { description, tags = [], terms = [], businessName, type } = item;
  const TypeIcon = Icons[typeIcons[type] as keyof typeof Icons];

  const updateFilter = (key: FilterNames, value: string) => {
    const selectedValues = params[key] || [];
    return selectedValues.includes(value)
      ? mutateIf({
          condition: key === 'terms',
          list: selectedValues,
          operator: (list) =>
            list.filter((selectedValue) => selectedValue !== value),
        })
      : [...selectedValues, value];
  };

  return (
    <StyledLink to={generatePath('/product/:name', { name: item.name })}>
      <ItemWrapper>
        <ItemAvatar>
          <TypeIcon data-testid={`icon-${type}`} />
        </ItemAvatar>
        <ItemContainer>
          <ItemHeader>
            <TypeBadge type={type} iconVisible={false} />
            <Label color="light">
              {t('admin_list-column_title-user_likes', {
                likes: item.likes?.toLocaleString('en-US') ?? 0,
              })}
            </Label>
          </ItemHeader>
          <Heading bold level={4}>
            {businessName}
          </Heading>
          <Text size="small">
            <TruncateText>{removeMD(description)}</TruncateText>
          </Text>
          <TagContainer>
            {terms?.map((term) => (
              <TagComponent
                selectable
                tag={{ name: term, description: '' }}
                type="term"
                key={term}
                selected={params['terms']?.includes(term)}
                href={getHref({ terms: updateFilter('terms', term) })}
              />
            ))}
            {tags?.map((tag) => (
              <TagComponent
                selectable
                tag={{ name: tag, description: '' }}
                type="tag"
                key={tag}
                selected={params['tags']?.includes(tag)}
                href={getHref({ tags: updateFilter('tags', tag) })}
              />
            ))}
          </TagContainer>
          <Divider size="small" gutter="tight" />
        </ItemContainer>
      </ItemWrapper>
    </StyledLink>
  );
};

export default Item;
