import { useSearchParams } from 'react-router-dom';

const referralName = 'referral';

const useHandleReferral = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  return {
    referral: searchParams.get(referralName),
    cleanReferral: () => {
      searchParams.delete(referralName);
      setSearchParams(searchParams);
    },
  };
};

export default useHandleReferral;
