import styled from 'styled-components';

export const CenteredForm = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  max-width: 1024px;
  min-width: 240px;
`;
