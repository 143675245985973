import { Text } from '@sede-x/shell-ds-react-framework';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 8px 0 24px 0;
`;

export const LabelStyled = styled(Text)`
  width: 200px;
`;
