import {
  FormField,
  Icons,
  TextInput,
  Select,
} from '@sede-x/shell-ds-react-framework';
import { FieldContainer, ButtonStyled } from './FieldInputs.styles';
import { useTranslation } from 'react-i18next';
import { ChangeEvent, useState } from 'react';
import { CUSTOM_HEADERS_VALUE } from '../../../../utils/regexpConst';

export type CustomHeaderFieldType = {
  id: string;
  endpoint: string | undefined;
  value: string;
};

interface Props {
  onChange: (value: CustomHeaderFieldType) => void;
  value: CustomHeaderFieldType;
  onDelete: () => void;
  endpoints: string[];
  index: number;
}

const FieldInputs = ({
  onChange,
  value,
  onDelete,
  endpoints,
  index,
}: Props) => {
  const { t } = useTranslation();
  const [customHeaderValue, setCustomHeaderValue] =
    useState<CustomHeaderFieldType>(
      endpoints.length > 1 ? value : { ...value, endpoint: endpoints[0] },
    );
  const [fieldsTouched, setFieldsTouched] = useState({
    endpoint: false,
    value: false,
  });

  const handleChangeSelector = (e: string) => {
    const newValue = { ...customHeaderValue, endpoint: e };
    setCustomHeaderValue(newValue);
    onChange(newValue);
  };
  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = { ...customHeaderValue, value: e.target?.value };
    setCustomHeaderValue(newValue);
    onChange(newValue);
  };

  const getErrorEndpoint = (errorValue: string | undefined) => {
    if (!errorValue) {
      return t('error_generic-required');
    }
    return '';
  };

  const getErrorValue = (errorValue: string) => {
    if (!errorValue) {
      return t('error_generic-required');
    }
    if (!CUSTOM_HEADERS_VALUE.exec(errorValue)) {
      return t('error_generic-invalid_format');
    }
    return '';
  };

  return (
    <FieldContainer data-testid="custom-headers-row">
      <FormField
        label={t('custom_header_field-title_field_label', { number: index })}
        description={t('custom_header_field-title_field_description')}
        bottomLeftHelper={{
          content:
            fieldsTouched.endpoint &&
            (customHeaderValue.endpoint || customHeaderValue.value)
              ? getErrorEndpoint(customHeaderValue.endpoint)
              : '',
          sentiment: 'negative',
        }}
      >
        <Select
          value={customHeaderValue.endpoint}
          onChange={handleChangeSelector}
          data-testid="custom-header-selector"
          placeholder={t('custom_header_field-title_field_placeholder')}
          onBlur={() => setFieldsTouched({ ...fieldsTouched, endpoint: true })}
          onClear={() => handleChangeSelector('')}
          options={endpoints.map((e) => ({ value: e, label: e }))}
          showSearch={false}
          allowClear={false}
        />
      </FormField>
      <FormField
        label={t('custom_header_field-description_field_label', {
          number: index,
        })}
        description={t('custom_header_field-description_field_description')}
        topHelper={
          <ButtonStyled
            icon={<Icons.Cross />}
            iconOnly
            onClick={onDelete}
            size="small"
            variant="transparent"
            data-testid="custom-headers-delete"
          />
        }
        bottomLeftHelper={{
          content:
            fieldsTouched.value &&
            (customHeaderValue.endpoint || customHeaderValue.value)
              ? getErrorValue(customHeaderValue.value)
              : '',
          sentiment: 'negative',
        }}
      >
        <TextInput
          value={customHeaderValue.value}
          data-testid="custom-header-value"
          onChange={handleChangeInput}
          placeholder={t('custom_header_field-description_field_placeholder')}
          onBlur={() => setFieldsTouched({ ...fieldsTouched, value: true })}
        />
      </FormField>
    </FieldContainer>
  );
};

export default FieldInputs;
