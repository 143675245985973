export const getNextVersion = (oldVersion: string) => {
  if (oldVersion) {
    const numbers = oldVersion.split('.');
    if (numbers.length === 3) {
      return `${numbers.slice(0, 2).join('.')}.${parseInt(numbers[2], 10) + 1}`;
    }
    return '';
  }
  return '';
};
