export const ALPHANUMERIC_FORMAT = '^[0-9a-zA-Z][0-9A-Za-z]*$';
export const NAME_FORMAT_RANGE = (min: number, max: number) =>
  `^[0-9A-Za-z]{${min},${max}}$`;
export const LENGTH_RANGE = (min: number, max: number) =>
  `^[0-9A-Za-z_ .-]{${min},${max}}$`;
export const BUSINESS_NAME_FORMAT = (min: number, max: number) =>
  `^[0-9A-Za-z!@#$%^&*()\\-_=+ ]{${min},${max}}$`;

export const SHORT_DESCRIPTION_FORMAT = (min: number, max: number) =>
  `^[0-9A-Za-z_ .,\\-&]{${min},${max}}$`;
export const VERSION_FORMAT = '^(\\d{1,3}\\.)(\\d{1,3}\\.)(\\*|\\d{1,3})$';
export const SHELL_EMAIL_FORMAT = /^[a-zA-Z0-9._-]+@shell.com$/;
export const URL_FORMAT =
  /^(https?:\/\/)?((([a-zA-Z\d]([a-zA-Z\d-]*[a-zA-Z\d])*)\.)+[a-zA-Z]{2,}|((\d{1,3}\.){3}\d{1,3}))(\:\d+)?(\/[-a-zA-Z\d%_.~+]*)*(\?[;&a-zA-Z\d%_.~+=-]*)?(\#[-a-zA-Z\d_]*)?$/i;

export const TAG_FORMAT = /^[A-Z][A-Z0-9/_]{1,126}[A-Z0-9]$/;
export const PATH_FORMAT = '^(/[^/]+)*/?$';
export const ALPHANUMERIC_WITH_SYMBOLS_FORMAT_255 = '^[0-9A-Za-z-_.]{0,255}$';
export const NUMERIC_FORMAT = /^\d+$/;
export const DESCRIPTION_RANGE = (min: number, max: number) =>
  `^(.|\n){${min},${max}}$`;
export const CUSTOM_HEADERS_VALUE =
  /^"[A-Za-z0-9]+-[A-Za-z0-9]+":"[A-Za-z0-9]+-[A-Za-z0-9]+"$/;
