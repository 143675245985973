import {
  FormField,
  Icons,
  TextInput,
  TextArea,
} from '@sede-x/shell-ds-react-framework';
import { FieldContainer, ButtonStyled } from './FieldInputs.styles';
import { useTranslation } from 'react-i18next';
import { ChangeEvent, useState } from 'react';

export type MetadataFieldType = { id: string; key: string; value: string };

interface Props {
  onChange: (value: MetadataFieldType) => void;
  value: MetadataFieldType;
  onDelete: () => void;
}

const maxChars = 60;

const FieldInputs = ({ onChange, value, onDelete }: Props) => {
  const { t } = useTranslation();
  const [metadataValue, setMetadataValue] = useState<MetadataFieldType>(value);
  const [fieldsTouched, setFieldsTouched] = useState({
    key: false,
    value: false,
  });

  const handleChangeField =
    (field: string) =>
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setMetadataValue({ ...metadataValue, [field]: e.target.value });
      onChange({ ...metadataValue, [field]: e.target.value });
    };

  const getError = (errorValue: string) => {
    if (!errorValue) {
      return t('error_generic-required');
    }
    if (errorValue.length > maxChars) {
      return t('error_generic-invalid_format');
    }
    return '';
  };

  return (
    <FieldContainer>
      <FormField
        label={t('metadata_field-title_field_label')}
        description={t('metadata_field-title_field_description')}
        bottomLeftHelper={{
          content:
            fieldsTouched.key && (metadataValue.key || metadataValue.value)
              ? getError(metadataValue.key)
              : '',
          sentiment: 'negative',
        }}
        bottomRightHelper={{
          content: `${metadataValue.key?.length}/${maxChars}`,
          sentiment:
            metadataValue.key?.length > maxChars ? 'negative' : 'secondary',
        }}
      >
        <TextInput
          value={metadataValue.key}
          onChange={handleChangeField('key')}
          placeholder={t('metadata_field-title_field_placeholder')}
          onBlur={() => setFieldsTouched({ ...fieldsTouched, key: true })}
        />
      </FormField>
      <FormField
        label={t('metadata_field-description_field_label')}
        description={t('metadata_field-description_field_description')}
        topHelper={
          <ButtonStyled
            icon={<Icons.Cross />}
            iconOnly
            onClick={onDelete}
            size="small"
            variant="transparent"
          />
        }
        bottomLeftHelper={{
          content:
            fieldsTouched.value && (metadataValue.key || metadataValue.value)
              ? getError(metadataValue.value)
              : '',
          sentiment: 'negative',
        }}
      >
        <TextArea
          value={metadataValue.value}
          onChange={handleChangeField('value')}
          placeholder={t('metadata_field-description_field_placeholder')}
          rows={1}
          onBlur={() => setFieldsTouched({ ...fieldsTouched, value: true })}
        />
      </FormField>
    </FieldContainer>
  );
};

export default FieldInputs;
