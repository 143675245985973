import {
  Button,
  IButtonProps,
  Tooltip,
} from '@sede-x/shell-ds-react-framework';
import React, { useState } from 'react';
import { Overlay } from './ActionButton.styles';

interface Props extends IButtonProps {
  helperText?: string;
  onClick?: () => Promise<void>;
}

const timeoutTime = 2000;

const ActionButton = ({ helperText, onClick, disabled, ...props }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async () => {
    setIsLoading(true);
    await onClick?.();
    setIsLoading(false);

    if (helperText) {
      setIsOpen(true);
      setTimeout(() => {
        setIsOpen(false);
      }, timeoutTime);
    }
  };

  const renderButton = (
    <Button
      variant="transparent"
      size="large"
      iconOnly
      onClick={handleClick}
      disabled={disabled ?? isLoading}
      {...props}
    />
  );

  return helperText ? (
    <Tooltip
      placement="right"
      arrow
      overlay={<Overlay>{helperText}</Overlay>}
      isOpen={isOpen}
      offset={6}
      trigger={renderButton}
      data-testid="action-button-tooltip"
    />
  ) : (
    renderButton
  );
};

export default ActionButton;
