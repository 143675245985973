import React from 'react';
import { DataProductType } from '../../../../../../state/models/dataProductsModel/types';
import { useDispatch } from 'react-redux';
import LeftHeaderLayout from './LeftHeaderLayout';

interface Props {
  dataProduct: DataProductType;
}

export interface LeftHeaderProps {
  dataProduct: DataProductType;
  onChangeVersion: (dpVersion: string) => void;
}

const LeftHeader: React.FC<Props> = ({ dataProduct }) => {
  const dispatch = useDispatch();

  const changeVersion = (dpVersion: string) => {
    dispatch.dataProducts.getCurrentDataProduct({
      id: dataProduct.name,
      version: dpVersion,
    });
  };

  return (
    <LeftHeaderLayout
      dataProduct={dataProduct}
      onChangeVersion={changeVersion}
    />
  );
};

export default LeftHeader;
