import { Text } from '@sede-x/shell-ds-react-framework';
import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  bottom: 0;
  width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 28px 200px;
  background: ${({ theme }) => theme.background.surface};
  gap: 24px;
  & a {
    color: ${({ theme }) => theme.text.onSurface.strong};
    text-decoration: none;
    ${({ theme }) => theme.label.medium.normal}
  }
`;

export const StyledText = styled(Text)`
  cursor: pointer;
`;
