import { Form } from '@sede-x/glass-design-library';
import { SHELL_EMAIL_FORMAT } from '../../../../../utils/regexpConst';
import { DataProductVariant } from '../../../../../utils/dataProductTypes';
import GovernanceFieldsAPI from './GovernanceFieldsAPI';
import { ButtonVariant } from '@sede-x/shell-ds-react-framework';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import TypeTranslation from '../../../../../utils/i18n/TypeTranslation';
import UpdatingVersionFields from '../UpdatingVersionSection/UpdatingVersionFields';
import { getNextVersion } from '../../../../../utils/versionFunctions';
import { useNavigate } from 'react-router-dom';
import routes from '../../../../../routes/routes';

const getButtonTexts: (
  type: DataProductVariant,
  t: TypeTranslation,
) => {
  submitButton: { text: string; variant: ButtonVariant };
  cancelButton: { text: string; variant: ButtonVariant };
} = (type, t) => {
  if (type === 'API') {
    return {
      submitButton: {
        text: t('admin_create-governance_step-next_button'),
        variant: 'filled',
      },
      cancelButton: {
        text: t('admin_create-governance_step-back_button'),
        variant: 'outlined',
      },
    };
  }
  return {
    submitButton: {
      text: t('admin_create-governance_step-submit_button'),
      variant: 'filled',
    },
    cancelButton: {
      text: t('admin_create-governance_step-back_button'),
      variant: 'outlined',
    },
  };
};
const GovernanceSection = ({
  onSubmit,
  onCancel,
  defaultValues,
  type,
  isUpdating = false,
  requireNewVersion = false,
}: {
  onSubmit: (values: { [key: string]: unknown }) => void;
  onCancel: () => void;
  defaultValues?: { [key: string]: unknown };
  type: DataProductVariant;
  isUpdating?: boolean;
  requireNewVersion?: boolean;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const defaultValuesWithVersion = requireNewVersion
    ? {
        ...defaultValues,
        newVersion: getNextVersion(defaultValues?.version as string),
      }
    : defaultValues;
  const extraFields = {
    API: GovernanceFieldsAPI(t),
    Event: [],
    Streaming: [],
    Resource: [],
    Batch: [],
  };

  const buttonTexts = useMemo(() => getButtonTexts(type, t), [type, t]);

  const updatingFields = isUpdating
    ? UpdatingVersionFields({
        t,
        requireNewVersion,
        currentVersion: defaultValues?.version as string,
        type: defaultValues?.type as string,
      })
    : [];

  return (
    <Form
      onCancel={onCancel}
      onSubmit={onSubmit}
      defaultValues={defaultValuesWithVersion}
      {...buttonTexts}
      customButtons={
        isUpdating
          ? [
              {
                text: t('admin_create-product_details_back_button'),
                variant: 'outlined',
                onClick: () => navigate(routes.admin),
              },
            ]
          : []
      }
      formConfig={[
        {
          name: 'governanceTitle',
          subtitle: t('admin_create-governance_step-title_description'),
          title: t('admin_create-governance_step-title'),
          type: 'HEADING',
        },
        [
          {
            label: t('admin_create-governance_step-owner_title'),
            name: 'owner',
            description: t('admin_create-governance_step-owner_description'),
            placeholder: t('admin_create-governance_step-owner_placeholder'),
            required: true,
            type: 'TEXT_INPUT',
            validator: new RegExp(SHELL_EMAIL_FORMAT),
          },
          {
            label: t('admin_create-governance_step-expert_title'),
            name: 'expert',
            description: t('admin_create-governance_step-expert_description'),
            placeholder: t('admin_create-governance_step-expert_placeholder'),
            required: true,
            type: 'TEXT_INPUT',
            validator: new RegExp(SHELL_EMAIL_FORMAT),
          },
        ],
        extraFields[type],
        ...(type !== 'API' ? updatingFields : []),
      ]}
    />
  );
};

export default GovernanceSection;
