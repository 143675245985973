import { Filters } from '@sede-x/glass-design-library';
import useParamsHandler from '../../hooks/useParamsHandler';
import { useSelector } from 'react-redux';
import { RootState } from '../../state/store';
import dataProductTypes from '../../utils/dataProductTypes';
import { TagType } from '../../state/models/dataProductsModel/types';
import { Icons, Text } from '@sede-x/shell-ds-react-framework';
import {
  FiltersContainer,
  TextContainer,
  IconContainer,
} from './FiltersComponentStyles';
import { Trans, useTranslation } from 'react-i18next';

const FiltersComponent = () => {
  const terms = useSelector((state: RootState) => state.dataProducts.terms);
  const { params, navigateWithParams, resetFilters } = useParamsHandler();
  const { t } = useTranslation();
  let tags = useSelector((state: RootState) => state.dataProducts.tags);
  tags = [...tags].sort((a, b) => a.localeCompare(b));
  const { search, ...filterParams } = params;
  const totalParams = Object.values(filterParams);
  const hasParams =
    totalParams.length > 0 &&
    totalParams.every((p) => {
      return p.length > 0;
    });

  const fields = [
    {
      name: 'terms',
      label: 'Data Domain',
      type: 'MULTISELECTION',
      placeholder: 'Select a domain',
      options: terms.map((term: TagType) => ({
        value: term.name,
        text: term.name,
      })),
    },
    {
      name: 'type',
      label: 'Type',
      type: 'MULTISELECTION',
      placeholder: 'Select a type',
      options: Object.values(dataProductTypes).map((dPT) => ({
        value: dPT.toLocaleLowerCase(),
        text: dPT,
      })),
    },
    {
      name: 'tags',
      label: 'Tags',
      type: 'MULTISELECTION',
      placeholder: 'Select one or more tags',
      options: tags.map((tag) => ({ value: tag, text: tag })),
    },
    {
      name: 'clear',
      label: '',
      type: 'BUTTON',
      onClick: () => {
        resetFilters();
      },
      text: 'Clear filters',
      disabled: !hasParams,
    },
  ];

  const currentDefaultValues = Object.keys(params)
    .filter((p) => fields.some((f) => f.name === p))
    .reduce((acc, current) => {
      return Object.assign(acc, { [current]: params[current] });
    }, {});

  return (
    <FiltersContainer>
      <TextContainer>
        <IconContainer>
          <Icons.AlertCircle width={14} height={14} />
        </IconContainer>
        <Text size="small">
          <Trans
            t={t}
            i18nKey="filters_component-helper_text"
            components={{ b: <b /> }}
          />
        </Text>
      </TextContainer>
      <Filters
        hideFilterCount
        fields={fields}
        liveUpdate
        defaultValues={currentDefaultValues}
        values={params}
        setValues={navigateWithParams}
      />
    </FiltersContainer>
  );
};

export default FiltersComponent;
