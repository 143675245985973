import { Button, Icons } from '@sede-x/shell-ds-react-framework';
import { Container } from './TableActions.styles';

const TableActions = ({
  onDelete,
  handleEdit,
}: {
  onDelete: () => void;
  handleEdit: () => void;
}) => (
  <Container>
    <Button
      variant="transparent"
      icon={<Icons.ComposeOutlined />}
      data-testid="actions-edit-button"
      iconOnly
      onClick={handleEdit}
    />
    <Button
      variant="transparent"
      icon={<Icons.Trash />}
      data-testid="actions-delete-button"
      iconOnly
      onClick={onDelete}
    />
  </Container>
);

export default TableActions;
