import {
  AdminStateGlass,
  GlassProductAdminListType,
  GlassProductAdminType,
} from './types';
const reducers = {
  setAllGlassProduct(
    state: AdminStateGlass,
    {
      allGlassProducts,
    }: {
      allGlassProducts: GlassProductAdminType[];
    },
  ) {
    return {
      ...state,
      allGlassProducts,
    };
  },
  setOwnGlassProducts(
    state: AdminStateGlass,
    {
      ownGlassProducts,
      totalGlassItems,
    }: {
      ownGlassProducts: GlassProductAdminListType[];
      totalGlassItems: number;
    },
  ) {
    return {
      ...state,
      ownGlassProducts,
      totalGlassItems,
    };
  },

  setIsLoading(state: AdminStateGlass, isLoading: boolean) {
    return {
      ...state,
      isLoading,
    };
  },

  setHasError(state: AdminStateGlass, hasError: boolean) {
    return {
      ...state,
      hasError,
    };
  },

  setCurrentError(state: AdminStateGlass, currentError?: string) {
    return {
      ...state,
      currentError,
    };
  },

  setCurrentGlassProduct(
    state: AdminStateGlass,
    currentGlassProduct: GlassProductAdminType,
  ) {
    return {
      ...state,
      currentGlassProduct,
    };
  },
};

export default reducers;
