import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { RootState } from '../../../../state/store';
import {
  DomainSubscription,
  MySubscriptionTable,
} from '../MySubscriptionPageComponent/MySubscriptionPageComponent.styles';
import { Toggle } from '@sede-x/shell-ds-react-framework';
import {
  DataDomainType,
  ToggleSize,
  IToggleProps,
} from '../../../../state/models/mySubscriptionModel/types';

const MySubscriptionPageComponent = () => {
  const dispatch = useDispatch();
  const DataDomain = useSelector(
    (state: RootState) => state.mySubscriptions.DataDomain,
  );

  useEffect(() => {
    dispatch.mySubscriptions.getDataDomains();
  }, [dispatch.mySubscriptions]);

  return (
    <>
      <MySubscriptionTable>
        <DomainSubscription level={2} bold>
          Data Domain
        </DomainSubscription>
        <DomainSubscription level={2} bold>
          Subscribe
        </DomainSubscription>
        {DataDomain.map((dataDomain: DataDomainType, index: number) => [
          <DomainSubscription level={5} key={`${index}-name`}>
            {dataDomain.name}
          </DomainSubscription>,
          <DomainSubscription level={5} key={`${index}-subscribe`}>
            <Toggle
              checked={false}
              onChange={(checked) => console.log(checked, index)}
              size="medium"
            />
          </DomainSubscription>,
        ])}
      </MySubscriptionTable>
    </>
  );
};

export default MySubscriptionPageComponent;
