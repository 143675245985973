import JSZip from 'jszip';
import { saveAs } from 'file-saver';

async function jszipDownloader(
  files: {
    content: string;
    contentType: string;
    fileName: string;
  }[],
  dpName: string,
) {
  const zip = new JSZip();
  files.forEach((f) => {
    zip.file(
      f.fileName,
      new Blob([f.content], {
        type: f.contentType,
      }),
    );
  });

  const zipFile = await zip.generateAsync({ type: 'blob' });
  saveAs(zipFile, dpName);
}

export default jszipDownloader;
