import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import pkceChallenge from 'pkce-challenge';
import { checkRefresh, getTokens, login } from '../services/authServices';

export interface IProviderData {
  provider: string;
  redirect: string;
  clientId: string;
}

export interface ITokenPair {
  accessToken: string;
  refreshToken: string;
}

interface IAuthStore {
  origin?: string;
  accessToken?: string;
  refreshToken?: string;
  code_challenge?: string;
  code_verifier?: string;
  provider?: string;
  redirect?: string;
  login: ({ provider, redirect, clientId }: IProviderData) => Promise<void>;
  getTokensFromProvider: ({
    code,
    clientId,
    redirect,
  }: {
    code: string;
    clientId: string;
    redirect: string;
  }) => void;
  refreshTokensFromProvider: ({
    clientId,
  }: {
    clientId: string;
  }) => Promise<void>;
}

let singleInterval: NodeJS.Timer | undefined;

const MINUTE = 60000;

function startRefreshInterval(cb: () => void) {
  cb(); // first refresh
  if (!singleInterval) {
    singleInterval = setInterval(cb, MINUTE);
  }
}

const LOADING_PAGE = '/authentication/loading/';

function isOnAuthCallbackPage(redirect: string): boolean {
  return !!redirect && window.location.href.includes(redirect);
}

function isOnAuthLoadingPage(): boolean {
  return window.location.href.includes(LOADING_PAGE);
}

const useAuth = create<IAuthStore>()(
  persist(
    (set, get) => ({
      login: async ({ provider, redirect, clientId }): Promise<void> => {
        const {
          accessToken,
          refreshToken,
          refreshTokensFromProvider,
          getTokensFromProvider,
          origin,
        } = get();

        // if the user already has a access_token and refresh start refreshing the access_token
        if (accessToken && refreshToken && redirect) {
          startRefreshInterval(() => refreshTokensFromProvider({ clientId }));
        } else {
          if (isOnAuthCallbackPage(redirect)) {
            const code =
              new URLSearchParams(window.location.href).get('code') ?? 'error'; // get callback code
            getTokensFromProvider({
              code,
              clientId,
              redirect,
            });
            // if the user isn't in the callback redirect him to auth loading
          } else {
            !origin && set(() => ({ origin: window.location.href }));
            window.location.href = LOADING_PAGE;
          }
          // if the user is on the "login page" start the SSO PKCE flow
          if (isOnAuthLoadingPage()) {
            const challenge = await pkceChallenge(128);
            const { code_challenge, code_verifier } = challenge;
            set(() => ({
              code_challenge,
              code_verifier,
              provider,
              redirect,
            })); // set the code challenge and verifier
            login({
              url: provider,
              redirect_uri: redirect,
              code_challenge,
              client_id: clientId,
            });
          }
        }
      },
      getTokensFromProvider: async ({ code, clientId, redirect }) => {
        const { provider, code_verifier } = get();
        const tokens = await getTokens({
          url: provider,
          redirect_uri: redirect,
          code_verifier: code_verifier ?? 'error',
          code: code ?? 'error',
          client_id: clientId,
        });
        set(() => ({
          accessToken: tokens.access_token,
          refreshToken: tokens.refresh_token,
        }));
        window.location.href = get().origin ?? '/';
      },
      refreshTokensFromProvider: async ({ clientId }: { clientId: string }) => {
        const { accessToken, refreshToken, provider, redirect } = get();
        if (accessToken && refreshToken && provider && redirect) {
          const result = await checkRefresh({
            url: provider,
            accessToken,
            refreshToken,
            clientId,
          });
          if (result?.accessToken && result.refreshToken) {
            if (
              result.accessToken !== accessToken ||
              result.refreshToken !== refreshToken
            ) {
              set(() => ({
                accessToken: result.accessToken,
                refreshToken: result.refreshToken,
              }));
            }
          } else {
            set(() => ({}));
            get().login({ provider, redirect, clientId });
          }
        }
      },
    }),
    {
      name: 'auth',
      storage: createJSONStorage(() => window.sessionStorage),
    },
  ),
);

export default useAuth;
