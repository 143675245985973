import { LoadingItem } from '../LoadingItem/LoadingItem';
import {
  LeftLoadingCardContainer,
  LoadingCardContainer,
  RightLoadingCardContainer,
} from './LoadingCard.styles';

const LoadingCard = () => {
  const props = [
    { margin: '8px 45% 5px 0px', key: '1' },
    { margin: '5px 0', key: '2' },
    { margin: '5px 0', key: '3' },
    { margin: '5px 0', key: '4' },
    { margin: '13px 5% 0 0', key: '5' },
    { margin: '14px 80% 20px 0', key: '6' },
  ];
  return (
    <LoadingCardContainer data-testid="Loading-card">
      <LeftLoadingCardContainer>
        <LoadingItem
          type={'round'}
          width={'40px'}
          margin={'8px 0'}
          height={'40px'}
        />
      </LeftLoadingCardContainer>
      <RightLoadingCardContainer>
        {props.map((item) => (
          <LoadingItem
            type={'normal'}
            width={'100%'}
            margin={item.margin}
            key={`loading${item.key}`}
            height="10px"
          />
        ))}
      </RightLoadingCardContainer>
    </LoadingCardContainer>
  );
};
export default LoadingCard;
