import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledLink = styled(Link)`
  margin-left: 8px;
  margin-top: 4px;
`;

export const StyledAnchor = styled.a`
  margin-left: 8px;
  margin-top: 4px;
`;
