import styled from 'styled-components';

export const LoadingTabsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  padding-left: 5px;
  flex: 1;
`;

export const CategoryText = styled.div`
  display: flex;
  gap: 4px;
  color: ${(props) => props.theme.text.onSurface.subtle};
`;
