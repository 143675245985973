import {
  Button,
  ButtonLink,
  Divider,
  Heading,
  Icons,
} from '@sede-x/shell-ds-react-framework';
import {
  ButtonContainer,
  Container,
  LinkStyled,
  RightContainer,
  StyledText,
  IconCircle,
  IconContainer,
} from './CreationCard.styles';
import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface Props {
  icon: ReactElement;
  title: string;
  description: string;
  urlWiki: string;
  textWiki: string;
  urlCreate: string;
}

const CreationCard = ({
  icon,
  title,
  description,
  urlWiki,
  textWiki,
  urlCreate,
}: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <Container>
        <IconContainer>
          <IconCircle>{icon}</IconCircle>
        </IconContainer>
        <RightContainer>
          <Heading type="h3" bold>
            {title}
          </Heading>
          <StyledText size="small">{description}</StyledText>
          <LinkStyled>
            <a href={urlWiki} target="_blank" rel="noreferrer">
              <ButtonLink
                icon={<Icons.Open width={16} height={16} />}
                iconPosition="right"
              >
                {textWiki}
              </ButtonLink>
            </a>
          </LinkStyled>
          <ButtonContainer>
            <Link to={urlCreate}>
              <Button
                variant="transparent"
                icon={<Icons.ChevronRight />}
                iconPosition="right"
              >
                {t('admin_create-product-selector-start_creation')}
              </Button>
            </Link>
          </ButtonContainer>
        </RightContainer>
      </Container>
      <Divider />
    </>
  );
};

export default CreationCard;
