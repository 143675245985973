import { useEffect, useState } from 'react';
import List from '../../components/List/List';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../state/store';
import LoadingList from '../../components/LoadingList/LoadingList';
import { ErrorState } from '../../components/ErrorState/ErrorState';
import { ListContainer, ListSectionContainer } from './ListComponent.styles';
import useParamsHandler, { FilterNames } from '../../hooks/useParamsHandler';

export const maxRowsPerPage = 10;

export const getParamsList = (type: FilterNames, params: string[]) => {
  return { id: type, values: params || [] };
};

const ListComponent = () => {
  const { params } = useParamsHandler();
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();

  const { dataProducts, totalItems, isLoading, hasError } = useSelector(
    (state: RootState) => state.dataProducts,
  );

  useEffect(() => {
    dispatch.dataProducts.getDomains();
    dispatch.dataProducts.getTags();
  }, []);

  useEffect(() => {
    setPage(1);
    dispatch.dataProducts.getDataProducts({
      keywords: params.search?.[0] || '',
      offset: 0,
      limit: maxRowsPerPage,
      filters: [
        getParamsList('type', params.type),
        getParamsList('terms', params.terms),
        getParamsList('tags', params.tags),
      ],
    });
  }, [dispatch.dataProducts, params]);

  const onPageChange = (newPage: number) => {
    dispatch.dataProducts.getDataProducts({
      keywords: params.search?.[0] || '',
      offset: (newPage - 1) * maxRowsPerPage,
      limit: maxRowsPerPage,
      filters: [
        getParamsList('type', params.type),
        getParamsList('terms', params.terms),
        getParamsList('tags', params.tags),
      ],
    });
    setPage(newPage);
  };

  if (isLoading) {
    return (
      <ListSectionContainer>
        <ListContainer>
          <LoadingList />
        </ListContainer>
      </ListSectionContainer>
    );
  }
  if (hasError) {
    return (
      <ListSectionContainer>
        <ListContainer>
          <ErrorState />
        </ListContainer>
      </ListSectionContainer>
    );
  }

  return (
    <ListSectionContainer>
      <ListContainer>
        <List
          dataProducts={dataProducts}
          searchText={params.search?.[0]}
          totalItems={totalItems}
          page={page}
          maxRowsPerPage={maxRowsPerPage}
          onPageChange={onPageChange}
        />
      </ListContainer>
    </ListSectionContainer>
  );
};

export default ListComponent;
