import {
  ALPHANUMERIC_WITH_SYMBOLS_FORMAT_255,
  NUMERIC_FORMAT,
  URL_FORMAT,
} from '../../../../../utils/regexpConst';
import TypeTranslation from '../../../../../utils/i18n/TypeTranslation';
import BannerField from '../../../../../components/FormFields/BannerField/BannerField';
import { Sentiments } from '@sede-x/shell-ds-react-framework';

export const URIField = (t: TypeTranslation) => ({
  label: t('admin_create-configuration_step-uri_title'),
  name: 'uri',
  description: t('admin_create-configuration_step-uri_description'),
  placeholder: t('admin_create-configuration_step-uri_placeholder'),
  required: true,
  type: 'TEXT_INPUT',
  validator: new RegExp(URL_FORMAT),
});

export const TopicNameField = (t: TypeTranslation) => ({
  label: t('admin_create-configuration_step-topic_name_title'),
  name: 'topicName',
  description: t('admin_create-configuration_step-topic_name_description'),
  placeholder: t('admin_create-configuration_step-topic_name_placeholder'),
  required: true,
  type: 'TEXT_INPUT',
  validator: new RegExp(ALPHANUMERIC_WITH_SYMBOLS_FORMAT_255),
});

export const RetentionTimeField = (t: TypeTranslation) => ({
  label: t('admin_create-configuration_step-retention_time_title'),
  name: 'retentionTime',
  description: t('admin_create-configuration_step-retention_time_description'),
  placeholder: t('admin_create-configuration_step-retention_time_placeholder'),
  required: true,
  type: 'TEXT_INPUT',
  validator: new RegExp(NUMERIC_FORMAT),
});

export const PollTimeField = (t: TypeTranslation) => ({
  label: t('admin_create-configuration_step-poll_time_title'),
  name: 'pollTime',
  description: t('admin_create-configuration_step-poll_time_description'),
  placeholder: t('admin_create-configuration_step-poll_time_placeholder'),
  required: true,
  type: 'TEXT_INPUT',
  validator: new RegExp(NUMERIC_FORMAT),
});

export const BannerUpdating = (t: TypeTranslation) => ({
  label: '',
  name: 'bannerConfiguration',
  description: '',
  type: 'CUSTOM',
  component: BannerField({
    sentiment: Sentiments.Warning,
    text: t('admin_update-deployment_step-banner_configuration_text'),
  }),
});
