import { Heading, Text } from '@sede-x/shell-ds-react-framework';
import { EmptyStateContainer, StyledList } from '../EmptyState.styles';

const EmptyStateDefault = () => {
  return (
    <EmptyStateContainer>
      <Heading level={2}>No results found for this search filter</Heading>
      <Text bold size="medium">
        Suggestions:
      </Text>
      <StyledList>
        <li>Make sure all filters are selected correctly.</li>
        <li>Try different filters.</li>
        <li>Try more general filters.</li>
        <li>Try fewer filters.</li>
      </StyledList>
    </EmptyStateContainer>
  );
};

export default EmptyStateDefault;
