import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Layout from '../../../components/Layout/Layout';
import TabsPanel from '../../../components/TabsPanel/TabsPanel';
import { Button, Icons } from '@sede-x/shell-ds-react-framework';
import routes from '../../../routes/routes';
import AdminListPageRouting from './AdminListPageRouting';
import {
  AdminTabsContainer,
  Container,
  StyledAnchor,
} from './components/AdminListSection/AdminListSection.styles';
import { useDispatch } from 'react-redux';

const AdminListPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();

  const currentTab = params['*'];

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch.user.saveUserData({
      navigationHistory: {
        type: 'admin',
        item: '',
      },
    });
  }, [dispatch.user]);

  const tabs = [
    {
      name: t('admin_list-heading-products'),
      id: '/admin',
    },
    {
      name: t('admin_list-tab_header-glass-product'),
      id: 'glass',
    },
  ];

  const onTabClick = (id: string) => {
    navigate(id);
  };
  return (
    <Layout
      variant="CENTERED_COLUMN"
      content={
        <Container>
          <AdminTabsContainer>
            <TabsPanel
              tabs={tabs}
              onTabClick={onTabClick}
              defaultTab={currentTab ? currentTab : tabs[0].id}
            />
            <StyledAnchor
              to={
                currentTab === 'glass' ? routes.createGlassHub : routes.create
              }
            >
              <Button
                variant="outlined"
                iconPosition="right"
                icon={<Icons.AddCircle />}
              >
                {t('admin_list-button-register_new')}
              </Button>
            </StyledAnchor>
          </AdminTabsContainer>

          <AdminListPageRouting />
        </Container>
      }
      padding="0px"
    />
  );
};

export default AdminListPage;
