import { Form } from '@sede-x/glass-design-library';
import {
  LENGTH_RANGE,
  NUMERIC_FORMAT,
  URL_FORMAT,
} from '../../../../../utils/regexpConst';
import { useTranslation } from 'react-i18next';
import BannerField from '../../../../../components/FormFields/BannerField/BannerField';
import { useNavigate } from 'react-router-dom';
import routes from '../../../../../routes/routes';
import { Sentiments } from '@sede-x/shell-ds-react-framework';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../state/store';

const PropertiesSection = ({
  onSubmit,
  onCancel,
  defaultValues,
  isUpdating = false,
}: {
  onSubmit: (values: { [key: string]: unknown }) => void;
  onCancel: () => void;
  defaultValues?: { [key: string]: unknown };
  isUpdating?: boolean;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { navigationHistory } = useSelector(
    (state: RootState) => state.user.user,
  );
  return (
    <Form
      onCancel={onCancel}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      submitButton={{
        text: t('admin_create-properties_step-next_button'),
        variant: 'filled',
      }}
      cancelButton={{
        text: t('admin_create-properties_step-back_button'),
        variant: 'outlined',
      }}
      customButtons={
        isUpdating
          ? [
              {
                text: t('admin_create-product_details_back_button'),
                variant: 'outlined',
                onClick: () => {
                  if (navigationHistory.type === 'details') {
                    navigate(`${routes.details}/${navigationHistory.item}`);
                  } else {
                    navigate(routes.admin);
                  }
                },
              },
            ]
          : []
      }
      formConfig={[
        ...(isUpdating
          ? [
              {
                label: '',
                name: 'bannerPolicies',
                description: '',
                type: 'CUSTOM',
                component: BannerField({
                  sentiment: Sentiments.Warning,
                  text: t('admin_update-deployment_step-banner_policies_text'),
                }),
              },
            ]
          : []),
        {
          name: 'propertiesTitle',
          subtitle: t('admin_create-properties_step-title_description'),
          title: t('admin_create-properties_step-title'),
          type: 'HEADING',
        },
        {
          label: t('admin_create-properties_step-url_title'),
          name: 'url',
          description: t('admin_create-properties_step-url_description'),
          placeholder: t('admin_create-properties_step-url_placeholder'),
          required: true,
          type: 'TEXT_INPUT',
          validator: new RegExp(URL_FORMAT),
        },
        [
          {
            label: t('admin_create-properties_step-data_volume_title'),
            name: 'dataVolume',
            description: t(
              'admin_create-properties_step-data_volume_description',
            ),
            placeholder: t(
              'admin_create-properties_step-data_volume_placeholder',
            ),
            required: true,
            type: 'TEXT_INPUT',
            validator: new RegExp(NUMERIC_FORMAT),
          },
          {
            label: t('admin_create-properties_step-batch_size_title'),
            name: 'batchSize',
            description: t(
              'admin_create-properties_step-batch_size_description',
            ),
            placeholder: t(
              'admin_create-properties_step-batch_size_placeholder',
            ),
            required: true,
            type: 'TEXT_INPUT',
            validator: new RegExp(NUMERIC_FORMAT),
          },
        ],

        [
          {
            label: t('admin_create-properties_step-batch_frequency_title'),
            name: 'batchFrequency',
            description: t(
              'admin_create-properties_step-batch_frequency_description',
            ),
            placeholder: t(
              'admin_create-properties_step-batch_frequency_placeholder',
            ),
            required: true,
            type: 'TEXT_INPUT',
            validator: new RegExp(LENGTH_RANGE(0, 50)),
          },
          {
            label: t('admin_create-properties_step-data_source_title'),
            name: 'dataSource',
            description: t(
              'admin_create-properties_step-data_source_description',
            ),
            placeholder: t(
              'admin_create-properties_step-data_source_placeholder',
            ),
            type: 'TEXT_INPUT',
          },
        ],
      ]}
    />
  );
};

export default PropertiesSection;
