import { Form } from '@sede-x/glass-design-library';
import LinkFormHelper from '../../../../../components/LinkFormHelper/LinkFormHelper';
import POLICIES from '../../../../../utils/policiesConsts';
import { URL_FORMAT } from '../../../../../utils/regexpConst';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TypeTranslation from '../../../../../utils/i18n/TypeTranslation';
import EmptyField from '../../../../../components/FormFields/EmptyField/EmptyField';
import BannerField from '../../../../../components/FormFields/BannerField/BannerField';
import UpdatingVersionFields from '../UpdatingVersionSection/UpdatingVersionFields';
import { getNextVersion } from '../../../../../utils/versionFunctions';
import CustomHeadersField from '../../../../../components/FormFields/CustomHeadersField/CustomHeadersField';
import { Endpoint } from '../../../../../components/FormFields/EndpointSelector/EndpointsSelector';
import { useNavigate } from 'react-router-dom';
import routes from '../../../../../routes/routes';
import { Sentiments } from '@sede-x/shell-ds-react-framework';
import PoliciesField from '../../../../../components/FormFields/PoliciesField/PoliciesField';

const arePoliciesEquals = (oldPolicies: string[], newPolicies: string[]) => {
  if (newPolicies === undefined) {
    return true;
  }
  if (oldPolicies?.length !== newPolicies?.length) {
    return false;
  }
  return (
    oldPolicies.every((oP) => newPolicies.includes(oP)) &&
    newPolicies.every((nP) => oldPolicies.includes(nP))
  );
};

const generateDynamicFields = (
  currentPolicies: string[],
  t: TypeTranslation,
) => {
  const currentFields = [];
  if (
    currentPolicies.length > 0 &&
    currentPolicies.includes(POLICIES.EXTERNAL_AUTH)
  ) {
    currentFields.push({
      label: t('admin_create-deployment_step-openid_url_title'),
      name: 'openConfigurationUrl',
      description: t('admin_create-deployment_step-openid_url_description'),
      placeholder: t('admin_create-deployment_step-openid_url_placeholder'),
      required: true,
      type: 'TEXT_INPUT',
      validator: new RegExp(URL_FORMAT),
    });
  }

  return currentFields;
};

const DeploymentSection = ({
  onSubmit,
  onCancel,
  defaultValues,
  isUpdating = false,
  requireNewVersion = false,
}: {
  onSubmit: (values: { [key: string]: unknown }) => void;
  onCancel: () => void;
  defaultValues?: { [key: string]: unknown };
  isUpdating?: boolean;
  requireNewVersion?: boolean;
}) => {
  const defaultValuesWithVersion = requireNewVersion
    ? {
        ...defaultValues,
        newVersion: getNextVersion(defaultValues?.version as string),
      }
    : defaultValues;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [currentPolicies, setCurrentPolicies] = useState<string[]>(
    (Array.isArray(defaultValues?.policies) && defaultValues?.policies) || [],
  );
  const [innerRequireNewVersion, setInnerRequireNewVersion] =
    useState(requireNewVersion);

  const dynamicFields = useMemo(
    () => generateDynamicFields(currentPolicies, t),
    [currentPolicies, t],
  );

  return (
    <Form
      onCancel={onCancel}
      onSubmit={onSubmit}
      defaultValues={defaultValuesWithVersion}
      onChange={({ policies, openConfigurationUrl }) => {
        if (!arePoliciesEquals(currentPolicies, policies)) {
          setCurrentPolicies(policies || []);
        }
        const isDiffOpenConfig =
          !!openConfigurationUrl &&
          defaultValues?.openConfigurationUrl !== openConfigurationUrl;
        if (
          !arePoliciesEquals(policies, defaultValues?.policies as string[]) ||
          isDiffOpenConfig
        ) {
          setInnerRequireNewVersion(true);
        } else {
          setInnerRequireNewVersion(requireNewVersion);
        }
      }}
      submitButton={{
        text: t('admin_create-deployment_step-submit_button'),
        variant: 'filled',
      }}
      cancelButton={{
        text: t('admin_create-deployment_step-back_button'),
        variant: 'outlined',
      }}
      customButtons={
        isUpdating
          ? [
              {
                text: t('admin_create-product_details_back_button'),
                variant: 'outlined',
                onClick: () => navigate(routes.admin),
              },
            ]
          : []
      }
      formConfig={[
        {
          name: 'deploymentTitle',
          subtitle: t('admin_create-deployment_step-title_description'),
          title: t('admin_create-deployment_step-title'),
          type: 'HEADING',
        },
        ...(isUpdating
          ? [
              {
                label: '',
                name: 'bannerEndpoints',
                description: '',
                type: 'CUSTOM',
                component: BannerField({
                  sentiment: Sentiments.Warning,
                  text: t('admin_update-deployment_step-banner_policies_text'),
                }),
              },
            ]
          : []),
        {
          label: t('admin_create-deployment_step-policies_title'),
          name: 'policies',
          subtitle: t('admin_create-deployment_step-policies_description'),
          topHelper: (
            <LinkFormHelper
              href={t('admin_create-deployment_step-policies_helper_link')}
            >
              {t('admin_create-deployment_step-policies_helper_text')}
            </LinkFormHelper>
          ),
          component: PoliciesField,
          type: 'CUSTOM',
        },
        dynamicFields.length > 1
          ? dynamicFields
          : [...dynamicFields, EmptyField],

        ...(currentPolicies.length > 0 &&
        currentPolicies.includes(POLICIES.CUSTOM_HEADERS)
          ? [
              {
                label: t('admin_create-deployment_step-metadata_title'),
                name: 'customHeaders',
                description: t(
                  'admin_create-deployment_step-metadata_description',
                ),
                type: 'CUSTOM',
                component: CustomHeadersField(
                  (defaultValues?.endpoints as Endpoint[]).map((e) => e.path),
                ),
                required: true,
                customValidators: [
                  {
                    reason: ' ',
                    validate: async (value: string) => {
                      return value === undefined || value?.length > 0;
                    },
                  },
                ],
              },
            ]
          : []),
        ...(isUpdating
          ? UpdatingVersionFields({
              t,
              requireNewVersion: innerRequireNewVersion,
              currentVersion: defaultValues?.version as string,
              type: defaultValues?.type as string,
            })
          : []),
      ]}
    />
  );
};

export default DeploymentSection;
