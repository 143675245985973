import React from 'react';
import { StyledLink } from './LinkWithIcon.styles';
import { ButtonLink, Icons } from '@sede-x/shell-ds-react-framework';

interface Props {
  children: string;
  href: string;
  underlined?: boolean;
  bold?: boolean;
}

const LinkWithIcon: React.FC<Props> = ({
  children,
  href,
  underlined = true,
  bold = false,
  ...rest
}) => {
  return (
    <StyledLink underlined={underlined} bold={bold} {...rest}>
      <a href={href} target="_blank" rel="noreferrer">
        <ButtonLink
          icon={<Icons.Open width={16} height={16} />}
          iconPosition="right"
        >
          {children}
        </ButtonLink>
      </a>
    </StyledLink>
  );
};

export default LinkWithIcon;
