import { Pagination, Text } from '@sede-x/shell-ds-react-framework';
import { useEffect, useRef } from 'react';
import Item, { ItemTypes } from '../Item/Item';
import { ListContainer, PaginationContainer } from './List.styles';
import { getPageInfo } from '../../utils/paginationFunctions';
import EmptyState from '../EmptyState/EmptyState';

const List = ({
  dataProducts,
  totalItems,
  searchText,
  page,
  maxRowsPerPage,
  onPageChange,
}: {
  dataProducts: ItemTypes[];
  totalItems: number;
  searchText: string;
  page: number;
  maxRowsPerPage: number;
  onPageChange: (page: number) => void;
}) => {
  const listContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (page !== 1 && listContainerRef.current) {
      listContainerRef.current.scrollIntoView();
    }
  }, [page]);

  const currentPageItems = dataProducts.length;

  return (
    <ListContainer ref={listContainerRef}>
      {totalItems === 0 && <EmptyState keywords={searchText} />}
      {dataProducts.map((item: ItemTypes) => (
        <Item item={item} key={item.id} />
      ))}
      {totalItems > maxRowsPerPage && (
        <PaginationContainer>
          <Text>
            {getPageInfo(page, maxRowsPerPage, totalItems, currentPageItems)}
          </Text>
          <Pagination
            current={page}
            defaultPageSize={maxRowsPerPage}
            total={totalItems}
            onChange={onPageChange}
            data-testid="pagination-component"
          />
        </PaginationContainer>
      )}
    </ListContainer>
  );
};

export default List;
