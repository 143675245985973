import { Link } from 'react-router-dom';
import styled from 'styled-components';
import TabsPanel from '../../../../../components/TabsPanel/TabsPanel';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const HeadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: flex-end;
  margin-bottom: 24px;
`;

export const StyledAnchor = styled(Link)`
  text-decoration: none;
`;

export const StyledTabsPanel = styled(TabsPanel)`
  background-color: none;
  align-items: flex-start;
`;

export const AdminTabsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
`;
