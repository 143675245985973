import EmptyStateWithKeywords from './variants/EmptyStateWithKeywords';
import EmptyStateDefault from './variants/EmptyStateDefault';

const EmptyState = ({ keywords }: { keywords?: string }) => {
  if (keywords) {
    return <EmptyStateWithKeywords keywords={keywords} />;
  }

  return <EmptyStateDefault />;
};

export default EmptyState;
