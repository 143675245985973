import { useDispatch, useSelector } from 'react-redux';
import TagsChart from '../TagsChart/TagsChart';
import { Container, StyledDivider } from './InsightsPageComponent.styles';
import { useEffect } from 'react';
import { RootState } from '../../../../state/store';
import { Heading, Text } from '@sede-x/shell-ds-react-framework';
import RequestsChart from '../RequestsChart/RequestsChart';

const InsightsPageComponent = () => {
  const dispatch = useDispatch();
  const { dataDomains } = useSelector((state: RootState) => state.insights);

  useEffect(() => {
    dispatch.insights.getDataDomains();
  }, [dispatch.insights]);

  return (
    <Container>
      <Heading level={1} bold>
        Insight Dashboard
      </Heading>
      <StyledDivider />
      <Text>
        Number of Data Products: <b>{dataDomains.totalDataProducts}</b>
      </Text>
      <TagsChart />
      <StyledDivider />
      <RequestsChart />
    </Container>
  );
};

export default InsightsPageComponent;
