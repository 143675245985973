const genericChartStyles = {
  tooltip: {
    backgroundColor: '#2A3037',
    style: {
      color: '#FFFFFF',
    },
  },
};

export default genericChartStyles;
