import { Icons, Loading } from '@sede-x/shell-ds-react-framework';
import { DataProductType } from '../../../../../../../../../state/models/dataProductsModel/types';
import { DetailButton } from '../../TheButton.styles';
import RequestAccessModal from '../components/RequestAccessModal';
import { useState } from 'react';

interface Props {
  dataProduct: DataProductType;
  isLoading?: boolean;
}

const RequestAccessButton = ({ dataProduct, isLoading = false }: Props) => {
  const [open, setOpen] = useState<boolean>(false);

  return dataProduct.accessType !== 'public' ? (
    <>
      <DetailButton
        icon={!isLoading ? <Icons.Lock /> : <Loading />}
        disabled={isLoading}
        iconPosition="right"
        onClick={() => setOpen(true)}
      >
        Request access
      </DetailButton>
      {open && (
        <RequestAccessModal
          dataProduct={dataProduct}
          onCancel={() => setOpen(false)}
        />
      )}
    </>
  ) : (
    <></>
  );
};

export default RequestAccessButton;
