import styled from 'styled-components';

export const FiltersContainer = styled.div`
  width: 264px;
  height: auto;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 16px;
  margin-top: 10px;
  font-size: 12px;
`;

export const IconContainer = styled.div`
  padding-right: 3px;
`;
