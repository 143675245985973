import styled from 'styled-components';

export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: baseline;
`;

export const StyledAvatar = styled.div`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.background.base};
  display: flex;
  align-items: center;
  justify-content: center;
`;
