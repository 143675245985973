import { Modal, Text } from '@sede-x/shell-ds-react-framework';
import React from 'react';
import {
  ButtonStyled,
  Container,
  EndpointContainer,
  EndpointText,
  HeadingStyled,
} from './ResultModal.styles';
import SuccessIcon from './SuccessIcon';
import InfoIcon from './InfoIcon';
import ErrorIcon from './ErrorIcon';
import SmallInfoIcon from './SmallInfoIcon';

const variants = {
  success: {
    title: 'Download completed',
    description: 'All selected data sets have been downloaded',
    icon: <SuccessIcon />,
  },
  info: {
    title: 'Partially completed',
    description: "The following datasets couldn't be downloaded:",
    icon: <InfoIcon />,
  },
  error: {
    title: 'Download failed',
    description:
      'An unknown error occurred while downloading the data.\nPlease try again.',
    icon: <ErrorIcon />,
  },
};

export type VariantsResultType = keyof typeof variants;

interface Props {
  variant: VariantsResultType;
  onClose: () => void;
  items?: string[];
}

const ResultModal: React.FC<Props> = ({ variant, onClose, items = [] }) => {
  const currentVariant = variants[variant] || variants['error'];

  return (
    <Modal
      showHeader={false}
      showFooter={false}
      onClose={onClose}
      open
      closable={false}
      width="347px"
    >
      <Container>
        {currentVariant.icon}
        <HeadingStyled bold>{currentVariant.title}</HeadingStyled>
        <Text>{currentVariant.description}</Text>
        {items.map((i) => (
          <EndpointContainer key={i}>
            <SmallInfoIcon />
            <EndpointText>{i}</EndpointText>
          </EndpointContainer>
        ))}
        <ButtonStyled onClick={onClose}>Continue</ButtonStyled>
      </Container>
    </Modal>
  );
};

export default ResultModal;
