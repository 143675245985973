import { useEffect } from 'react';
import Layout from '../../../../components/Layout/Layout';
import AdminFormEditSection from '../sections/EditForms/AdminFormEditSection';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../state/store';
import LoadingFullPageComponent from '../../../../components/LoadingFullPageComponent/LoadingFullPageComponent';

const AdminFormEditPageGlass = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    if (id) {
      dispatch.adminGlass.getCurrentGlassProduct({ id });
    }
    return () => {
      dispatch.adminGlass.cleanCurrentGlassProduct();
    };
  }, [id]);

  const { currentGlassProduct } = useSelector(
    (state: RootState) => state.adminGlass,
  );

  return (
    <Layout
      variant="CENTERED_COLUMN"
      content={
        !currentGlassProduct.id ? (
          <LoadingFullPageComponent />
        ) : (
          <AdminFormEditSection product={currentGlassProduct} />
        )
      }
      padding="0px"
    />
  );
};

export default AdminFormEditPageGlass;
