import { RematchDispatch } from '@rematch/core';
import DataProductService from '../../../services/DataProductService';
import { RootModel, RootState } from '../../store';

const effects = (dispatch: RematchDispatch<RootModel>) => ({
  async getDataDomains(_?: undefined, state?: RootState) {
    const response = await DataProductService.getDataDomains();
    dispatch.mySubscriptions.setDataDomainsData({
      DataDomain: response,
    });
  },
});

export default effects;
