import styled, { keyframes } from 'styled-components';

const ShineAnimation = keyframes`
  0% {background-position: top right;}
  100% {background-position: top left;}
`;

export const LoadingItemStyle = styled.div`
  display: flex;
  background: ${({ theme }) => theme.background.surface} -webkit-gradient(
      linear,
      left top,
      right top,
      from(${({ theme }) => theme.background.surface}),
      to(${({ theme }) => theme.background.surface}),
      color-stop(0.5, ${({ theme }) => theme.background.inactive})
    ) 0 0 no-repeat;
  background-size: 2000px;
  background-clip: content-box;
  animation-name: ${ShineAnimation};
  animation-duration: 1s;
  animation-iteration-count: infinite;
`;

export const ContentLoadingElement = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
`;

export const LoadingElement = styled(LoadingItemStyle)<{
  type: string;
  width: string;
  margin: string;
  height: string;
}>`
  margin: ${(props) => (props.margin ? props.margin : '10px')};

  width: ${(props) => (props.width ? props.width : '100%')};
  height: ${(props) => (props.type === 'round' ? props.width : props.height)};
  border-radius: ${(props) => (props.type === 'round' ? '50%' : '8px')};
`;
