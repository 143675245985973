import { Icons } from '@sede-x/shell-ds-react-framework';
import { ButtonStyled, Container } from './CustomHeadersField.styles';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import FieldInputs, { CustomHeaderFieldType } from './FieldInputs/FieldInputs';
import { CUSTOM_HEADERS_VALUE } from '../../../utils/regexpConst';

interface Props {
  onChange: (value: string) => void;
  value?: string;
}

type TypeReturned = { [key: string]: { [key: string]: string } };

export const processOutput = (values: CustomHeaderFieldType[]) => {
  const result = values.reduce<TypeReturned>((acc, current) => {
    const valueSplitted = current.value.split(':');
    const key = valueSplitted[0].replaceAll('"', '');
    const value = valueSplitted[1].replaceAll('"', '');
    const currentValue = acc[current.endpoint as keyof typeof acc] || {};
    acc[current.endpoint as keyof typeof acc] = {
      ...currentValue,
      [key]: value,
    };
    return acc;
  }, {});

  return JSON.stringify(result);
};

export const processInput: (values: string) => CustomHeaderFieldType[] = (
  values: string,
) => {
  const parsedInput: TypeReturned = JSON.parse(values);
  return Object.keys(parsedInput).reduce<CustomHeaderFieldType[]>(
    (acc, current) => {
      const currentValues = parsedInput[current];

      return [
        ...acc,
        ...Object.keys(currentValues).map((cV) => {
          const currentValue = `"${cV}":"${currentValues[cV]}"`;
          return {
            endpoint: current,
            value: currentValue,
            id: `${current}${currentValue}`,
          };
        }),
      ];
    },
    [],
  );
};

const CustomHeadersField = (endpoints: string[]) =>
  function Field({ onChange, value }: Props) {
    const { t } = useTranslation();
    const [customHeaderValues, setCustomHeaderValues] = useState<
      CustomHeaderFieldType[]
    >(value ? processInput(value) : []);

    const callOnChange = (values: CustomHeaderFieldType[]) => {
      const valuesWithoutEmpty = values.filter(
        (v) => v.endpoint !== '' || v.value !== '',
      );

      const areValuesValid =
        valuesWithoutEmpty.length === 0 ||
        valuesWithoutEmpty.every(
          (v) =>
            !!v.endpoint &&
            v.value !== '' &&
            CUSTOM_HEADERS_VALUE.exec(v.value),
        );

      if (areValuesValid && valuesWithoutEmpty.length > 0) {
        onChange(processOutput(valuesWithoutEmpty));
      } else {
        onChange('');
      }
    };

    const handleCreateNew = () => {
      const newValues = [
        ...customHeaderValues,
        { id: Date.now().toString(), endpoint: '', value: '' },
      ];
      setCustomHeaderValues(newValues);
      callOnChange(newValues);
    };

    const handleRemove = (id: string) => () => {
      const newValues = customHeaderValues.filter((mdV) => mdV.id !== id);
      setCustomHeaderValues(newValues);
      callOnChange(newValues);
    };

    const handleChange = (id: string) => (mdField: CustomHeaderFieldType) => {
      const newValues = customHeaderValues.map((md) =>
        md.id === id ? mdField : md,
      );
      setCustomHeaderValues(newValues);
      callOnChange(newValues);
    };

    return (
      <Container>
        {customHeaderValues.map((mV, index) => (
          <FieldInputs
            value={mV}
            onChange={handleChange(mV.id)}
            onDelete={handleRemove(mV.id)}
            key={mV.id}
            endpoints={endpoints}
            index={index + 1}
          />
        ))}
        <ButtonStyled
          icon={<Icons.Add />}
          iconPosition="left"
          onClick={handleCreateNew}
          variant="outlined"
        >
          {t('custom_header_field-add_new_button')}
        </ButtonStyled>
      </Container>
    );
  };

export default CustomHeadersField;
