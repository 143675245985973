import {
  Checkbox,
  Heading,
  Icons,
  Modal,
} from '@sede-x/shell-ds-react-framework';
import { Trans, useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../../../state/store';
import {
  BodyContainer,
  BodyText,
  BoxContainer,
  IconContainer,
  InnerContainer,
} from './ConfirmationDeleteModal.styles';

const ConfirmationDeleteModal = ({
  onConfirm,
  onCancel,
  productTitle,
  isGlass = false,
}: {
  onConfirm: () => void;
  onCancel: () => void;
  productTitle: string;
  isGlass?: boolean;
}) => {
  const [isConfirmed, setIsConfirmed] = useState(false);
  const { isLoading } = useSelector((state: RootState) => state.admin);
  const { t } = useTranslation();

  return (
    <Modal
      width={800}
      onClose={onCancel}
      open
      title={t('admin_list-delete_modal-title')}
      description={
        isGlass
          ? t('admin_list-delete_modal_glass-description')
          : t('admin_list-delete_modal-description')
      }
      actions={[
        {
          action: onCancel,
          label: t('admin_list-delete_modal-cancel'),
          props: { variant: 'outlined', disabled: isLoading },
        },
        {
          action: onConfirm,
          label: t('admin_list-delete_modal-confirm'),
          props: { disabled: isLoading || !isConfirmed, loading: isLoading },
        },
      ]}
    >
      <InnerContainer>
        <BoxContainer>
          <IconContainer>
            <Icons.Alert width={24} height={24} color="red" />
          </IconContainer>
          <BodyContainer>
            <Heading level={4} bold>
              {t('admin_list-delete_modal-header')}
            </Heading>
            <BodyText>
              <Trans
                t={t}
                i18nKey="admin_list-delete_modal-body_1"
                values={{ product: productTitle }}
                components={{ bold: <strong style={{ marginLeft: '4px' }} /> }}
              />
            </BodyText>
            <BodyText>
              {isGlass
                ? t('admin_list-delete_modal_glass-body_2', {
                    product: productTitle,
                  })
                : t('admin_list-delete_modal-body_2', {
                    product: productTitle,
                  })}
            </BodyText>
          </BodyContainer>
        </BoxContainer>
        <Checkbox
          onChange={(e) => setIsConfirmed(e.target.checked)}
          label={t('admin_list-delete_modal-checkbox_label')}
        />
      </InnerContainer>
    </Modal>
  );
};

export default ConfirmationDeleteModal;
