import { Container } from './RichTextEditor.styles';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { markdownToHtml, htmlToMarkdown } from '../../../utils/markdownParser';

interface Props {
  onChange: (value: string) => void;
  value: string;
}

const RichTextEditor = ({ onChange, value }: Props) => {
  const handleChange = (html: string) => {
    onChange(htmlToMarkdown(html));
  };
  return (
    <Container>
      <ReactQuill
        theme="snow"
        defaultValue={markdownToHtml(value)}
        onChange={handleChange}
      />
    </Container>
  );
};

export default RichTextEditor;
