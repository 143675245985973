import Layout from '../../../../components/Layout/Layout';
import ConfirmationPageContent from './ConfirmationPageContent/ConfirmationPageContent';

const ConfirmationPage = () => {
  return (
    <Layout variant="CENTERED_CONTENT" content={<ConfirmationPageContent />} />
  );
};

export default ConfirmationPage;
